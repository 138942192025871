import { useState } from "react";
import { getDeviceInfo } from "@sse-digital-services/design-system";

const usePWANotificationRequest = () => {
    const [shownRequest, setShownRequest] = useState(false);
    const alreadyInPWA =
        window?.matchMedia("(display-mode: standalone)")?.matches ||
        !!window?.navigator?.standalone;
    const { ios } = getDeviceInfo();
    const iosShownRequest = localStorage.getItem(
        "iosShownNotificationPermissionRequest"
    );
    const notificationPermissions =
        "Notification" in window && Notification?.permission;

    const shouldRequestPermission =
        alreadyInPWA &&
        !shownRequest &&
        ((!ios && notificationPermissions === "default") ||
            (ios && !iosShownRequest));

    const requestPermission = async () => {
        if (
            notificationPermissions === "default" &&
            Notification?.requestPermission
        ) {
            await Notification.requestPermission();
            setShownRequest(true);
        }
    };

    return { shouldRequestPermission, requestPermission };
};

export default usePWANotificationRequest;
