import {
    getDeviceInfo,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import Typography from "@mui/material/Typography";
import React from "react";
import Dialog from "@mui/material/Dialog";
import InfoDialog from "components/modals/InfoDialog";
import { APP_NAME } from "../../constants/generalConstants";
import PropTypes from "prop-types";

const MoreInfoModal = ({ open, setOpen }) => {
    const { borderSX } = useHighContrastBorderSX();
    const { ios, android } = getDeviceInfo();

    const content = ios ? (
        <Typography>
            Installing {APP_NAME} as an app will let you launch it faster, and
            will allow you to see how many tasks and notifications you have at a
            glance.
        </Typography>
    ) : android ? (
        <Typography>
            Installing {APP_NAME} as an app will let you launch it faster, and
            will allow you to receive alerts of new notifications on the app.
        </Typography>
    ) : (
        <Typography>
            Installing {APP_NAME} as an app will let you see how many tasks and
            notifications you have at a glance, and will allow you to start{" "}
            {APP_NAME} automatically when you log in. <br />
            <br /> For the best experience, install using Microsoft Edge.
        </Typography>
    );

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                sx: borderSX,
            }}
        >
            <InfoDialog
                heading={`Why install ${APP_NAME}?`}
                closeDialog={() => {
                    setOpen(false);
                }}
            >
                {content}
            </InfoDialog>
        </Dialog>
    );
};

MoreInfoModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default MoreInfoModal;
