import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";
import { getPreviousDate } from "@sse-digital-services/design-system";

const iBuyRequisitionAPIService = apiService(apiConfig.requisitions);

const startDate = getPreviousDate(60);

const IBuyRequisitionClient = {
    getApprovals: () => iBuyRequisitionAPIService.get("approvals", { n: 100 }),
    getRequisition: (id) => iBuyRequisitionAPIService.get(`requisitions/${id}`),
    getRequisitionList: (numRequisitions) =>
        iBuyRequisitionAPIService.get(`requisitions`, {
            mode: "MY",
            order_by: ["submittedDate", "DESC"],
            n: numRequisitions,
            date__gte: startDate,
        }),
};

export default IBuyRequisitionClient;
