import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import integrationsClient from "../clients/integrationsClient";
import useServiceNowUser from "./useServiceNowUser";
import { SERVICENOW } from "../../../constants/serviceNames";
import serviceNowMapper from "../mappers/serviceNowMapper";
import useSWRInfinite from "swr/infinite";
import { SERVICENOW_REQUEST_ROWS_PER_PAGE } from "../../../constants/generalConstants";
import { useMemo } from "react";
import { countAPIItems, reduceAPIItems } from "../../../utils/apiItemUtils";

const useServiceNowRequests = (viewClosed) => {
    const { servicesInMaintenanceMode, preferencesAreLoading } =
        usePreferences();
    const {
        serviceNowUser,
        serviceNowUserIsLoading,
        errorMessage: serviceNowUserErrorMessage,
    } = useServiceNowUser();

    const { throwAsyncError } = useErrorHandling();

    const getCacheKey = (pageCount) => {
        if (preferencesAreLoading || serviceNowUserIsLoading) {
            return null;
        }
        return ["ServiceNowRequests", pageCount, viewClosed];
    };

    const {
        data: serviceNowRequestsData,
        size: loadingPageCount,
        setSize: setPagesToLoad,
        error: requestsError,
    } = useSWRInfinite(
        getCacheKey,
        async (args) => {
            try {
                const params = {
                    limit: SERVICENOW_REQUEST_ROWS_PER_PAGE,
                    offset: args[1] * SERVICENOW_REQUEST_ROWS_PER_PAGE,
                    show_closed: args[2],
                };
                if (servicesInMaintenanceMode && serviceNowUser) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === SERVICENOW
                        )
                    ) {
                        const serviceNowRequestsData =
                            await integrationsClient.getRequests(params, {
                                "sn-user-data-token": serviceNowUser,
                            });
                        return serviceNowMapper.mapAPIARequestsToRequests(
                            serviceNowRequestsData
                        );
                    }
                }
            } catch (error) {
                // Display message from API in case of 404 (user not found in ITSC)
                let message =
                    error?.response?.data?.errorCode === 404
                        ? error?.response?.data?.detail
                        : "Unable to fetch ITSC requests";
                throwAsyncError(
                    ErrorTypes.ServiceNowRequestsError,
                    message,
                    error
                );
                throw new Error("Unable to fetch ITSC requests");
            }
        },
        { fallbackData: [], parallel: true, revalidateFirstPage: false }
    );

    const serviceNowRequests = useMemo(() => {
        return reduceAPIItems(serviceNowRequestsData, []);
    }, [serviceNowRequestsData]);

    const serviceNowRequestsCount = useMemo(() => {
        return countAPIItems(serviceNowRequestsData);
    }, [serviceNowRequestsData]);

    return {
        serviceNowRequests,
        serviceNowRequestsCount,
        loadingPageCount,
        setPagesToLoad,
        errorMessage: serviceNowUserErrorMessage || requestsError?.message,
    };
};

export default useServiceNowRequests;
