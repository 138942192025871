import { useMemo } from "react";
import accessibilityClient from "../clients/accessibilityClient";
import accessibilityPreferencesMapper from "../mappers/accessibilityPreferencesMapper";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import {
    DARK_THEME,
    HIGH_CONTRAST_THEME,
    LIGHT_THEME,
} from "@sse-digital-services/design-system";

const useAccessibility = () => {
    const { throwAsyncError } = useErrorHandling();

    const {
        data: accessibilitySettings,
        mutate: refreshAccessibilityPreferences,
        isLoading: accessibilityPreferencesAreLoading,
    } = useSWR(
        "accessibilityPreferences",
        async () => {
            try {
                const accessibilityPreferences =
                    await accessibilityClient.getShopfrontAccessibilitySettings();
                return accessibilityPreferencesMapper.mapAccessibilitySettings(
                    accessibilityPreferences
                );
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.FetchAccessibilityAPIError,
                    "Unable to fetch accessibility preferences",
                    error
                );
            }
        },
        { fallbackData: null }
    );

    const updateAccessibilitySettings = async (newAccessibilitySettings) => {
        const optimisitcNewSettings = {
            ...accessibilitySettings,
            ...newAccessibilitySettings,
        };
        await refreshAccessibilityPreferences(
            async () => {
                try {
                    const preferencesUpdate =
                        accessibilityPreferencesMapper.mapAccessibilitySettingsToShopfront(
                            newAccessibilitySettings
                        );
                    const accessibilityPreferences =
                        await accessibilityClient.updateShopfrontAccessibilitySettings(
                            preferencesUpdate
                        );
                    return accessibilityPreferencesMapper.mapAccessibilitySettings(
                        accessibilityPreferences
                    );
                } catch (error) {
                    throwAsyncError(
                        ErrorTypes.PostAccessibilityAPIError,
                        "Unable to update accessibility preferences - your changes may not have been saved",
                        error
                    );
                }
            },
            {
                optimisticData: optimisitcNewSettings,
                rollbackOnError: false,
                revalidate: false,
            }
        );
    };

    const themeMode = useMemo(() => {
        if (accessibilitySettings?.highContrastMode) {
            return HIGH_CONTRAST_THEME;
        } else if (accessibilitySettings?.darkMode) {
            return DARK_THEME;
        } else {
            return LIGHT_THEME;
        }
    }, [
        accessibilitySettings?.highContrastMode,
        accessibilitySettings?.darkMode,
    ]);

    return {
        accessibilitySettings,
        themeMode,
        updateAccessibilitySettings,
        accessibilityPreferencesAreLoading,
    };
};

export default useAccessibility;
