import useNavigationItems from "./useNavigationItems";
import { BottomNavigationBar } from "@sse-digital-services/design-system";
import { ChatBotDrawer } from "../chatBot/chatBotDrawer";
import * as React from "react";
import NotificationsDrawer from "../modals/notifications/notificationsDrawer";

const SfBottomNavigationBar = () => {
    const { navItems } = useNavigationItems();
    return (
        <>
            <BottomNavigationBar
                navItems={navItems.filter((item) => item.showOnMobileBottomBar)}
            />
            <NotificationsDrawer />
            <ChatBotDrawer />
        </>
    );
};

export default SfBottomNavigationBar;
