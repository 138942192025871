import { GenericErrorPage } from "@sse-digital-services/design-system";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../redux/actions/errorActions";
import { useCallback } from "react";
import { triggerSnackbar } from "../utils/alertsUtils";
import { APP_NAME } from "../constants/generalConstants";

export const ErrorTypes = {
    IBuyCartsAPIError: "iBuyCartsAPIError",
    IBuyApprovalsAPIError: "iBuyApprovalsAPIError",
    IBuyRequisitionsAPIError: "iBuyRequisitionsAPIError",
    IBuySearchAPIError: "iBuySearchAPIError",
    FetchPreferencesAPIError: "fetchPreferencesAPIError",
    PostPreferencesAPIError: "postPreferencesAPIError",
    FetchAccessibilityAPIError: "fetchAccessibilityAPIError",
    PostAccessibilityAPIError: "postAccessibilityAPIError",
    NotificationsAPIError: "notificationsAPIError",
    PayslipsAPIError: "payslipsAPIError",
    GraphAPIError: "graphAPIError",
    ServiceNowRequestsError: "serviceNowRequestsError",
    ServiceNowApprovalsError: "serviceNowApprovalsError",
    ServiceNowProcureToPayApprovalsError:
        "serviceNowProcureToPayApprovalsError",
    ServiceNowProcureToRequestItemsError:
        "serviceNowProcureToPayRequestItemsError",
    ServiceNowIncidentsError: "serviceNowIncidentsError",
    ServiceNowPtpIncidentsError: "serviceNowPtpIncidentsError",
    ServiceNowUserError: "serviceNowUserError",
    SharePriceAPIError: "sharePriceAPIError",
    NotificationCounterAPIError: "notificationCounterAPIError",
    UserProfileAPIError: "userProfileAPIError",
    SearchAssistantAPIError: "searchAssistantAPIError",
    NPSAPIError: "npsAPIError",
    EdisonReportAPIError: "edisonReportAPIError",
    DocumentProcessorAPIError: "documentProcessorAPIError",
    LMSLearningsAPIError: "LMSLearningsAPIError",
};

const useErrorHandling = () => {
    const dispatch = useDispatch();
    const errorState = useSelector((state) => state.error);

    const logError = useCallback((errorMessage, innerError) => {
        console.error(`Error: ${errorMessage}`);
        if (innerError) {
            console.error("Original error: ", innerError);
        }
    }, []);

    const logWarning = useCallback((errorMessage, innerError) => {
        console.warn(`Warning: ${errorMessage}`);
        if (innerError) {
            console.warn("Original exception: ", innerError);
        }
    }, []);

    const handleError = useCallback(
        (errorType, errorMessage, innerError) => {
            logError(errorMessage, innerError);
            switch (errorType) {
                case ErrorTypes.FetchPreferencesAPIError:
                case ErrorTypes.UserProfileAPIError:
                    return (
                        <GenericErrorPage
                            appName={APP_NAME}
                            message={errorMessage}
                        />
                    );
                default:
                    return <GenericErrorPage appName={APP_NAME} />;
            }
        },
        [logError]
    );

    const resetErrors = useCallback(() => {
        dispatch(setError(null));
    }, []);

    const handleErrors = useCallback(
        (thrownError, errorState) => {
            if (thrownError?.errorType) {
                return handleError(
                    thrownError.errorType,
                    thrownError.errorMessage,
                    thrownError
                );
            }
            if (errorState) {
                return handleError(
                    errorState.errorType,
                    errorState.errorMessage,
                    errorState.innerError
                );
            }
            return <GenericErrorPage appName={APP_NAME} />;
        },
        [errorState, handleError, resetErrors]
    );

    const throwAsyncError = (errorType, errorMessage, innerError) => {
        switch (errorType) {
            case ErrorTypes.IBuyCartsAPIError:
            case ErrorTypes.IBuyApprovalsAPIError:
            case ErrorTypes.IBuyRequisitionsAPIError:
            case ErrorTypes.IBuySearchAPIError:
            case ErrorTypes.PostPreferencesAPIError:
            case ErrorTypes.FetchAccessibilityAPIError:
            case ErrorTypes.PostAccessibilityAPIError:
            case ErrorTypes.NotificationsAPIError:
            case ErrorTypes.ServiceNowRequestsError:
            case ErrorTypes.ServiceNowApprovalsError:
            case ErrorTypes.ServiceNowProcureToPayApprovalsError:
            case ErrorTypes.ServiceNowProcureToRequestItemsError:
            case ErrorTypes.ServiceNowIncidentsError:
            case ErrorTypes.ServiceNowPtpIncidentsError:
            case ErrorTypes.ServiceNowUserError:
            case ErrorTypes.PayslipsAPIError:
            case ErrorTypes.SharePriceAPIError:
            case ErrorTypes.SearchAssistantAPIError:
            case ErrorTypes.NotificationCounterAPIError:
            case ErrorTypes.NPSAPIError:
            case ErrorTypes.EdisonReportAPIError:
            case ErrorTypes.DocumentProcessorAPIError:
            case ErrorTypes.LMSLearningsAPIError:
                logError(errorMessage, innerError);
                triggerSnackbar("error", errorMessage, null, dispatch);
                break;
            case ErrorTypes.GraphAPIError:
                logWarning(errorMessage, innerError);
                break;
            default:
                dispatch(setError({ errorType, errorMessage, innerError }));
        }
    };

    const throwError = (error, errorType, errorMessage) => {
        const newError = error ?? new Error();
        newError.errorType = errorType;
        newError.errorMessage = errorMessage;
        throw newError;
    };

    return {
        errorState,
        handleErrors,
        throwAsyncError,
        throwError,
        resetErrors,
    };
};

export default useErrorHandling;
