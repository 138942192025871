import React from "react";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { InfoOutlined } from "@mui/icons-material";
import { styledDashboardTileActionButtonSX } from "styled/styledSXProperties/styledDashboardTileCardSX";
import { StyledTooltip } from "@sse-digital-services/design-system";

export const MoreInfoButton = ({
    ariaLabel,
    dataTrackId,
    openMoreInfoModal,
}) => {
    return (
        <StyledTooltip title={"More Info"}>
            <IconButton
                aria-label={ariaLabel}
                data-track-id={dataTrackId}
                size="small"
                onClick={(e) => {
                    openMoreInfoModal();
                    e.stopPropagation();
                }}
                sx={{ color: "text.primary" }}
            >
                <InfoOutlined
                    sx={{
                        ...styledDashboardTileActionButtonSX,
                        backgroundColor: "background.paper",
                    }}
                />
            </IconButton>
        </StyledTooltip>
    );
};

MoreInfoButton.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    dataTrackId: PropTypes.string.isRequired,
    openMoreInfoModal: PropTypes.func.isRequired,
};
