export const validateHttpsUrl = (url) => {
    try {
        const urlObject = new URL(url);
        return (
            urlObject.protocol === "https:" &&
            !url.includes(" ") &&
            urlObject.host.includes(".")
        );
    } catch {
        return false;
    }
};
