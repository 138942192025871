import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styledDividerSX } from "@sse-digital-services/design-system";
import React, { useState } from "react";
import NotificationCard from "./notificationCard";
import useNotifications from "../../../services/apiUtils/hooks/useNotifications";
import { NOTIFICATION_COUNT_PER_PAGE } from "../../../constants/generalConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import NotificationCardSkeleton from "../../skeletons/notificationCardSkeleton";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const InnerList = ({ results, isLoading }) => {
    let resultsToDisplay = results;
    if (isLoading) {
        resultsToDisplay = new Array(NOTIFICATION_COUNT_PER_PAGE).fill(null);
    }
    return (
        <>
            {resultsToDisplay.map((card, index) => (
                <Box key={index} m={1}>
                    {isLoading ? (
                        <NotificationCardSkeleton />
                    ) : (
                        <NotificationCard {...card} />
                    )}
                </Box>
            ))}
        </>
    );
};

InnerList.propTypes = {
    results: PropTypes.array,
    isLoading: PropTypes.bool,
};

const Notifications = ({ scrollBoxId }) => {
    const { notifications, setPageIndex, notificationsAreLoading } =
        useNotifications();
    const [page, setPage] = useState(1);

    const getNextPage = () => {
        setPageIndex(page + 1);
        setPage(page + 1);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                p: 1,
                backgroundColor: "background.main",
            }}
        >
            <InfiniteScroll
                dataLength={notifications.data.length} //This is important field to render the next data
                next={getNextPage}
                scrollThreshold="70%"
                hasMore={
                    page <
                    Math.ceil(
                        notifications.total_count / NOTIFICATION_COUNT_PER_PAGE
                    )
                }
                loader={
                    <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        my={2}
                    >
                        <CircularProgress
                            id="notifications-circular-progress"
                            color="info"
                            aria-label="notifications-circular-progress"
                        />
                    </Box>
                }
                endMessage={
                    <>
                        <Divider
                            sx={{
                                ...styledDividerSX,
                                margin: "0px 0px 36px 0px",
                            }}
                        />
                        {!notifications.total_count && (
                            <Typography variant={"h4"} align={"center"}>
                                You have no notifications at this time
                            </Typography>
                        )}
                    </>
                }
                scrollableTarget={scrollBoxId}
            >
                <InnerList
                    results={notifications.data}
                    isLoading={notificationsAreLoading}
                />
            </InfiniteScroll>
        </Box>
    );
};

Notifications.propTypes = {
    scrollBoxId: PropTypes.string.isRequired,
};

export default Notifications;
