import React from "react";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {
    ChevronRight,
    getDeviceInfo,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import useDashboardTiles from "../tiles/useDashboardTiles";
import PropTypes from "prop-types";

const LinkMenu = ({
    links,
    anchorEl,
    menuId,
    onClose,
    onMouseEnter,
    onMouseLeave,
    menuSx,
}) => {
    const { isEditing } = useDashboardTiles();
    const { borderSX } = useHighContrastBorderSX();
    const open = Boolean(anchorEl);
    const { handheld } = getDeviceInfo();
    const popoverSX = handheld ? {} : { pointerEvents: "none" };

    return (
        <Menu
            slotProps={{
                paper: { sx: { ...borderSX, borderRadius: "1px" } },
            }}
            id={menuId}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            MenuListProps={{
                "aria-labelledby": `${menuId}-list`,
                onMouseEnter: onMouseEnter,
                onMouseLeave: onMouseLeave,
                style: { pointerEvents: "auto" },
            }}
            sx={{
                mt: 0,
                ml: "-8px",
                "&.MuiPopover-root": { ...popoverSX },
                ...menuSx,
            }}
        >
            {Object.keys(links).map((link) => (
                <MenuItem
                    key={link}
                    component="a"
                    data-track-id={`${link}-link`}
                    sx={{
                        width: "100%",
                        my: 1,
                        justifyContent: "space-between",
                        pl: "8px",
                    }}
                    href={isEditing ? undefined : links[link]}
                    target="_blank"
                >
                    <Typography variant={"body3"}>{link}</Typography>
                    <ChevronRight
                        fontSize={"small"}
                        sx={{
                            ml: "15px",
                            borderRadius: "50%",
                            border: "2px solid",
                        }}
                    />
                </MenuItem>
            ))}
        </Menu>
    );
};

LinkMenu.propTypes = {
    links: PropTypes.object.isRequired,
    anchorEl: PropTypes.object,
    menuId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    menuSx: PropTypes.object,
};

export default LinkMenu;
