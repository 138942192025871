import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import integrationsClient from "../clients/integrationsClient";
import usePreferences from "./usePreferences";
import { SERVICENOW } from "../../../constants/serviceNames";
import { useMemo } from "react";

const useServiceNowUser = () => {
    const {
        servicesInMaintenanceMode,
        servicesBasedOnPermissions,
        preferencesAreLoading,
    } = usePreferences();
    const { throwAsyncError } = useErrorHandling();

    const {
        data: serviceNowUser,
        mutate: getServiceNowUserId,
        isLoading: serviceNowUserDataIsLoading,
        error: serviceNowError,
    } = useSWR(
        () => (preferencesAreLoading ? null : "ServiceNowUser"),
        async () => {
            try {
                if (
                    servicesInMaintenanceMode &&
                    servicesBasedOnPermissions.includes(SERVICENOW)
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === SERVICENOW
                        )
                    ) {
                        const serviceNowUserData =
                            await integrationsClient.getUser();
                        return serviceNowUserData.itsc_user_id;
                    }
                }
            } catch (error) {
                // Display message from API in case of 404 (user not found in ITSC)
                let message =
                    error?.response?.data?.errorCode === 404 ||
                    error?.response?.data?.errorCode === "SF-ITG-SN-400"
                        ? error?.response?.data?.detail
                        : "Unable to fetch ITSC User ID";
                throwAsyncError(ErrorTypes.ServiceNowUserError, message, error);
                throw new Error("Unable to retrieve user data from ITSC");
            }
        },
        { fallbackData: "" }
    );

    const serviceNowUserIsLoading = useMemo(
        () => serviceNowUserDataIsLoading || preferencesAreLoading,
        [serviceNowUserDataIsLoading, preferencesAreLoading]
    );

    return {
        serviceNowUser,
        getServiceNowUserId,
        serviceNowUserIsLoading,
        errorMessage: serviceNowError?.message,
    };
};

export default useServiceNowUser;
