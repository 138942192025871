import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Typography,
    useTheme,
} from "@mui/material";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import React from "react";
import { buttonSX } from "styled/styledSXProperties/styledUserInput";
import PropTypes from "prop-types";

export const ConfirmationDialog = ({
    open,
    onCancel,
    onConfirm,
    dialogTitle,
    actionInProgress = false,
    children,
}) => {
    const { borderSX } = useHighContrastBorderSX();
    const theme = useTheme();
    return (
        <Dialog open={open} onClose={onCancel} PaperProps={{ sx: borderSX }}>
            <DialogTitle>
                <Typography variant="h4" component="p">
                    {dialogTitle}
                </Typography>
            </DialogTitle>
            {children && (
                <DialogContent sx={{ padding: "0 24px", width: "100%" }}>
                    {children}
                </DialogContent>
            )}
            <DialogActions>
                {actionInProgress && (
                    <LinearProgress
                        variant="indeterminate"
                        sx={{ width: "100%", height: "10px", mr: "5px" }}
                    />
                )}
                <Button
                    onClick={onCancel}
                    sx={{
                        ...borderSX,
                        ...buttonSX(theme),
                    }}
                    disabled={actionInProgress}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    onClick={onConfirm}
                    sx={{
                        ...borderSX,
                        ...buttonSX(theme),
                    }}
                    disabled={actionInProgress}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    actionInProgress: PropTypes.bool,
    children: PropTypes.element,
};
