import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import { useDispatch } from "react-redux";
import { triggerSnackbar } from "utils/alertsUtils";
import documentProcessorClient from "../clients/documentProcessorClient";
import useSWR from "swr";
import usePreferences from "./usePreferences";
import {
    COSMOS_THROTTLING_ERROR,
    SHAREPOINT_DOCUMENT_NOT_FOUND,
} from "../../../constants/customErrorCodes";
import documentProcessorMapper from "../mappers/documentProcessorMapper";

const useDocumentProcessor = () => {
    const dispatch = useDispatch();
    const { throwAsyncError } = useErrorHandling();
    const { preferencesAreLoading, isAdmin } = usePreferences();

    const customErrorCodes = new Set([
        SHAREPOINT_DOCUMENT_NOT_FOUND,
        COSMOS_THROTTLING_ERROR,
    ]);

    const triggerSharepointScraping = async () => {
        try {
            const scrape = documentProcessorClient.postScrapeAll();
            triggerSnackbar(
                "info",
                "SharePoint scraping triggered to run in the background, please come back later to check for an updated 'last scraped' time!",
                null,
                dispatch
            );
            await scrape;
        } catch (error) {
            if (error.code !== "ECONNABORTED") {
                let message = customErrorCodes.has(
                    error?.response?.data?.errorCode
                )
                    ? error.response.data.detail
                    : "Unable to process Sharepoint sites.";
                throwAsyncError(
                    ErrorTypes.DocumentProcessorAPIError,
                    message,
                    error
                );
            }
        }
    };

    const addToCognitiveSearch = async (url, onSuccess) => {
        try {
            const cleanedUrl = documentProcessorMapper.mapUrlToAPIUrl(url);
            await documentProcessorClient.postScrape({
                sharepoint_url: cleanedUrl,
            });
            triggerSnackbar(
                "success",
                "Adding to Edison's knowledge base successful",
                null,
                dispatch
            );
            onSuccess && onSuccess();
        } catch (error) {
            let message = customErrorCodes.has(error?.response?.data?.errorCode)
                ? error.response.data.detail
                : "Unable to add document to Edison's knowledge base";
            throwAsyncError(
                ErrorTypes.DocumentProcessorAPIError,
                message,
                error
            );
        }
    };

    const deleteFromCognitiveSearch = async (url, onSuccess) => {
        const cleanedUrl = documentProcessorMapper.mapUrlToAPIUrl(url);
        try {
            await documentProcessorClient.postDelete({
                sharepoint_url: cleanedUrl,
            });
            triggerSnackbar(
                "success",
                "Deletion from Edison's knowledge base successful",
                null,
                dispatch
            );
            onSuccess && onSuccess();
        } catch (error) {
            let message = customErrorCodes.has(error?.response?.data?.errorCode)
                ? error.response.data.detail
                : "Unable to remove document from Edison's knowledge base";

            throwAsyncError(
                ErrorTypes.DocumentProcessorAPIError,
                message,
                error
            );
        }
    };

    const { data: latestScrapedDate } = useSWR(
        () => (preferencesAreLoading ? null : "lastProcessed"),
        async () => {
            if (isAdmin) {
                try {
                    const scrapedDate =
                        await documentProcessorClient.getTimestamp();
                    return scrapedDate?.last_processed;
                } catch (error) {
                    throwAsyncError(
                        ErrorTypes.DocumentProcessorAPIError,
                        "Unable to fetch date of last SharePoint processing.",
                        error
                    );
                }
            }
        },
        { fallbackData: null }
    );

    return {
        triggerSharepointScraping,
        addToCognitiveSearch,
        deleteFromCognitiveSearch,
        latestScrapedDate,
    };
};

export default useDocumentProcessor;
