const edisonReportMapper = {
    mapAPIReportDataToReport: (apiReportData) => ({
        count: apiReportData.total_count,
        data: apiReportData?.data?.map((apiReport) => ({
            id: apiReport.id,
            conversationLength: apiReport.total_length,
            timestamp: apiReport.timestamp,
            query: apiReport.query,
            searchQuery: apiReport.search_query,
            response: apiReport.response,
            searchResults: apiReport.search_results,
        })),
    }),
};

export default edisonReportMapper;
