import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import useSWRInfinite from "swr/infinite";
import { EDISON_QA_PER_PAGE } from "../../../constants/generalConstants";
import { useEffect, useMemo } from "react";
import edisonReportingClient from "../clients/edisonReportingClient";
import edisonReportMapper from "../mappers/edisonReportMapper";

const useEdisonReport = () => {
    const { preferencesAreLoading, isEdisonAdmin } = usePreferences();
    const { throwAsyncError } = useErrorHandling();

    const getEdisonReportDataCacheKey = (pageIndex) => {
        if (preferencesAreLoading || !isEdisonAdmin) {
            return null;
        }
        return ["edisonReport", pageIndex];
    };

    const {
        data: edisonReportData,
        size: lastPageIndex,
        setSize: setPageIndex,
        isLoading: edisonReportIsLoading,
    } = useSWRInfinite(
        getEdisonReportDataCacheKey,
        async (args) => {
            const params = {
                limit: EDISON_QA_PER_PAGE,
                offset: args[1] * EDISON_QA_PER_PAGE,
            };
            try {
                const apiReportPageData =
                    await edisonReportingClient.getReport(params);
                return edisonReportMapper.mapAPIReportDataToReport(
                    apiReportPageData
                );
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.EdisonReportAPIError,
                    "Unable to fetch Edison QA Report",
                    error
                );
                return {
                    count: 0,
                    data: [],
                };
            }
        },
        {
            fallbackData: [],
            parallel: true,
            revalidateFirstPage: false,
        }
    );
    const edisonReportIsLoadingMore =
        edisonReportIsLoading ||
        (lastPageIndex > 0 &&
            edisonReportData &&
            typeof edisonReportData[lastPageIndex - 1] === "undefined");

    const report = useMemo(() => {
        if (edisonReportData?.length > 0) {
            return edisonReportData.reduce(
                (result, reportDataPage) => {
                    if (reportDataPage) {
                        return {
                            ...reportDataPage,
                            data: [...result.data, ...reportDataPage.data],
                        };
                    }
                },
                {
                    count: 0,
                    data: [],
                }
            );
        }
        return {
            count: 0,
            data: [],
        };
    }, [edisonReportData]);

    useEffect(() => {
        if (lastPageIndex === 1 && report?.count > EDISON_QA_PER_PAGE) {
            setPageIndex(2);
        }
    }, [lastPageIndex, report?.count, setPageIndex]);

    return {
        report,
        lastPageIndex,
        setPageIndex,
        edisonReportIsLoading,
        edisonReportIsLoadingMore,
    };
};

export default useEdisonReport;
