import React from "react";
import { REQUISITION_ROWS_PER_PAGE } from "../../../constants/generalConstants";
import PropTypes from "prop-types";
import PaginatedTable from "../../tables/paginatedTable";
import { iBuyMainURL } from "../../../services/apiUtils/config/urls";
import { PROCUREMENT, serviceNames } from "../../../constants/serviceNames";
import TileAccordion from "../tileAccordion";
import useIBuyRequisitions from "../../../services/apiUtils/hooks/useIBuyRequisitions";
import IBuyRequisitionRow from "./iBuyRequisitionRow";

const IBuyRequisitionsSection = ({ isEditing, maintenanceMode = false }) => {
    const { requisitionList, errorMessage } = useIBuyRequisitions();

    const totalItems = requisitionList ? requisitionList.length : 0;

    return (
        <TileAccordion
            serviceName={serviceNames[PROCUREMENT]}
            tileName="requests"
            maintenanceMode={maintenanceMode}
            errorMessage={errorMessage}
            count={totalItems}
            isEditing={isEditing}
        >
            <PaginatedTable
                totalItems={totalItems}
                isEditing={isEditing}
                serviceUrl={`${iBuyMainURL}/requisitions?mode=MY&order_by=submittedDate+DESC`}
                itemsPerPage={REQUISITION_ROWS_PER_PAGE}
                service={PROCUREMENT}
                items={requisitionList ?? []}
                Row={IBuyRequisitionRow}
            />
        </TileAccordion>
    );
};

IBuyRequisitionsSection.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    maintenanceMode: PropTypes.bool,
};

export default IBuyRequisitionsSection;
