import useSWR from "swr";
import npsClient from "../clients/npsClient";
import npsMapper from "../mappers/npsMapper";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import useSWRInfinite from "swr/infinite";
import { FEEDBACK_PER_PAGE } from "../../../constants/generalConstants";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCheckFeedbackAPI } from "../../../redux/actions/generalActions";
import { triggerSnackbar } from "../../../utils/alertsUtils";

const useNPS = () => {
    const dispatch = useDispatch();
    const shouldCheckFeedbackAPI = useSelector(
        (state) => state.general.checkFeedbackAPI
    );
    const { preferencesAreLoading, isManager } = usePreferences();
    const { throwAsyncError } = useErrorHandling();

    const setCheckFeedbackAPIState = useCallback(
        (newFlag) => {
            dispatch(setCheckFeedbackAPI(newFlag));
        },
        [dispatch, setCheckFeedbackAPI]
    );

    const { data: shouldSeekFeedback } = useSWR(
        shouldCheckFeedbackAPI ? "showNPSForm" : null,
        async () => {
            try {
                const apiShowNPSForm = await npsClient.shouldShowFeedbackForm();
                return npsMapper.mapAPIShowformToShowform(apiShowNPSForm);
            } catch (error) {
                console.error(`Unable to contact NPS API: ${error}`);
            }
        },
        { fallbackData: false }
    );

    const { data: averageRating } = useSWR(
        () => (!preferencesAreLoading && isManager ? "averageNPSRating" : null),
        async () => {
            try {
                const apiAverageRating = await npsClient.getAverageRating();
                return npsMapper.mapAPIAverageRatingToAverageRating(
                    apiAverageRating
                );
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.NPSAPIError,
                    "Unable to fetch average rating",
                    error
                );
            }
        },
        { fallbackData: null }
    );

    const getNPSDataCacheKey = (pageIndex) => {
        if (preferencesAreLoading || !isManager) {
            return null;
        }
        return ["npsData", pageIndex];
    };

    const {
        data: npsData,
        size: lastPageIndex,
        setSize: setPageIndex,
        isLoading: npsFeedbackIsLoading,
        mutate: refreshNPSFeedback,
    } = useSWRInfinite(
        getNPSDataCacheKey,
        async (args) => {
            const params = {
                limit: FEEDBACK_PER_PAGE,
                offset: args[1] * FEEDBACK_PER_PAGE,
            };
            try {
                const apiNPSPageData = await npsClient.getFeedback(params);
                return npsMapper.mapAPIFeedbackDataToNPSFeedback(
                    apiNPSPageData
                );
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.NPSAPIError,
                    "Unable to fetch NPS feedback",
                    error
                );
                return {
                    count: 0,
                    data: [],
                };
            }
        },
        {
            fallbackData: [],
            parallel: true,
            revalidateFirstPage: false,
        }
    );
    const npsDataIsLoadingMore =
        npsFeedbackIsLoading ||
        (lastPageIndex > 0 &&
            npsData &&
            typeof npsData[lastPageIndex - 1] === "undefined");

    const feedback = useMemo(() => {
        if (npsData?.length > 0) {
            return npsData.reduce(
                (result, npsDataPage) => {
                    if (npsDataPage) {
                        return {
                            ...npsDataPage,
                            data: [...result.data, ...npsDataPage.data],
                        };
                    }
                },
                {
                    count: 0,
                    data: [],
                }
            );
        }
        return {
            count: 0,
            data: [],
        };
    }, [npsData]);

    useEffect(() => {
        if (lastPageIndex === 1 && feedback?.count > FEEDBACK_PER_PAGE) {
            setPageIndex(2);
        }
    }, [lastPageIndex, feedback?.count, setPageIndex]);

    const submitNPSFeedback = async (feedback) => {
        try {
            const feedbackToSubmit =
                npsMapper.mapNewFeedbackToAPIFeedback(feedback);
            await npsClient.postFeedback(feedbackToSubmit);
            await refreshNPSFeedback();
            triggerSnackbar(
                "success",
                "Thank you for your feedback",
                null,
                dispatch
            );
        } catch (error) {
            throwAsyncError(
                ErrorTypes.NPSAPIError,
                "Unable to submit your feedback",
                error
            );
        }
    };

    return {
        shouldSeekFeedback,
        averageRating,
        feedback,
        lastPageIndex,
        setPageIndex,
        npsFeedbackIsLoading,
        submitNPSFeedback,
        setCheckFeedbackAPIState,
        npsDataIsLoadingMore,
    };
};

export default useNPS;
