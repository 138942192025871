import {
    setShowFeedbackForm,
    setShowFeedbackPrompt,
} from "../../../redux/actions/generalActions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Slide, useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import {
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import useNPS from "../../../services/apiUtils/hooks/useNPS";

const NpsButton = () => {
    const showPrompt = useSelector((state) => state.general.showFeedbackPrompt);
    const dispatch = useDispatch();
    const { borderSX } = useHighContrastBorderSX();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const { handheld } = getDeviceInfo();
    const { shouldSeekFeedback } = useNPS();
    const setShowPrompt = (newShowPrompt) => {
        dispatch(setShowFeedbackPrompt(newShowPrompt));
    };

    const setShowForm = (newShowForm) => {
        dispatch(setShowFeedbackForm(newShowForm));
    };

    const handleClick = () => {
        setShowPrompt(false);
        setShowForm(true);
    };

    const showButton =
        isDesktopWidth && !handheld && !(shouldSeekFeedback && showPrompt);

    return (
        <Slide
            direction="left"
            in={showButton}
            mountOnEnter={true}
            unmountOnExit={true}
        >
            <Box
                sx={{
                    position: "absolute",
                    right: 0,
                    left: "auto",
                    top: "100px",
                    margin: "10px 0 0 auto",
                    zIndex: "fab",
                    height: "144px",
                    width: "51px",
                }}
            >
                <Paper
                    sx={{
                        ...borderSX,
                        backgroundColor: "secondary.dark",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "0px",
                        boxSizing: "border-box",
                        padding: 0,
                        right: 0,
                        zIndex: "fab",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <Button
                        data-track-id="nps-give-feedback-button"
                        onClick={handleClick}
                        sx={{
                            padding: "13px",
                            transform: "rotate(-90deg)",
                            width: "144px",
                            height: "51px",
                            minWidth: "144px",
                        }}
                    >
                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "common.white",
                                textTransform: "none",
                                width: "144px",
                                height: "51px",
                                textAlign: "center",
                            }}
                            variant={"h5"}
                            component="p"
                        >
                            Give Feedback
                        </Typography>
                    </Button>
                </Paper>
            </Box>
        </Slide>
    );
};

export default NpsButton;
