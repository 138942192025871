import PropTypes from "prop-types";
import * as React from "react";
import { ButtonBase, useMediaQuery } from "@mui/material";
import useDashboardTiles from "../useDashboardTiles";
import QuickActionTileContent from "./quickActionTileContent";
import { quickActionTileButtonSX } from "styled/styledSXProperties/styledDashboardTileCardSX";
import { DESKTOP_MEDIA_QUERY } from "@sse-digital-services/design-system";

const SingleLinkTile = ({
    title,
    link,
    linkText,
    trackingId,
    alternativeText,
    descriptionSx,
    titleSx,
}) => {
    const { isEditing } = useDashboardTiles();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    return (
        <ButtonBase
            data-track-id={`${title}-quick-link-button`}
            aria-label={`${title}-quick-link-button`}
            href={isEditing ? null : link}
            sx={quickActionTileButtonSX(isDesktopWidth)}
            target="_blank"
            disabled={!!alternativeText}
        >
            <QuickActionTileContent
                title={title}
                descriptionText={alternativeText || linkText}
                trackingId={trackingId}
                showChevron={!alternativeText}
                descriptionSx={descriptionSx}
                titleSx={titleSx}
            ></QuickActionTileContent>
        </ButtonBase>
    );
};

SingleLinkTile.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    alternativeText: PropTypes.string,
    trackingId: PropTypes.string,
    descriptionSx: PropTypes.object,
    titleSx: PropTypes.object,
};

export default SingleLinkTile;
