import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ContrastOutlined, SwapVert } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    HIGH_CONTRAST_THEME,
    SelectColorDialog,
} from "@sse-digital-services/design-system";
import useSfThemes from "../../../utils/useSfThemes";
import useAccessibility from "../../../services/apiUtils/hooks/useAccessibility";
import SettingsToggle from "./settingsToggle";

const HighContrastSection = () => {
    const theme = useTheme();
    const { accessibilitySettings, updateAccessibilitySettings } =
        useAccessibility();
    const toggleHighContrast = async () => {
        if (accessibilitySettings) {
            const highContrastMode = accessibilitySettings.highContrastMode;
            await updateAccessibilitySettings({
                highContrastMode: !highContrastMode,
            });
        }
    };

    const [hcBackgroundPickerOpen, setHcBackgroundPickerOpen] = useState(false);
    const [hcForegroundPickerOpen, setHcForegroundPickerOpen] = useState(false);
    const { setHCBackgroundColour, setHCForegroundColour, setHCColours } =
        useSfThemes();
    const hcBackgroundColour = accessibilitySettings?.highContrastBackground;
    const hcForegroundColour = accessibilitySettings?.highContrastForeground;

    const switchHCColours = async () => {
        await setHCColours(hcForegroundColour, hcBackgroundColour);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginY: "5px",
            }}
        >
            <SettingsToggle
                name={`toggle high contrast mode ${
                    theme.name === HIGH_CONTRAST_THEME ? "off" : "on"
                }`}
                trackingId={`toggle high contrast mode ${
                    theme.name === HIGH_CONTRAST_THEME ? "off" : "on"
                }`}
                checked={theme.name === HIGH_CONTRAST_THEME}
                handleChange={toggleHighContrast}
                label={
                    <>
                        <ContrastOutlined
                            sx={{ color: "icon.secondary", marginRight: "8px" }}
                        />
                        <Typography variant="body1" component="p">
                            High contrast
                        </Typography>
                    </>
                }
            />
            {theme.name === HIGH_CONTRAST_THEME && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                        }}
                    >
                        <FormControl
                            component="fieldset"
                            variant="standard"
                            sx={{ width: "100%" }}
                        >
                            <FormGroup
                                variant="standard"
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginLeft: 0,
                                }}
                            >
                                <FormControlLabel
                                    sx={{
                                        width: "100%",
                                        justifyContent: "space-between",
                                        marginLeft: 0,
                                        marginY: "2px",
                                    }}
                                    control={
                                        <Box
                                            onClick={() =>
                                                setHcBackgroundPickerOpen(true)
                                            }
                                            sx={{
                                                margin: "0 20px",
                                                height: "16px",
                                                width: "16px",
                                                border: `1px solid ${hcForegroundColour}`,
                                                bgcolor: hcBackgroundColour,
                                            }}
                                        />
                                    }
                                    label="Background"
                                    labelPlacement="start"
                                    slotProps={{
                                        typography: {
                                            variant: "body1",
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    sx={{
                                        width: "100%",
                                        justifyContent: "space-between",
                                        marginLeft: 0,
                                        marginY: "2px",
                                    }}
                                    control={
                                        <Box
                                            onClick={() =>
                                                setHcForegroundPickerOpen(true)
                                            }
                                            sx={{
                                                margin: "0 20px",
                                                height: "16px",
                                                width: "16px",
                                                border: `1px solid ${hcForegroundColour}`,
                                                bgcolor: hcForegroundColour,
                                            }}
                                        />
                                    }
                                    label="Foreground"
                                    labelPlacement="start"
                                    slotProps={{
                                        typography: {
                                            variant: "body1",
                                        },
                                    }}
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <IconButton
                        data-track-id={"swap HC colours"}
                        aria-label="swap HC colours"
                        onClick={switchHCColours}
                    >
                        <SwapVert sx={{ color: "icon.secondary" }} />
                    </IconButton>
                    <SelectColorDialog
                        colour={hcBackgroundColour}
                        setColour={setHCBackgroundColour}
                        open={hcBackgroundPickerOpen}
                        setOpen={setHcBackgroundPickerOpen}
                    />
                    <SelectColorDialog
                        colour={hcForegroundColour}
                        setColour={setHCForegroundColour}
                        open={hcForegroundPickerOpen}
                        setOpen={setHcForegroundPickerOpen}
                    />
                </Box>
            )}
        </Box>
    );
};

export default HighContrastSection;
