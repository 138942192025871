import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import integrationsClient from "../clients/integrationsClient";
import useServiceNowUser from "./useServiceNowUser";
import { SERVICENOW } from "../../../constants/serviceNames";
import useSWR from "swr";

const useServiceNowRequestItems = (requestExternalId) => {
    const { servicesInMaintenanceMode, preferencesAreLoading } =
        usePreferences();

    const {
        serviceNowUser,
        serviceNowUserIsLoading,
        errorMessage: serviceNowUserErrorMessage,
    } = useServiceNowUser();

    const { throwAsyncError } = useErrorHandling();

    const cacheKey =
        preferencesAreLoading || serviceNowUserIsLoading
            ? null
            : ["ServiceNowRequestItems", requestExternalId];

    const {
        data: serviceNowRequestItemsData,
        error: requestsError,
        isLoading: requestItemsAreLoading,
    } = useSWR(
        cacheKey,
        async () => {
            try {
                const params = {
                    request_id: requestExternalId,
                };
                if (servicesInMaintenanceMode && serviceNowUser) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === SERVICENOW
                        )
                    ) {
                        const responseData =
                            await integrationsClient.getRequestItems(params, {
                                "sn-user-data-token": serviceNowUser,
                            });
                        return responseData.data;
                    }
                }
            } catch (error) {
                // Display message from API in case of 404 (user not found in ITSC)
                let message =
                    error?.response?.data?.errorCode === 404
                        ? error?.response?.data?.detail
                        : "Unable to fetch ITSC request items";
                throwAsyncError(
                    ErrorTypes.ServiceNowRequestsError,
                    message,
                    error
                );
                throw new Error("Unable to fetch ITSC request items");
            }
        },
        { fallbackData: [] }
    );
    return {
        items: serviceNowRequestItemsData,
        errorMessage: serviceNowUserErrorMessage || requestsError?.message,
        requestItemsAreLoading,
    };
};

export default useServiceNowRequestItems;
