import PropTypes from "prop-types";
import * as React from "react";
import usePayslips from "../../../services/apiUtils/hooks/usePayslips";
import { PAYSLIPS_HISTORICAL } from "../../../constants/externalUrls";
import QuickActionTile from "../templates/quickActionTile";
import SingleLinkTile from "../templates/singleLinkTile";

const PayslipsInfoTile = ({ id }) => {
    const { latestPayslipsDate, errorMessage } = usePayslips();

    const linkText = latestPayslipsDate
        ? `${latestPayslipsDate
              .getDate()
              .toString()} ${latestPayslipsDate.toLocaleString("en-GB", {
              month: "long",
          })}`
        : "No payslips found";
    return (
        <QuickActionTile id={id} errorMessage={errorMessage}>
            <SingleLinkTile
                link={PAYSLIPS_HISTORICAL}
                linkText={linkText}
                title={"Latest Payslip"}
            />
        </QuickActionTile>
    );
};

PayslipsInfoTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default PayslipsInfoTile;
