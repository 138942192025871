import { IconButton } from "@mui/material";
import React from "react";
import { ContentCopyOutlined } from "@mui/icons-material";
import { triggerSnackbar } from "utils/alertsUtils";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { StyledTooltip } from "@sse-digital-services/design-system";

export const CopyToClipboardButton = ({ toCopy, buttonSX }) => {
    const dispatch = useDispatch();
    const onClick = () => {
        navigator.clipboard.writeText(toCopy);
        triggerSnackbar("info", "Message copied to clipboard", null, dispatch);
    };
    return (
        <StyledTooltip title={"Copy to clipboard"}>
            <IconButton
                sx={{ px: "0px" }}
                onClick={onClick}
                data-track-id="copy to clipboard"
                aria-label="copy to clipboard"
            >
                <ContentCopyOutlined
                    sx={{
                        ...buttonSX,
                    }}
                />
            </IconButton>
        </StyledTooltip>
    );
};

CopyToClipboardButton.propTypes = {
    toCopy: PropTypes.string.isRequired,
    buttonSX: PropTypes.object,
};
