import { initialState } from "../initialState";

// Action Types
import {
    SET_IS_EDITING,
    SET_CHECK_FEEDBACK_API,
    SET_SHOW_CHAT_FIELD,
    SET_SHOW_FEEDBACK_FORM,
    SET_SHOW_FEEDBACK_PROMPT,
    SET_SHOW_NOTIFICATIONS,
    SET_SHOW_USER_INFO,
    SET_SELECTED_CUSTOMISATION_TAB,
} from "../actionTypes";

const generalReducer = (state = initialState.general, action) => {
    switch (action.type) {
        case SET_SHOW_USER_INFO:
            return {
                ...state,
                showUserInfo: action.payload,
            };
        case SET_SHOW_NOTIFICATIONS:
            return {
                ...state,
                showNotifications: action.payload,
            };
        case SET_SHOW_CHAT_FIELD:
            return {
                ...state,
                showChatField: action.payload,
            };
        case SET_IS_EDITING:
            return {
                ...state,
                isEditing: action.payload,
            };
        case SET_SELECTED_CUSTOMISATION_TAB:
            return {
                ...state,
                selectedCustomisationTab: action.payload,
            };
        case SET_SHOW_FEEDBACK_FORM:
            return {
                ...state,
                showFeedbackForm: action.payload,
            };
        case SET_SHOW_FEEDBACK_PROMPT:
            return {
                ...state,
                showFeedbackPrompt: action.payload,
            };
        case SET_CHECK_FEEDBACK_API:
            return {
                ...state,
                checkFeedbackAPI: action.payload,
            };

        default:
            return state;
    }
};

export default generalReducer;
