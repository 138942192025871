import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";

const TileFilterButton = ({ handleClick, viewClosed }) => {
    return (
        <Button
            onClick={handleClick}
            sx={{
                color: "text.emphasis",
                textTransform: "none",
                height: "25px",
                width: "120px",
                display: "flex",
                borderRadius: "1px",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid",
            }}
        >
            <Typography>
                {viewClosed ? "Viewing All" : "Viewing Open"}
            </Typography>
        </Button>
    );
};

TileFilterButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
    viewClosed: PropTypes.bool.isRequired,
};

export default TileFilterButton;
