import { getUserInfo } from "../../../utils/authUtils";
import useNotifications from "./useNotifications";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import usePreferences from "./usePreferences";
import useAccessibility from "./useAccessibility";
import useIBuyApprovals from "./useIBuyApprovals";
import useServiceNowApprovals from "./useServiceNowApprovals";
import useUserProfile from "./useUserProfile";
import useIBuyRequisitions from "./useIBuyRequisitions";
import useIBuyCarts from "./useIBuyCarts";
import { useMsal } from "@azure/msal-react";

const usePreloading = () => {
    const [directDataHasLoaded, setDirectDataHasLoaded] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasStartedLoading, setHasStartedLoading] = useState(false);

    const dispatch = useDispatch();
    const { preferencesAreLoading } = usePreferences();
    const { userProfileIsLoading } = useUserProfile();
    const { accessibilityPreferencesAreLoading } = useAccessibility();
    useIBuyApprovals();
    const { notificationsAreLoading } = useNotifications();
    const { instance: msalInstance } = useMsal();
    useServiceNowApprovals();
    useIBuyRequisitions();
    useIBuyCarts();

    const apiDataIsLoading = useMemo(
        () =>
            notificationsAreLoading ||
            preferencesAreLoading ||
            userProfileIsLoading ||
            accessibilityPreferencesAreLoading,
        [
            notificationsAreLoading,
            preferencesAreLoading,
            userProfileIsLoading,
            accessibilityPreferencesAreLoading,
        ]
    );

    useEffect(() => {
        const preloadData = async () => {
            if (
                !hasLoaded &&
                !preferencesAreLoading &&
                !userProfileIsLoading &&
                !directDataHasLoaded &&
                !hasStartedLoading
            ) {
                setHasStartedLoading(true);
                await getUserInfo(dispatch, msalInstance);
                setDirectDataHasLoaded(true);
            }
        };

        preloadData();
    }, [
        hasLoaded,
        preferencesAreLoading,
        userProfileIsLoading,
        hasStartedLoading,
        directDataHasLoaded,
    ]);

    useEffect(() => {
        if (!hasLoaded && directDataHasLoaded && !apiDataIsLoading) {
            setHasLoaded(true);
        }
    }, [hasLoaded, hasStartedLoading, apiDataIsLoading]);

    return { hasLoaded };
};

export default usePreloading;
