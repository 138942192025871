import { jwtDecode } from "jwt-decode";
import { setAuth } from "../redux/actions/authActions";
import { tokenRequest } from "../services/auth/authConfig";
import { triggerSnackbar } from "./alertsUtils";
import { tokenManager } from "../services/apiUtils/tokenManager";

export const getUserInfo = async (dispatch, msalInstance) => {
    /*
    Arguments
    ---------
    dispatch :  func
    */
    let token = "";
    try {
        if (process.env.REACT_APP_LOCAL_DEV !== "true") {
            const tokenResponse =
                await msalInstance.acquireTokenSilent(tokenRequest);
            token = tokenResponse.accessToken;
        } else {
            token = await tokenManager.getLocalAPIToken(tokenRequest);
        }
        const userClaims = jwtDecode(token);
        const email = userClaims.email;
        const userName = getDisplayName(userClaims.name);
        const samAccountName = userClaims.samAccountName;
        // If local storage has an OU, set as active OU
        dispatch(
            setAuth({
                userName,
                email,
                samAccountName,
                id_token: null,
            })
        );
    } catch (error) {
        const msg =
            "Unable to read Azure AD token - please refresh your browser";
        console.error(msg + " " + error?.message?.toString());
        triggerSnackbar("warning", msg, null, dispatch);
    }
};

export const getInitialsFromEmail = (email) => {
    /*
    Arguments
    ---------
    email : string
    Returns
    -------
    string
    */
    if (email) {
        const initialOne = email[0];
        const userName = email.split("@")[0];
        const surname = userName.split(".")[1];
        const initialTwo = surname ? surname[0] : "";
        return (initialOne + initialTwo).toUpperCase();
    } else {
        return null;
    }
};

export const stripContractor = (userName) => {
    /*
    Arguments
    ---------
    userName : string
    Returns
    -------
    string
    */
    return userName ? userName.replace("(Contractor)", "").trim() : null;
};

export const getDisplayName = (userName) => {
    const strippedUserName = userName
        ? userName.replace("(Contractor)", "").trim()
        : null;
    if (strippedUserName && strippedUserName.indexOf(",") !== -1) {
        const surname = strippedUserName.split(",")[0];
        const firstNames = strippedUserName.split(",")[1].trim();
        return `${firstNames} ${surname}`;
    }
    return strippedUserName;
};

export const logOutUser = async (accounts, msalInstance) => {
    /*
    Arguments
    ---------
    accounts : obj (returned from the useMsal instance: `const { accounts } = useMsal();`)
    */
    const logoutRequest = {
        account: msalInstance.getAccountByHomeId(accounts[0].homeAccountId),
        postLogoutRedirectUri: msalInstance.getConfiguration().auth.redirectUri,
    };
    await msalInstance.logoutRedirect(logoutRequest);
};
