import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { styledDashboardTileActionButtonSX } from "../../styled/styledSXProperties/styledDashboardTileCardSX";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { SwapVert } from "@mui/icons-material";
import { Close, PlusCircle } from "@sse-digital-services/design-system";
import PropTypes from "prop-types";
import { alpha, Fade, useTheme } from "@mui/material";
import useAccessibility from "../../services/apiUtils/hooks/useAccessibility";

const updateTransform = (transform, active, id) => {
    if (transform) {
        transform.scaleX = 1;
        transform.scaleY = 1;
        if (
            active?.id &&
            active.id !== id &&
            active.id !== "visibleTilesPlaceholder" &&
            active.id !== "hiddenTilesPlaceholder"
        ) {
            transform.x += 16;
        }
    }
};

const DraggableTileHandle = ({
    id,
    name,
    onAdd,
    onRemove,
    addable,
    hidden,
}) => {
    const theme = useTheme();
    const { accessibilitySettings } = useAccessibility();
    const inHCMode = accessibilitySettings?.highContrastMode;
    const animateLayoutChanges = (args) =>
        defaultAnimateLayoutChanges({ ...args, wasDragging: true });
    const { active, attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id,
            animateLayoutChanges,
            attributes: { role: "region" },
        });

    updateTransform(transform, active, id);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "manipulation",
        zIndex: active?.id && active.id !== id ? 0 : 1,
    };

    const handleKeyboardAdd = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onAdd(event);
        }
    };

    const handleKeyboardRemove = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onRemove(event);
        }
    };

    return (
        <Fade
            in={
                !addable ||
                !active?.id ||
                active.id === id ||
                active.id === "visibleTilesPlaceholder" ||
                active.id === "hiddenTilesPlaceholder"
            }
        >
            <div
                id={id}
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
                tabIndex={!hidden ? 0 : -1}
            >
                <Box
                    sx={{
                        display: "flex",
                        minHeight: "30px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: !hidden ? "grab" : "default",
                        ":active": {
                            cursor: !hidden ? "grabbing" : "default",
                        },
                        border: "1px solid",
                        borderColor: inHCMode
                            ? theme.palette.text.main
                            : alpha(theme.palette.text.main, 0.12),
                        padding: "20px",
                        opacity: hidden ? 0 : 1,
                        backgroundColor: "background.paper",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <SwapVert
                            sx={{
                                color: "secondary.contrastText",
                                margin: "0 16px 0 8px",
                                height: "22px",
                                width: "22px",
                            }}
                        />
                        <Typography
                            variant="h6"
                            component="p"
                            sx={{ paddingBottom: "-4px" }}
                        >
                            {name}
                        </Typography>
                    </Box>

                    {!hidden &&
                        !(
                            active &&
                            active.id !== "visibleTilesPlaceholder" &&
                            active.id !== "hiddenTilesPlaceholder"
                        ) &&
                        (addable ? (
                            <IconButton
                                aria-label="Add"
                                size="small"
                                onClick={onAdd}
                                onKeyDown={handleKeyboardAdd}
                                sx={{ padding: 0 }}
                            >
                                <PlusCircle
                                    sx={{
                                        ...styledDashboardTileActionButtonSX,
                                        backgroundColor: "background.paper",
                                    }}
                                    fontSize="small"
                                />
                            </IconButton>
                        ) : (
                            <IconButton
                                aria-label="Remove"
                                size="small"
                                onClick={onRemove}
                                onKeyDown={handleKeyboardRemove}
                                sx={{
                                    padding: 0,
                                    ":active": {
                                        cursor: !hidden
                                            ? "grabbing"
                                            : "default",
                                    },
                                }}
                            >
                                <Close
                                    sx={{
                                        ...styledDashboardTileActionButtonSX,
                                        backgroundColor: "background.paper",
                                    }}
                                    fontSize="small"
                                />
                            </IconButton>
                        ))}
                </Box>
            </div>
        </Fade>
    );
};

DraggableTileHandle.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    addable: PropTypes.bool,
    hidden: PropTypes.bool,
};

export default DraggableTileHandle;
