const Buffer = require("buffer/").Buffer;

const imageMapper = {
    mapAPIImageToImageString: (apiImage) => {
        const base64String = Buffer.from(apiImage, "binary").toString("base64");
        return `data:image/png;base64,${base64String}`;
    },
};

export default imageMapper;
