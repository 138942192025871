import React from "react";
import PropTypes from "prop-types";
import { PROCURETOPAY } from "../../../constants/serviceNames";
import BaseRow from "../../tables/baseRow";
import SummaryRow from "../../tables/summaryRow";
import { formatDateTime } from "@sse-digital-services/design-system";

const ProcureToPayIncidentsRow = ({ item, isEditing }) => {
    let incidentDetails = [
        {
            label: "Description",
            boldLabel: true,
            value: item.description,
        },
        {
            label: "Updated on",
            boldLabel: true,
            value: formatDateTime(item.updatedDate),
        },
        {
            label: "Created on",
            boldLabel: true,
            value: formatDateTime(item.createdDate),
        },
    ];

    return (
        <BaseRow itemDetails={incidentDetails}>
            <SummaryRow
                item={item}
                service={PROCURETOPAY}
                isEditing={isEditing}
            />
        </BaseRow>
    );
};
ProcureToPayIncidentsRow.propTypes = {
    item: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

export default ProcureToPayIncidentsRow;
