import PropTypes from "prop-types";
import * as React from "react";
import QuickActionTile from "../templates/quickActionTile";
import { NUDGE_URL } from "../../../constants/externalUrls";
import useUserProfile from "../../../services/apiUtils/hooks/useUserProfile";
import { jwtDecode } from "jwt-decode";
import SingleLinkTile from "../templates/singleLinkTile";
import { quickActionTilesMap } from "../tilesMap";

const NudgeTile = ({ id }) => {
    const { userProfileToken } = useUserProfile();

    const decodedToken = userProfileToken && jwtDecode(userProfileToken);
    const alternativeText =
        decodedToken?.PERSON_TYPE === "CWK"
            ? "Nudge is not available to contractors"
            : null;
    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={NUDGE_URL}
                linkText={quickActionTilesMap[id].description}
                title={"Nudge for SSE"}
                trackingId={"Nudge link"}
                alternativeText={alternativeText}
            />
        </QuickActionTile>
    );
};

NudgeTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default NudgeTile;
