import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { TwoColDetailsGrid } from "@sse-digital-services/design-system";

const IBuyCartDetails = ({ cartsData, activeCart }) => {
    const cartDetails = [
        {
            label: "Total items",
            value: cartsData[activeCart].totalItems,
            variant: "body1",
        },
        {
            label: "Supplier",
            value: cartsData[activeCart].supplierName,
            variant: "body1",
        },
        {
            label: "Operating Unit",
            value: cartsData[activeCart].operatingUnit,
            variant: "body1",
        },
        {
            label: "Total",
            boldLabel: true,
            value: cartsData[activeCart].totalCost,
            boldValue: true,
            variant: "body1",
            component: "p",
            rowSX: { margin: "12px 0 0 0" },
        },
    ];

    return (
        <Box sx={{ display: "flex", justifyContent: "left", ml: "10px" }}>
            {cartsData[activeCart] && (
                <TwoColDetailsGrid details={cartDetails} />
            )}
        </Box>
    );
};

IBuyCartDetails.propTypes = {
    cartsData: PropTypes.object.isRequired,
    activeCart: PropTypes.string.isRequired,
};

export default IBuyCartDetails;
