import { sharepointProcessorContentReplacements } from "../../../constants/generalConstants";

const documentProcessorMapper = {
    mapUrlToAPIUrl: (url) => {
        let cleanUrl = url.replace("https://", "");
        Object.keys(sharepointProcessorContentReplacements).forEach((key) => {
            cleanUrl = cleanUrl.replaceAll(
                sharepointProcessorContentReplacements[key],
                key
            );
        });
        return cleanUrl;
    },
};

export default documentProcessorMapper;
