import React from "react";
import { Box, FormControlLabel } from "@mui/material";
import { ToggleSwitch } from "@sse-digital-services/design-system";
import PropTypes from "prop-types";

const SettingsToggle = ({ name, trackingId, label, checked, handleChange }) => (
    <FormControlLabel
        sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginLeft: 0,
            marginY: "5px",
        }}
        key={name}
        control={
            <ToggleSwitch
                checked={checked}
                inputProps={{
                    "data-track-id": trackingId,
                    "aria-label": trackingId,
                }}
                onChange={handleChange}
                name={name}
            />
        }
        label={
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                }}
            >
                {label}
            </Box>
        }
        labelPlacement="start"
        componentsProps={{
            typography: {
                variant: "body1",
                component: "span",
            },
        }}
    />
);

SettingsToggle.propTypes = {
    name: PropTypes.string.isRequired,
    trackingId: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    checked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default SettingsToggle;
