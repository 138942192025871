import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import { styledDashboardTileSX } from "../../styled/styledSXProperties/styledDashboardTileCardSX";

const HomepageTileSkeleton = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
                <Card
                    sx={{
                        ...styledDashboardTileSX,
                        height: "200px",
                        padding: "10px",
                    }}
                >
                    <Skeleton variant="text" height="30%" width="100%" />
                    <Skeleton variant="rectangular" height="70%" width="100%" />
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Card
                    sx={{
                        ...styledDashboardTileSX,
                        height: "200px",
                        padding: "10px",
                    }}
                >
                    <Skeleton variant="text" height="30%" width="100%" />
                    <Skeleton variant="rectangular" height="70%" width="100%" />
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Card
                    sx={{
                        ...styledDashboardTileSX,
                        height: "200px",
                        padding: "10px",
                    }}
                >
                    <Skeleton variant="text" height="30%" width="100%" />
                    <Skeleton variant="rectangular" height="70%" width="100%" />
                </Card>
            </Grid>
        </Grid>
    );
};

export default HomepageTileSkeleton;
