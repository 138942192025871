import PropTypes from "prop-types";
import React from "react";
import IBuySearch from "./iBuySearch";
import { Typography, useTheme } from "@mui/material";
import useDashboardTiles from "../useDashboardTiles";
import DashboardTile from "../templates/dashboardTile";

const IBuySearchTile = ({ id, transform, addRectGetter }) => {
    const { isEditing } = useDashboardTiles();
    const theme = useTheme();

    return (
        <DashboardTile
            id={id}
            tileColor={theme.palette.background.paper}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <Typography
                sx={{ margin: "0 0 20px 0" }}
                color="text.primary"
                variant="h2"
                component="p"
                textAlign="left"
                alignItems="center"
            >
                iBuy Product Search
            </Typography>
            <IBuySearch isEditing={isEditing} />
        </DashboardTile>
    );
};

IBuySearchTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default IBuySearchTile;
