import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import {
    contentBoxL3,
    styledDividerSX,
    PageLayout,
} from "@sse-digital-services/design-system";
import Divider from "@mui/material/Divider";
import useTasks from "../../tasks/useTasks";
import TasksFilterPanel from "./tasksFilterPanel";
import TasksCardSkeleton from "../../skeletons/tasksCardSkeleton";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import useNavigationItems from "../../navigation/useNavigationItems";
import * as routes from "../../../constants/routes";

const Tasks = () => {
    const skeletons = new Array(6)
        .fill({})
        .map((_, index) => (
            <TasksCardSkeleton key={`task-card-skeleton-${index}`} />
        ));
    const [tasks, setTasks] = useState([]);
    const [currentKey, setCurrentKey] = useState("");
    const [taskCounts, setTaskCounts] = useState({});
    const { taskUnion, tasksAreLoading, taskCards } = useTasks();
    const [sortByAscending, setSortByAscending] = React.useState(false);
    const [filterOptions, setFilterOptions] = React.useState({
        service: [],
        requester: [],
    });
    const [currentFilters, setCurrentFilters] = React.useState({
        service: [],
        requester: [],
    });
    const location = useLocation();
    const { navItems } = useNavigationItems();

    const multiFilter = (tasks, filters) => {
        let filterKeys = Object.keys(filters);
        return tasks.filter((eachObj) => {
            return filterKeys.every((filterKey) => {
                if (!filters[filterKey].length) return true;
                return filters[filterKey].includes(eachObj[filterKey]);
            });
        });
    };

    const countUniqueFieldValues = (field, data) => {
        let valueCount = {};
        for (const item of data) {
            valueCount[item[field]] = valueCount[item[field]]
                ? valueCount[item[field]] + 1
                : 1;
        }
        return valueCount;
    };
    const countTasks = (currentTasks, filterKey) => {
        let filtersCopy = { ...currentFilters };
        if (currentTasks && !filterKey) {
            // No filters considered, count all tasks
            Object.keys(filtersCopy).forEach((k) => {
                taskCounts[k] = countUniqueFieldValues(k, currentTasks);
            });
        } else if (filterKey) {
            // Count tasks with respect to the filter currently being edited
            Object.keys(filtersCopy).forEach((k) => {
                if (k !== filterKey) {
                    filtersCopy[k] = [];
                }
            });
            let recount = multiFilter(taskUnion, filtersCopy);
            Object.keys(taskCounts).forEach((k) => {
                if (k !== filterKey) {
                    taskCounts[k] = countUniqueFieldValues(k, recount);
                }
            });
        }
        setTaskCounts({ ...taskCounts });
    };

    useEffect(() => {
        if (!tasksAreLoading) {
            setTasks(taskUnion);
            countTasks(taskUnion);
            let filters = {
                service: [
                    ...new Set(taskUnion.map((item) => item.service)),
                ].sort(),
                requester: [
                    ...new Set(taskUnion.map((item) => item.requester)),
                ].sort(),
            };
            setFilterOptions(filters);
        }
    }, [tasksAreLoading, taskUnion]);

    useEffect(() => {
        if (tasks && !tasksAreLoading) {
            let sortedTasks = multiFilter(taskUnion, currentFilters);
            if (sortByAscending) {
                sortedTasks = sortedTasks.sort(
                    (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
                );
            } else {
                sortedTasks = sortedTasks.sort(
                    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
                );
            }
            setTasks(sortedTasks);
            countTasks(sortedTasks, currentKey);
        }
    }, [currentFilters, sortByAscending, taskUnion]);

    return (
        <PageLayout
            homeName="Dashboard"
            homeRoute={routes.HOMEPAGE}
            currentPath={location.pathname}
            navItems={navItems}
        >
            <Box sx={contentBoxL3}>
                <TasksFilterPanel
                    setSortByAscending={setSortByAscending}
                    sortByAscending={sortByAscending}
                    taskCounts={taskCounts}
                    filterOptions={filterOptions}
                    currentFilters={currentFilters}
                    setCurrentFilters={setCurrentFilters}
                    setCurrentKey={setCurrentKey}
                />
                <Stack spacing={1} mt={"20px"}>
                    {tasksAreLoading
                        ? skeletons.map((task) => {
                              return task;
                          })
                        : taskCards(tasks, false).map((task) => {
                              return task;
                          })}
                </Stack>
                <Divider sx={{ ...styledDividerSX, margin: "24px 0px" }} />
            </Box>
        </PageLayout>
    );
};

export default Tasks;
