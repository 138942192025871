import FormControl from "@mui/material/FormControl";
import { Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MultiSelectFilter from "../../tasks/multiSelectFilter";
import { serviceNames } from "../../../constants/serviceNames";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";

const TasksFilterPanel = ({
    setSortByAscending,
    sortByAscending = false,
    taskCounts = {},
    filterOptions,
    currentFilters,
    setCurrentFilters,
    setCurrentKey,
}) => {
    const { borderSX } = useHighContrastBorderSX();
    const theme = useTheme();

    const handleFilterChange = (event, filterKey) => {
        setCurrentKey(filterKey);
        const {
            target: { value },
        } = event;
        if (value[value.length - 1] === "clear") {
            setCurrentFilters({
                ...currentFilters,
                [filterKey]: [],
            });
        } else {
            setCurrentFilters({
                ...currentFilters,
                [filterKey]:
                    typeof value === "string" ? value.split(",") : value,
            });
        }
    };
    const clearFilters = () => {
        let filters = currentFilters;
        Object.keys(filters).forEach((i) => (filters[i] = []));
        setCurrentFilters({ ...filters });
        setCurrentKey("");
    };
    const handleSortChange = () => {
        setSortByAscending(!sortByAscending);
    };
    const buttonSx = {
        ...borderSX,
        height: "38px",
        minWidth: "100%",
        align: "left",
        borderRadius: "1px",
        color: "icon.secondary",
        background: theme.palette.background.paper,
    };

    return (
        <Grid container spacing={2}>
            <Grid item lg={3} md={4} xs={12}>
                <MultiSelectFilter
                    filterKey={"service"}
                    onChange={(e) => handleFilterChange(e, "service")}
                    filterOptions={filterOptions}
                    currentFilters={currentFilters}
                    displayNames={serviceNames}
                    itemCounts={taskCounts.service}
                    buttonSx={buttonSx}
                    icon={<GridViewOutlinedIcon />}
                />
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
                <MultiSelectFilter
                    filterKey={"requester"}
                    onChange={(e) => handleFilterChange(e, "requester")}
                    filterOptions={filterOptions}
                    currentFilters={currentFilters}
                    itemCounts={taskCounts.requester}
                    buttonSx={buttonSx}
                    icon={<PeopleAltOutlinedIcon />}
                />
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
                <FormControl size="small" variant={"filled"} fullWidth>
                    <Button
                        sx={{
                            ...buttonSx,
                            textTransform: "capitalize",
                            borderRadius: "1px",
                            justifyContent: "flex-start",
                            "& .MuiButton-startIcon": {
                                "& > *:first-of-type": {
                                    fontSize: 24,
                                },
                            },
                        }}
                        onClick={handleSortChange}
                        startIcon={
                            sortByAscending ? (
                                <OutboundOutlinedIcon
                                    sx={{
                                        color: "icon.secondary",
                                    }}
                                />
                            ) : (
                                <OutboundOutlinedIcon
                                    sx={{
                                        color: "icon.secondary",
                                        transform: "rotate(90deg)",
                                    }}
                                />
                            )
                        }
                    >
                        <Typography variant="body3" component="p">
                            {`Date created: ${
                                sortByAscending ? "Ascending" : "Descending"
                            }`}
                        </Typography>
                    </Button>
                </FormControl>
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
                <FormControl size="small" variant={"filled"} fullWidth>
                    <Button
                        sx={{
                            ...buttonSx,
                            textTransform: "capitalize",
                            borderRadius: "1px",
                            justifyContent: "flex-start",
                            "& .MuiButton-startIcon": {
                                "& > *:first-of-type": {
                                    fontSize: 24,
                                },
                            },
                        }}
                        onClick={clearFilters}
                        startIcon={
                            <CancelOutlinedIcon
                                sx={{ color: "icon.secondary" }}
                            />
                        }
                    >
                        <Typography variant="body3" component="p">
                            {"Clear all filters"}
                        </Typography>
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    );
};
TasksFilterPanel.propTypes = {
    setSortByAscending: PropTypes.func.isRequired,
    sortByAscending: PropTypes.bool,
    taskCounts: PropTypes.object,
    filterOptions: PropTypes.object.isRequired,
    currentFilters: PropTypes.object.isRequired,
    setCurrentFilters: PropTypes.func.isRequired,
    setCurrentKey: PropTypes.func.isRequired,
};

export default React.memo(TasksFilterPanel);
