import * as React from "react";
import { useEffect, useRef } from "react";
import useNotifications from "../../../services/apiUtils/hooks/useNotifications";
import Notifications from "./notifications";
import { useDispatch, useSelector } from "react-redux";
import { SwipeableDrawerWithHeader } from "@sse-digital-services/design-system";
import { setShowNotifications } from "../../../redux/actions/generalActions";

const NotificationsDrawer = () => {
    const dispatch = useDispatch();

    const showNotifications = useSelector(
        (state) => state.general.showNotifications
    );
    const showUserInfo = useSelector((state) => state.general.showUserInfo);
    const isMountingRef = useRef(false);

    const { updateNotificationsStatus } = useNotifications();

    const handleClose = async () => {
        dispatch(setShowNotifications(false));
    };

    // Update notification status to 'read' if the state of drawer changes from open to closed but not on initial
    // render.onClose does not trigger on mobile if the user navigates away from it without swiping it away.
    useEffect(() => {
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        const updateStatus = async () => {
            if (!isMountingRef.current && !showNotifications) {
                await updateNotificationsStatus();
            } else {
                isMountingRef.current = false;
            }
        };
        updateStatus();
    }, [showNotifications]);

    const drawerTitle = "Your Notifications";

    return (
        <SwipeableDrawerWithHeader
            modalProps={{ keepMounted: false }}
            open={showNotifications && !showUserInfo}
            onClose={handleClose}
            title={drawerTitle}
            onOpen={() => {}}
            paperSx={{
                backgroundColor: "background.main",
            }}
            scrollUnderNavBar="true"
        >
            <Notifications scrollBoxId={`drawer-content-${drawerTitle}`} />
        </SwipeableDrawerWithHeader>
    );
};

export default NotificationsDrawer;
