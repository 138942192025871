import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import store from "./redux/store";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { SWRConfig } from "swr";
import { defaultSWRConfig } from "./services/apiUtils/config/swrConfig";
import PwaWrapper from "./components/PWA/pwaWrapper";
import {
    AppHeightWrapper,
    AuthWrapper,
    GenericErrorPage,
    LoadingScreen,
} from "@sse-digital-services/design-system";
import { getMsalInstance } from "./services/auth/authProvider";
import { loginRequest } from "./services/auth/authConfig";
import { APP_NAME } from "./constants/generalConstants";

const MsalLoadingScreen = () => <LoadingScreen appName={APP_NAME} />;
MsalLoadingScreen.displayName = "LoadingScreen";
const MsalErrorPage = () => <GenericErrorPage appName={APP_NAME} />;
MsalErrorPage.displayName = "GenericErrorPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <PwaWrapper>
            <AppHeightWrapper>
                <AuthWrapper
                    msalInstance={getMsalInstance()}
                    authRequest={loginRequest}
                    loadingPage={MsalLoadingScreen}
                    errorPage={MsalErrorPage}
                >
                    <React.StrictMode>
                        <SWRConfig value={{ ...defaultSWRConfig }}>
                            <App />
                        </SWRConfig>
                    </React.StrictMode>
                </AuthWrapper>
            </AppHeightWrapper>
        </PwaWrapper>
    </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
