import {
    Box,
    Dialog,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import InfoDialog from "./InfoDialog";
import { GoToServiceButton } from "components/buttons/goToServiceButton";
import { SERVICENOW } from "constants/serviceNames";
import useServiceNowRequestItems from "services/apiUtils/hooks/useServiceNowRequestItems";
import { SERVICENOW_REQUEST_ITEM_LENGTH_CUTOFF } from "constants/generalConstants";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";

const LoadingSkeleton = () => (
    <>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
    </>
);

const ServiceNowMoreInfoModal = ({ request, closeDialog, isEditing }) => {
    const { items, errorMessage, requestItemsAreLoading } =
        useServiceNowRequestItems(request.externalId);
    const borderSX = useHighContrastBorderSX();
    return (
        <Dialog
            open={true}
            onClose={closeDialog}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: borderSX,
            }}
        >
            <InfoDialog
                heading={`Items For Request ${request.id}`}
                closeDialog={closeDialog}
                headerButton={
                    <GoToServiceButton
                        url={`https://${request.url}`}
                        disabled={isEditing}
                        service={SERVICENOW}
                    />
                }
            >
                <Box sx={{ width: "100%", borderTop: "1px solid" }}>
                    {!items || items.length === 0 ? (
                        requestItemsAreLoading ? (
                            <LoadingSkeleton />
                        ) : (
                            <Typography sx={{ padding: "15px" }}>
                                {errorMessage
                                    ? "An error has occurred when fetching request items. Please check ServiceNow for more details."
                                    : "No items linked with request found. Please check ServiceNow for more details."}
                            </Typography>
                        )
                    ) : (
                        <Table
                            sx={{
                                width: "100%",
                            }}
                        >
                            <TableBody>
                                {items.map((item) => (
                                    <TableRow key={item}>
                                        <TableCell
                                            sx={{ borderBottom: "1px solid" }}
                                        >
                                            {handleServiceNowMaxItemLengthCutoff(
                                                item,
                                                request,
                                                isEditing
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Box>
            </InfoDialog>
        </Dialog>
    );
};

ServiceNowMoreInfoModal.propTypes = {
    request: PropTypes.object.isRequired,
    closeDialog: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

const handleServiceNowMaxItemLengthCutoff = (item, request, isEditing) => {
    if (item.length === SERVICENOW_REQUEST_ITEM_LENGTH_CUTOFF) {
        return (
            <>
                {item}...
                <Typography
                    sx={{
                        display: "flex",
                        flex: "row",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    View full item in ITSC{" "}
                    <GoToServiceButton
                        url={`https://${request.url}`}
                        disabled={isEditing}
                        service={SERVICENOW}
                    />
                </Typography>
            </>
        );
    }
    return item;
};

export default ServiceNowMoreInfoModal;
