import Backdrop from "@mui/material/Backdrop";
import { alpha, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Build, Error } from "@mui/icons-material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const DisabledOverlay = ({
    maintenanceMode,
    serviceNames,
    errorMessage,
    isEditing,
}) => {
    const message = maintenanceMode
        ? `${serviceNames?.join(", ")} ${
              serviceNames?.length > 1 ? "are" : "is"
          } down for maintenance`
        : errorMessage;
    const theme = useTheme();
    return (
        <Backdrop
            sx={{
                position: "absolute",
                backgroundColor: alpha(
                    theme.palette.common.slateGrey20,
                    isEditing ? 0.85 : 0.95
                ),
                color: alpha(theme.palette.common.black, isEditing ? 0.75 : 1),
                zIndex: "contentOverlay",
            }}
            open={!!(maintenanceMode || errorMessage)}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    textAlign="center"
                    variant="h5"
                    component="p"
                    m="4px"
                >
                    {message}
                </Typography>
                {maintenanceMode ? (
                    <Build fontSize="medium" />
                ) : (
                    <Error fontSize="medium" />
                )}
            </Box>
        </Backdrop>
    );
};

DisabledOverlay.propTypes = {
    maintenanceMode: PropTypes.bool,
    serviceNames: PropTypes.array,
    errorMessage: PropTypes.string,
    isEditing: PropTypes.bool,
};

export default DisabledOverlay;
