import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import {
    DARK_THEME,
    HIGH_CONTRAST_THEME,
} from "@sse-digital-services/design-system";
import useAccessibility from "../../../services/apiUtils/hooks/useAccessibility";
import HighContrastSection from "./highContrastSection";
import SettingsToggle from "./settingsToggle";

const AccessibilitySection = () => {
    const theme = useTheme();
    const { accessibilitySettings, updateAccessibilitySettings } =
        useAccessibility();
    const toggleTheme = async () => {
        if (accessibilitySettings) {
            const darkMode = accessibilitySettings.darkMode;
            await updateAccessibilitySettings({ darkMode: !darkMode });
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                }}
            >
                <Typography
                    variant="h6"
                    component="p"
                    textAlign="left"
                    mb={1}
                    fontWeight="bold"
                >
                    Accessibility
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                >
                    {theme.name !== HIGH_CONTRAST_THEME && (
                        <SettingsToggle
                            name={"theme toggle"}
                            trackingId={`toggle dark mode ${
                                theme.name === DARK_THEME ? "off" : "on"
                            }`}
                            checked={theme.name === DARK_THEME}
                            handleChange={toggleTheme}
                            label={
                                <>
                                    {theme.name === DARK_THEME ? (
                                        <LightModeOutlined
                                            aria-label="themeModeLogo"
                                            sx={{
                                                color: "icon.secondary",
                                                margin: "0 8px 0 0",
                                            }}
                                        />
                                    ) : (
                                        <DarkModeOutlined
                                            aria-label="themeModeLogo"
                                            sx={{
                                                color: "icon.secondary",
                                                margin: "0 8px 0 0",
                                            }}
                                        />
                                    )}
                                    <Typography variant="body1" component="p">
                                        {theme.name === DARK_THEME
                                            ? "Light mode"
                                            : "Dark mode"}
                                    </Typography>
                                </>
                            }
                        />
                    )}
                </Box>
                <HighContrastSection />
            </Box>
        </>
    );
};

export default AccessibilitySection;
