import {
    formatDate,
    formatNumberToCurrency,
} from "@sse-digital-services/design-system";
import { FAVOURITES_CART_NAME } from "../../../constants/generalConstants";
import { PROCUREMENT_APPROVALS } from "../../../constants/taskNames";
import { PROCUREMENT } from "../../../constants/serviceNames";

const mapAPIRequisitionToRequisition = (apiRequisition) => {
    let currency = apiRequisition.ouBaseCurrency ?? "GBP";
    const totalPrice =
        apiRequisition.totalPrice ?? apiRequisition.totalPriceBaseCurrency;
    return {
        id: apiRequisition.id,
        requester: apiRequisition.submittedByName,
        createdDate: formatDate(apiRequisition.submittedDate),
        supplierName: apiRequisition.supplierName,
        totalCost: formatNumberToCurrency(totalPrice, currency),
        description: apiRequisition.requisitionHeaderForm.HEADER_DESCRIPTION,
        operatingUnit: apiRequisition.requisitionHeaderForm.ORG_NAME,
        displayState: apiRequisition.status,
    };
};

const iBuyMapper = {
    mapAPICartsToCarts: (apiCarts) => {
        const iBuyCarts = {};
        apiCarts
            .filter((apiCart) => apiCart.name !== FAVOURITES_CART_NAME)
            .forEach((apiCart) => {
                const items = apiCart.items;
                const totalCost = items.reduce(
                    (total, item) => total + item.UNIT_PRICE * item.QUANTITY,
                    0
                );
                const totalItems = items.reduce(
                    (total, item) => total + item.QUANTITY,
                    0
                );
                const cartEmpty = items.length === 0;
                let currency = "GBP";
                if (!cartEmpty) {
                    currency = items[0].CURRENCY_CODE ?? "GBP";
                }
                iBuyCarts[apiCart.id] = {
                    name: apiCart.name,
                    totalItems: cartEmpty ? "Cart Empty" : totalItems,
                    totalCost: cartEmpty
                        ? "Cart Empty"
                        : `${formatNumberToCurrency(totalCost, currency)}`,
                    supplierName: cartEmpty
                        ? "Cart Empty"
                        : items[0].SUPPLIER_NAME,
                    operatingUnit: cartEmpty
                        ? "Cart Empty"
                        : items[0].IBUY_CART_ITEM_OU,
                };
            });
        return iBuyCarts;
    },
    mapAPIApprovalsToApprovals: (apiApprovals) => {
        if (apiApprovals.data) {
            return apiApprovals.data.map((apiApproval) => ({
                id: apiApproval.id,
                requester: apiApproval.submittedByName,
                createdDate: apiApproval.submittedDate,
                amount:
                    apiApproval.totalPrice ??
                    apiApproval.totalPriceBaseCurrency,
                supplier: apiApproval.supplierName,
                description:
                    apiApproval.requisitionHeaderForm.HEADER_DESCRIPTION,
                currency: apiApproval.ouBaseCurrency ?? "GBP",
                taskType: PROCUREMENT_APPROVALS,
                service: PROCUREMENT,
            }));
        }
        return [];
    },
    mapAPIRequisitionToImageUrl: (apiRequisition) => {
        if (apiRequisition.requisitionLines) {
            return apiRequisition.requisitionLines.reduce(
                (imageUrl, requisitionLine) =>
                    imageUrl || requisitionLine.ITEM_IMAGE_URL,
                ""
            );
        }
        return "";
    },
    mapAPIRequisitionsToRequisitions: (apiRequisitions) => {
        if (apiRequisitions.data) {
            return apiRequisitions.data.map((apiRequisition) =>
                mapAPIRequisitionToRequisition(apiRequisition)
            );
        }
        return [];
    },
};

export default iBuyMapper;
