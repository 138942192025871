import * as React from "react";
import * as PropTypes from "prop-types";
import { formatDate } from "@sse-digital-services/design-system";
import TaskActionButton from "../../buttons/taskActionButton";
import { CardActions } from "@mui/material";
import useProcureToPayApprovals from "../../../services/apiUtils/hooks/useProcureToPayApprovals";
import BaseTaskCard from "../baseTaskCard";

const ProcureToPayTaskCard = ({ id, isDashboard }) => {
    const { ptpApprovalById } = useProcureToPayApprovals();
    const { updatedDate, url, requestedFor, createdDate } = ptpApprovalById(id);

    const rows = [
        { field: "Incident", value: id },
        { field: "Updated", value: formatDate(updatedDate) },
    ];
    !isDashboard &&
        rows.push(
            { field: "Requested for", value: requestedFor },
            { field: "Submitted", value: formatDate(createdDate) }
        );

    return (
        <BaseTaskCard
            fields={rows}
            title={`Procure To Pay Incident`}
            isDashboard={isDashboard}
        >
            <CardActions sx={{ px: 0 }}>
                <TaskActionButton
                    mode="neutral"
                    action="Respond in Procure To Pay"
                    url={`https://${url}`}
                />
            </CardActions>
        </BaseTaskCard>
    );
};

ProcureToPayTaskCard.propTypes = {
    id: PropTypes.string.isRequired,
    isDashboard: PropTypes.bool,
};

export default ProcureToPayTaskCard;
