import TaskActionButton from "../../buttons/taskActionButton";
import CardActions from "@mui/material/CardActions";
import * as React from "react";
import { useState } from "react";
import * as PropTypes from "prop-types";
import { iBuyMainURL } from "../../../services/apiUtils/config/urls";
import {
    formatDate,
    formatNumberToCurrency,
} from "@sse-digital-services/design-system";
import useIBuyApprovals from "../../../services/apiUtils/hooks/useIBuyApprovals";
import BaseTaskCard from "../baseTaskCard";
import IBuyTaskDialog from "./iBuyTaskDialog";

const IBuyTaskCard = ({ id, isDashboard }) => {
    const { iBuyApprovalById } = useIBuyApprovals();
    const { requester, createdDate, amount, description, supplier, currency } =
        iBuyApprovalById(id);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const displayDate = formatDate(createdDate);
    const displayAmount = formatNumberToCurrency(amount, currency);
    const iBuyUrl = `${iBuyMainURL}/requisition-summary/${id}`;

    const openDialog = () => {
        setIsDialogOpen(true);
    };
    const rows = [
        { field: "Requisition", value: id },
        { field: "Requested by", value: requester },
    ];
    !isDashboard &&
        rows.push(
            { field: "Supplier", value: supplier },
            { field: "Submitted", value: formatDate(createdDate) }
        );

    return (
        <BaseTaskCard
            fields={rows}
            title={`iBuy Requisition`}
            action={openDialog}
            isDashboard={isDashboard}
        >
            <CardActions
                sx={{
                    p: "0px",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <TaskActionButton
                    mode="neutral"
                    action="View in iBuy"
                    url={iBuyUrl}
                />
            </CardActions>
            <IBuyTaskDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                id={id}
                description={description}
                supplier={supplier}
                date={displayDate}
                requester={requester}
                amount={displayAmount}
                url={iBuyUrl}
            />
        </BaseTaskCard>
    );
};

IBuyTaskCard.propTypes = {
    id: PropTypes.string.isRequired,
    isDashboard: PropTypes.bool,
};

export default IBuyTaskCard;
