import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    alpha,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Build, Error } from "@mui/icons-material";
import { ChevronDown } from "@sse-digital-services/design-system";

const TileAccordion = ({
    serviceName,
    tileName,
    count,
    children,
    isEditing,
    errorMessage,
    maintenanceMode,
}) => {
    const theme = useTheme();

    const message = maintenanceMode
        ? `${serviceName} is down for maintenance`
        : errorMessage;

    const displayDot = message || count !== undefined;

    const dotContent = maintenanceMode ? (
        <Build fontSize={"small"} sx={{ color: "info.contrastText" }} />
    ) : errorMessage ? (
        <Error fontSize={"small"} sx={{ color: "info.contrastText" }} />
    ) : (
        <Typography
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            color={"card.contrastText"}
            sx={{ display: "flex" }}
        >
            {count}
        </Typography>
    );

    return (
        <Accordion
            id={`${serviceName}-${tileName}-accordion`}
            disableGutters
            disabled={isEditing}
            sx={{
                boxShadow: "none",
                px: "8px",
                "&.MuiAccordion-root:before": {
                    display: "none",
                },
            }}
        >
            <AccordionSummary
                id={`${serviceName}-${tileName}-accordion-summary`}
                sx={{
                    display: "flex",
                    px: 0,
                    borderBottom: "1px solid",
                    borderColor: alpha(theme.palette.primary.main, 0.12),
                    "& .MuiAccordionSummary-content": {
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0px",
                    },
                }}
                expandIcon={
                    <ChevronDown
                        aria-label="expand service row"
                        sx={{
                            color: "icon.secondary",
                            borderRadius: "50%",
                            border: "2px solid",
                        }}
                    />
                }
            >
                <Typography fontWeight={"bold"}>{serviceName}</Typography>
                {displayDot && (
                    <Box
                        sx={{
                            m: 1,
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            backgroundColor: message
                                ? "error.main"
                                : "icon.secondary",
                            borderRadius: "50%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {dotContent}
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
                {message ? <Typography> {message}</Typography> : children}
            </AccordionDetails>
        </Accordion>
    );
};

TileAccordion.propTypes = {
    serviceName: PropTypes.string.isRequired,
    tileName: PropTypes.string.isRequired,
    count: PropTypes.number,
    children: PropTypes.node,
    isEditing: PropTypes.bool,
    maintenanceMode: PropTypes.bool,
    errorMessage: PropTypes.string,
};

export default TileAccordion;
