import iBuyCartsClient from "../clients/iBuyCartsClient";
import iBuyMapper from "../mappers/iBuyMapper";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import useSWR from "swr";
import { PROCUREMENT } from "../../../constants/serviceNames";

const useIBuyCarts = () => {
    const {
        servicesInMaintenanceMode,
        preferencesAreLoading,
        servicesBasedOnPermissions,
    } = usePreferences();
    const { throwAsyncError } = useErrorHandling();

    const { data: iBuyCarts, error: iBuyError } = useSWR(
        () => (preferencesAreLoading ? null : "iBuyCarts"),
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(PROCUREMENT) &&
                    servicesInMaintenanceMode
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === PROCUREMENT
                        )
                    ) {
                        const iBuyCartsData = await iBuyCartsClient.getCarts();
                        return iBuyMapper.mapAPICartsToCarts(iBuyCartsData);
                    }
                }
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.IBuyCartsAPIError,
                    "Unable to fetch carts from iBuy",
                    error
                );
                throw new Error("Unable to fetch carts from iBuy");
            }
        },
        { fallbackData: null }
    );

    return { iBuyCarts, errorMessage: iBuyError?.message };
};

export default useIBuyCarts;
