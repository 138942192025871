import React from "react";
import PropTypes from "prop-types";
import { PLANONFM, serviceNames } from "../../../constants/serviceNames";
import TileAccordion from "../tileAccordion";
import { Button, Typography } from "@mui/material";
import { planonLinks } from "../quickLinkTiles/planonFMTile";
import { ContentPasteGo } from "@mui/icons-material";
import { StyledTooltip } from "@sse-digital-services/design-system";

const PlanonFMSection = ({ isEditing, maintenanceMode = false }) => {
    return (
        <TileAccordion
            serviceName={serviceNames[PLANONFM]}
            tileName="requests"
            maintenanceMode={maintenanceMode}
            isEditing={isEditing}
        >
            {Object.keys(planonLinks).map((link) => (
                <StyledTooltip
                    key={link}
                    title={`View in ${serviceNames[PLANONFM]}`}
                >
                    <Button
                        aria-label={`${link}-link`}
                        data-track-id={`${link}-link`}
                        size="small"
                        href={planonLinks[link]}
                        target="_blank"
                        p={"5px"}
                        sx={{
                            color: "text.primary",
                            justifyContent: "space-between",
                            width: "100%",
                            textTransform: "none",
                            p: "7px",
                        }}
                        disabled={isEditing}
                    >
                        <Typography>{link}</Typography>
                        <ContentPasteGo />
                    </Button>
                </StyledTooltip>
            ))}
        </TileAccordion>
    );
};

PlanonFMSection.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    maintenanceMode: PropTypes.bool,
};

export default PlanonFMSection;
