import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
    timeout: 0,
};
const searchAssistantAPIService = apiService(
    apiConfig.searchAssistant,
    apiHeaderConfig
);

const searchAssistantClient = {
    chat: (request) => searchAssistantAPIService.post("chat", request),
};

export default searchAssistantClient;
