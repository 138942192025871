import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import { SERVICENOW } from "../../../constants/serviceNames";
import useServiceNowUser from "./useServiceNowUser";
import integrationsClient from "../clients/integrationsClient";
import serviceNowMapper from "../mappers/serviceNowMapper";
import useSWRInfinite from "swr/infinite";
import { useMemo } from "react";
import { PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE } from "../../../constants/generalConstants";
import { countAPIItems, reduceAPIItems } from "../../../utils/apiItemUtils";

const useServiceNowPtpIncidents = (viewClosed) => {
    const { servicesInMaintenanceMode, preferencesAreLoading } =
        usePreferences();
    const {
        serviceNowUser,
        serviceNowUserIsLoading,
        errorMessage: serviceNowUserErrorMessage,
    } = useServiceNowUser();
    const { throwAsyncError } = useErrorHandling();

    const getCacheKey = (pageCount) => {
        if (
            preferencesAreLoading ||
            serviceNowUserIsLoading ||
            !serviceNowUser
        ) {
            return null;
        }
        return ["ptp-incidents", pageCount, viewClosed];
    };

    const {
        data: ptpIncidentsData,
        size: loadedPageCount,
        setSize: setPagesToLoad,
        isLoading: ptpIncidentsAreLoading,
        error: ptpIncidentsError,
    } = useSWRInfinite(
        getCacheKey,
        async (args) => {
            try {
                const params = {
                    limit: PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE,
                    offset: args[1] * PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE,
                    show_closed: args[2],
                };
                if (servicesInMaintenanceMode && serviceNowUser) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === SERVICENOW
                        )
                    ) {
                        const incidentData =
                            await integrationsClient.getPtpIncidents(params, {
                                "sn-user-data-token": serviceNowUser,
                            });
                        return serviceNowMapper.mapAPIPtpIncidentsToIncidents(
                            incidentData
                        );
                    }
                }
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.ServiceNowIncidentsError,
                    "Unable to fetch incidents from Procure To Pay",
                    error
                );
                throw new Error(
                    "Unable to fetch incidents from Procure To Pay"
                );
            }
        },
        { fallbackData: [], parallel: true, revalidateFirstPage: false }
    );

    const ptpIncidentList = useMemo(() => {
        return reduceAPIItems(ptpIncidentsData, []);
    }, [ptpIncidentsData]);

    const ptpIncidentCount = useMemo(() => {
        return countAPIItems(ptpIncidentsData);
    }, [ptpIncidentsData]);

    return {
        ptpIncidentsAreLoading,
        ptpIncidentCount,
        ptpIncidentList,
        loadedPageCount,
        setPagesToLoad,
        errorMessage: serviceNowUserErrorMessage || ptpIncidentsError?.message,
    };
};

export default useServiceNowPtpIncidents;
