import * as React from "react";

import ChatBotContent from "./chatBotContent";
import { useDispatch, useSelector } from "react-redux";
import { setShowChatField } from "../../redux/actions/generalActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SwipeableDrawerWithHeader } from "@sse-digital-services/design-system";

const ChatBotDrawer = () => {
    const isWideScreen = useMediaQuery("(min-width:700px)");
    const dispatch = useDispatch();
    const showChatField = useSelector((state) => state.general.showChatField);
    const handleOpen = () => {
        dispatch(setShowChatField(true));
    };

    const handleClose = async () => {
        dispatch(setShowChatField(false));
    };

    return (
        <SwipeableDrawerWithHeader
            open={showChatField}
            onClose={handleClose}
            title={"Corporate Service Smart Assistant"}
            onOpen={handleOpen}
            paperSx={{
                width: isWideScreen ? 700 : "100%",
            }}
            id={"chat"}
        >
            <ChatBotContent />
        </SwipeableDrawerWithHeader>
    );
};

export { ChatBotDrawer };
