import {
    Box,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { Close } from "@sse-digital-services/design-system";
import React from "react";
import { styledDashboardTileActionButtonSX } from "styled/styledSXProperties/styledDashboardTileCardSX";

const InfoDialog = ({ heading, closeDialog, headerButton, children }) => {
    return (
        <>
            <DialogTitle>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "16px",
                    }}
                >
                    <Typography variant="h4" component="p">
                        {heading}
                    </Typography>
                    {headerButton}
                </Box>
                <IconButton
                    aria-label="close"
                    data-track-id={`info-dialog-${heading}-close`}
                    onClick={closeDialog}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close
                        sx={{
                            ...styledDashboardTileActionButtonSX,
                            backgroundColor: "background.paper",
                        }}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    overflowY: "auto",
                    maxHeight: "90%",
                }}
            >
                {children}
            </DialogContent>
        </>
    );
};

InfoDialog.propTypes = {
    heading: PropTypes.string.isRequired,
    closeDialog: PropTypes.func.isRequired,
    headerButton: PropTypes.object,
    children: PropTypes.object.isRequired,
};

export default InfoDialog;
