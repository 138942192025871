import React from "react";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { ContentPasteGo } from "@mui/icons-material";
import { serviceNames } from "../../constants/serviceNames";
import { StyledTooltip } from "@sse-digital-services/design-system";

export const GoToServiceButton = ({ service, url, disabled }) => {
    const serviceName = serviceNames[service];
    return (
        <StyledTooltip title={`View in ${serviceName}`}>
            <IconButton
                aria-label={`View in ${serviceName}`}
                data-track-id={`View in ${serviceName} icon`}
                size="small"
                href={url}
                onClick={(e) => e.stopPropagation()}
                target="_blank"
                sx={{ color: "text.primary" }}
                disabled={disabled}
            >
                <ContentPasteGo />
            </IconButton>
        </StyledTooltip>
    );
};

GoToServiceButton.propTypes = {
    service: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};
