import {
    Box,
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import { FEEDBACK_PER_PAGE } from "constants/generalConstants";
import React, { useState } from "react";
import useNPS from "services/apiUtils/hooks/useNPS";
import StyledPagination from "../../tables/styledPagination";

const UserFeedbackResultsCard = () => {
    const { borderSX } = useHighContrastBorderSX();
    const [page, setPage] = useState(0);
    const {
        averageRating,
        feedback,
        lastPageIndex,
        setPageIndex,
        npsDataIsLoadingMore,
    } = useNPS();
    const tableBodyHeight = `${Math.min(230, 46 * feedback.count)}px`;

    const itemsToDisplay = feedback.data.slice(
        page * FEEDBACK_PER_PAGE,
        (page + 1) * FEEDBACK_PER_PAGE
    );

    const rowsPerPage = Math.min(feedback.count, FEEDBACK_PER_PAGE);
    const emptyRowCount = rowsPerPage - itemsToDisplay.length;
    const emptyRows = new Array(emptyRowCount).fill({});

    const handlePageChange = (event, newPage) => {
        if (
            newPage + 1 >= lastPageIndex &&
            feedback.count > (newPage + 1) * FEEDBACK_PER_PAGE
        ) {
            setPageIndex(newPage + 2);
        }
        setPage(newPage);
    };
    return (
        <Grid
            id="rating-results-card"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            item
        >
            <Card sx={{ padding: "16px", ...borderSX }}>
                <Box sx={{ marginBottom: "24px" }}>
                    <Typography sx={{ color: "text.primary" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Average review score:
                        </span>
                        {averageRating
                            ? ` ${averageRating} stars`
                            : " Average rating not available"}
                    </Typography>
                </Box>
                <TableContainer>
                    <Table
                        data-track-id="nps-ratings-table"
                        aria-label="ratings table"
                        size="small"
                        sx={{
                            "& .MuiTableCell-sizeSmall": {
                                padding: "2px",
                                borderColor: "text.primary",
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "5%",
                                        minWidth: "60px",
                                    }}
                                >
                                    Rating
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "15%",
                                        minWidth: "100px",
                                    }}
                                >
                                    Visit Reason
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "65%",
                                        minWidth: "150px",
                                    }}
                                >
                                    Comments
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        fontWeight: "bold",
                                        width: "15%",
                                        minWidth: "200px",
                                    }}
                                >
                                    Timestamp
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                height: tableBodyHeight,
                                verticalAlign: "top",
                            }}
                        >
                            {itemsToDisplay.map((ratingRowData, index) => (
                                <TableRow
                                    key={`rating-${index}`}
                                    sx={{
                                        width: "100%",
                                        height: "46px",
                                    }}
                                >
                                    <TableCell>
                                        {ratingRowData.rating}
                                    </TableCell>
                                    <TableCell>
                                        {ratingRowData.reason}
                                    </TableCell>
                                    <TableCell>
                                        {ratingRowData.comments}
                                    </TableCell>
                                    <TableCell align="right">
                                        {ratingRowData.timestamp}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows.map((_, index) => (
                                <TableRow
                                    key={`emptyRow-${index}`}
                                    sx={{
                                        width: "100%",
                                        height: "46px",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            borderBottom: "none",
                                        }}
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <StyledPagination
                                    handlePageChange={handlePageChange}
                                    totalItems={feedback.count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    disabled={npsDataIsLoadingMore}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    );
};

export default UserFeedbackResultsCard;
