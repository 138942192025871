import React, { useEffect } from "react";
import { SERVICENOW_INCIDENT_ROWS_PER_PAGE } from "../../../constants/generalConstants";
import PropTypes from "prop-types";
import PaginatedTable from "../../tables/paginatedTable";
import { itscUrl } from "../../../services/apiUtils/config/urls";
import ItscIncidentsRow from "./itscIncidentsRow";
import { serviceNames, SERVICENOW } from "../../../constants/serviceNames";
import useServiceNowIncidents from "../../../services/apiUtils/hooks/useServiceNowIncidents";
import TileAccordion from "../tileAccordion";

const ItscIncidentsSection = ({
    isEditing,
    maintenanceMode = false,
    viewClosed = false,
}) => {
    const {
        incidentList,
        setPagesToLoad,
        loadedPageCount,
        incidentCount,
        errorMessage,
    } = useServiceNowIncidents(viewClosed);
    const preloadNextPage = (newPage) => {
        if (
            newPage + 1 >= loadedPageCount &&
            incidentCount > (newPage + 1) * SERVICENOW_INCIDENT_ROWS_PER_PAGE
        ) {
            setPagesToLoad(newPage + 2);
        }
    };

    useEffect(() => {
        if (
            loadedPageCount === 1 &&
            incidentCount > SERVICENOW_INCIDENT_ROWS_PER_PAGE
        ) {
            setPagesToLoad(2);
        }
    }, [loadedPageCount, incidentCount, setPagesToLoad]);

    return (
        <TileAccordion
            serviceName={serviceNames[SERVICENOW]}
            tileName="incidents"
            maintenanceMode={maintenanceMode}
            errorMessage={errorMessage}
            count={incidentCount}
            isEditing={isEditing}
        >
            <PaginatedTable
                totalItems={incidentCount}
                isEditing={isEditing}
                serviceUrl={`${itscUrl}?id=incidents`}
                itemsPerPage={SERVICENOW_INCIDENT_ROWS_PER_PAGE}
                service={SERVICENOW}
                items={incidentList ?? []}
                Row={ItscIncidentsRow}
                onPageChange={preloadNextPage}
            />
        </TileAccordion>
    );
};

ItscIncidentsSection.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    maintenanceMode: PropTypes.bool,
    viewClosed: PropTypes.bool,
};

export default ItscIncidentsSection;
