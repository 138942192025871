import Dialog from "@mui/material/Dialog";
import usePWANotificationRequest from "./usePWANotificationRequest";
import { Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
    getDeviceInfo,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import InfoDialog from "components/modals/InfoDialog";
import { buttonSX } from "styled/styledSXProperties/styledUserInput";
import { APP_NAME } from "../../../constants/generalConstants";

const TriggerNotificationRequestDialog = () => {
    const theme = useTheme();
    const { borderSX } = useHighContrastBorderSX();
    const [closeButtonPressed, setCloseButtonPressed] = useState(false);
    const { shouldRequestPermission, requestPermission } =
        usePWANotificationRequest();
    const { ios } = getDeviceInfo();

    const handleClick = async () => {
        setCloseButtonPressed(true);
        if (ios) {
            localStorage.setItem(
                "iosShownNotificationPermissionRequest",
                "true"
            );
        }
        return await requestPermission();
    };

    return (
        <Dialog
            open={shouldRequestPermission && !closeButtonPressed}
            PaperProps={{
                sx: borderSX,
            }}
        >
            <InfoDialog
                heading={`Please allow ${APP_NAME} to send you notifications when prompted`}
                closeDialog={() => setCloseButtonPressed(true)}
            >
                <Button
                    onClick={handleClick}
                    variant="contained"
                    sx={{
                        ...borderSX,
                        ...buttonSX(theme),
                        m: "16px",
                        pl: "6px",
                        pr: "6px",
                    }}
                >
                    OK
                </Button>
            </InfoDialog>
        </Dialog>
    );
};

export default TriggerNotificationRequestDialog;
