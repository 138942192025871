import useNPS from "../../../services/apiUtils/hooks/useNPS";
import Box from "@mui/material/Box";
import { InputLabel, Rating, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import { StarBorderOutlined, StarOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setShowFeedbackForm } from "../../../redux/actions/generalActions";
import { triggerSnackbar } from "../../../utils/alertsUtils";
import TextInput from "components/forms/textInput";
import ButtonArea from "components/forms/buttonArea";

const NpsForm = () => {
    const { submitNPSFeedback } = useNPS();
    const [rating, setRating] = useState(0);
    const [reason, setReason] = useState("");
    const [additionalReason, setAdditionalReason] = useState("");
    const [comments, setComments] = useState("");
    const { borderSX } = useHighContrastBorderSX();
    const dispatch = useDispatch();

    const setShowForm = (newShowForm) => {
        dispatch(setShowFeedbackForm(newShowForm));
    };

    const onSubmit = async () => {
        if (
            rating > 0 &&
            reason !== "" &&
            !(reason === "Other" && additionalReason === "")
        ) {
            await submitNPSFeedback({
                rating,
                reason,
                additionalReason,
                comments,
            });
            setShowForm(false);
        } else {
            triggerSnackbar(
                "info",
                "Please complete all fields before submitting",
                null,
                dispatch
            );
        }
    };

    const onFormClose = () => {
        setShowForm(false);
    };

    const updateReason = (reason) => {
        if (reason !== "Other") {
            setAdditionalReason("");
        }
        setReason(reason);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: "24px 16px",
                    overflow: "auto",
                }}
            >
                <Typography variant="h4" component="p">
                    Your feedback will help us improve and is completely
                    anonymous.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignContent: "flex-start",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignContent: "flex-start",
                            width: "100%",
                            paddingTop: "24px",
                        }}
                    >
                        <Typography variant="h6" component="p">
                            How was your visit today?
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                paddingTop: "8px",
                            }}
                        >
                            <Rating
                                data-track-id="nps-rating-stars"
                                sx={{
                                    fontSize: "2.5rem",
                                    "& .MuiRating-icon": { padding: "8px" },
                                }}
                                icon={
                                    <StarOutlined
                                        fontSize="inherit"
                                        sx={{ color: "warning.main" }}
                                    />
                                }
                                emptyIcon={
                                    <StarBorderOutlined
                                        fontSize="inherit"
                                        sx={{ color: "text.primary" }}
                                    />
                                }
                                value={rating}
                                onChange={(event, newValue) =>
                                    setRating(newValue)
                                }
                                precision={1}
                                max={5}
                                defaultValue={0}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="body4" component="p">
                            Very poor
                        </Typography>
                        <Typography variant="body4" component="p">
                            Very good
                        </Typography>
                    </Box>
                </Box>
                <FormControl sx={{ width: "100%", paddingTop: "24px" }}>
                    <InputLabel
                        id="reason-select-label"
                        disableAnimation={true}
                        shrink={false}
                        hidden={false}
                        sx={{
                            position: "relative",
                            transform: "unset",
                            marginBottom: "8px",
                            color: "text.primary",
                            "&.Mui-focused": {
                                color: "text.primary",
                            },
                        }}
                    >
                        <Typography variant="h6" component="p">
                            What was the main reason for your visit today?
                        </Typography>
                    </InputLabel>
                    <Select
                        data-track-id="nps-visit-reason"
                        labelId="reason-select-label"
                        placeholder="Please Select your reason"
                        value={reason}
                        onChange={(event) => updateReason(event.target.value)}
                        notched={true}
                        sx={{
                            ...borderSX,
                            "& .MuiSvgIcon-root": {
                                color: "text.primary",
                            },
                        }}
                        MenuProps={{
                            MenuListProps: {
                                sx: borderSX,
                            },
                        }}
                    >
                        <MenuItem value={"Ask Edison"}>Ask Edison</MenuItem>
                        <MenuItem value={"Approvals"}>
                            Viewing Approvals
                        </MenuItem>
                        <MenuItem value={"Tasks"}>Viewing Tasks</MenuItem>
                        <MenuItem value={"Links"}>Finding Links</MenuItem>
                        <MenuItem value={"Planning"}>Planning my day</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                </FormControl>
                {reason === "Other" && (
                    <TextInput
                        labelText="Please specify your reason for visiting"
                        dataTrackId="nps-other-reason"
                        id="other-reason"
                        value={additionalReason}
                        onChange={(event) =>
                            setAdditionalReason(event.target.value)
                        }
                        multiline={true}
                        inputProps={{ maxLength: 300 }}
                    />
                )}
                <TextInput
                    labelText="Please share any additional feedback (optional):"
                    dataTrackId="nps-additional-feedback"
                    id="additional-feedback"
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                    multiline={true}
                    minRows={4}
                    inputProps={{ maxLength: 300 }}
                />
            </Box>
            <ButtonArea
                buttonList={[
                    {
                        text: "Close",
                        onClick: onFormClose,
                        dataTrackId: "nps-form-close",
                    },
                    {
                        text: "Submit",
                        onClick: onSubmit,
                        dataTrackId: "nps-form-submit",
                    },
                ]}
                sx={{ padding: "0 8px", margin: "8px 8px 0 0" }}
            />
        </>
    );
};

export default NpsForm;
