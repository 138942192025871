import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import userProfileClient from "../clients/userProfileClient";
import { jwtDecode } from "jwt-decode";
import { USER_PROFILE_EXPIRY_BUFFER_MS } from "../../../constants/generalConstants";
import { useCallback } from "react";

const useUserProfile = () => {
    const { throwAsyncError } = useErrorHandling();

    const getTokenExpiryTSinMS = useCallback((token) => {
        const tokenData = jwtDecode(token);
        return tokenData.exp * 1000;
    }, []);

    const nextRefreshInterval = (token) => {
        try {
            return (
                getTokenExpiryTSinMS(token) -
                (Date.now() + USER_PROFILE_EXPIRY_BUFFER_MS)
            );
        } catch {
            return 0;
        }
    };

    const { data: userProfileToken, isLoading: userProfileIsLoading } = useSWR(
        "userProfile",
        async () => {
            try {
                const userProfileData =
                    await userProfileClient.getUserProfile();
                return userProfileData.token;
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.UserProfileAPIError,
                    "Unable to fetch user profile data",
                    error
                );
            }
        },
        { refreshInterval: nextRefreshInterval }
    );

    return { userProfileToken, userProfileIsLoading, getTokenExpiryTSinMS };
};

export default useUserProfile;
