import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};
const integrationsAPIService = apiService(
    apiConfig.integrations,
    apiHeaderConfig
);

const integrationsClient = {
    getUser: () => integrationsAPIService.get("servicenow-user"),
    getRequests: (params, requestHeaders) =>
        integrationsAPIService.get(`itsc-requests`, params, requestHeaders),
    getRequestItems: (params, requestHeaders) =>
        integrationsAPIService.get(
            `servicenow-request-item`,
            params,
            requestHeaders
        ),
    getIncidents: (params, requestHeaders) =>
        integrationsAPIService.get(`itsc-incidents`, params, requestHeaders),
    getPtpIncidents: (params, requestHeaders) =>
        integrationsAPIService.get(`ptp-incidents`, params, requestHeaders),
    getApprovals: (requestHeaders) =>
        integrationsAPIService.get(
            `itsc-approvals`,
            {
                order_by: "DESC",
                limit: 20,
            },
            requestHeaders
        ),
    getProcureToPayApprovals: (requestHeaders) =>
        integrationsAPIService.get(
            `ptp-incidents-tasks`,
            {
                order_by: "DESC",
                limit: 20,
            },
            requestHeaders
        ),
    getProcureToPayRequestItemTasks: (requestHeaders) =>
        integrationsAPIService.get(
            `ptp-request-tasks`,
            {
                order_by: "DESC",
                limit: 20,
            },
            requestHeaders
        ),
    getSharePrice: () => integrationsAPIService.get("sse-shareprice"),
};

export default integrationsClient;
