import { initialState } from "../initialState";
import {
    SET_APPROVALS_TASK_COUNT,
    SET_LMS_COURSES_TASK_COUNT,
    SET_ITSC_APPROVALS_TASK_COUNT,
    SET_PROCURE_TO_PAY_APPROVALS_TASK_COUNT,
    SET_PROCURE_TO_PAY_REQUEST_ITEM_TASK_COUNT,
} from "../actionTypes";

const taskCountReducer = (state = initialState.taskCount, action) => {
    switch (action.type) {
        case SET_APPROVALS_TASK_COUNT:
            return {
                ...state,
                approvals: action.payload,
            };
        case SET_ITSC_APPROVALS_TASK_COUNT:
            return {
                ...state,
                itscApprovals: action.payload,
            };
        case SET_PROCURE_TO_PAY_APPROVALS_TASK_COUNT:
            return {
                ...state,
                ptpApprovals: action.payload,
            };
        case SET_PROCURE_TO_PAY_REQUEST_ITEM_TASK_COUNT:
            return {
                ...state,
                ptpRequestItemTasks: action.payload,
            };
        case SET_LMS_COURSES_TASK_COUNT:
            return {
                ...state,
                lmsCourseDueTasks: action.payload,
            };
        default:
            return state;
    }
};

export default taskCountReducer;
