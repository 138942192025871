// Returns action creators for each alert action type
import { APPEND_TO_SNACKBAR_QUEUE, SET_SNACKBAR_QUEUE } from "../actionTypes";

// Action creators for alerts slice
export const appendToSnackbarQueue = (alert) => {
    return { type: APPEND_TO_SNACKBAR_QUEUE, payload: alert };
};

export const setSnackbarQueue = (newQueue) => {
    return { type: SET_SNACKBAR_QUEUE, payload: newQueue };
};
