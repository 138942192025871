import { TableCell, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { getStatusChip } from "../chips/statusChips";
import { styledCellSX } from "../../styled/styledSXProperties/styledPaginatedTable";
import { GoToServiceButton } from "../buttons/goToServiceButton";
import { MoreInfoButton } from "components/buttons/moreInfoButton";

const SummaryRow = ({ item, isEditing, service, url, openMoreInfoModal }) => {
    return (
        <>
            <TableCell component="th" scope="row" sx={styledCellSX}>
                <Typography variant="body1" componet="p">
                    {item.id}
                </Typography>
            </TableCell>
            <TableCell align="left" sx={styledCellSX}>
                {getStatusChip(item.displayState)}
            </TableCell>
            {openMoreInfoModal && (
                <TableCell align="right" sx={styledCellSX}>
                    <MoreInfoButton
                        ariaLabel={"open more info modal"}
                        dataTrackId={`open ${item.id} info modal`}
                        openMoreInfoModal={openMoreInfoModal}
                    />
                </TableCell>
            )}
            <TableCell align="right" sx={styledCellSX}>
                <GoToServiceButton
                    url={url ?? `https://${item.url}`}
                    colour={"text.primary"}
                    disabled={isEditing}
                    service={service}
                />
            </TableCell>
        </>
    );
};
SummaryRow.propTypes = {
    item: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    service: PropTypes.string.isRequired,
    url: PropTypes.string,
    openMoreInfoModal: PropTypes.func,
};

export default SummaryRow;
