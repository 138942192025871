export const isEnvProd = GLOBAL_CONFIG.REACT_APP_ENVIRONMENT === "prd";

// Defines routes for all webpages
import { payslipsMainURL } from "../services/apiUtils/config/urls";

// Payslips
export const PAYSLIPS_LATEST = `${payslipsMainURL}/employee/current-payslip`;
export const PAYSLIPS_HISTORICAL = `${payslipsMainURL}/employee/payslips`;

// Sharepoint
export const SSE_NET_HR_HUB = "https://ssecom.sharepoint.com/sites/HRHub";
export const SSE_NET_HOME = "https://ssecom.sharepoint.com/sites/SSENetHome";
export const SSE_EDISON =
    "https://ssecom.sharepoint.com.mcas.ms/sites/corporate-procurement%26commercial-cdp/SitePages/Edison.aspx";
export const MANAGERS_HUB =
    "https://ssecom.sharepoint.com/teams/Corporate-ManagersHub";
export const BENEFITS = "https://ssecom.sharepoint.com/sites/Benefits";
export const ERF_PORTAL = "https://ssecom.sharepoint.com/sites/ERFPortal";

// Planon
export const PLANON_URL = isEnvProd
    ? "https://ssefm-prod.planoncloud.com/"
    : "https://ssefm-acc.planoncloud.com/home/BP/WebClient?269";
export const PLANON_RESERVATIONS = isEnvProd
    ? "https://ssefm-prod.planoncloud.com/case/BP/SSE_MY_RES"
    : "https://ssefm-acc.planoncloud.com/case/BP/RL001";
export const PLANON_VISITORS = isEnvProd
    ? "https://ssefm-prod.planoncloud.com/case/BP/SSE_MY_VIS"
    : "https://ssefm-acc.planoncloud.com/case/BP/VL02";
export const PLANON_REQUESTS_FAULTS =
    "https://ssefm-acc.planoncloud.com/case/BP/SSE_REQ";
export const PLANON_PROD_RAISE_REQUESTS_FAULTS =
    "https://ssefm-prod.planoncloud.com/case/BP/SSE0007";
export const PLANON_PROD_VIEW_REQUESTS_FAULTS =
    "https://ssefm-prod.planoncloud.com/case/BP/RQL000";

// Nudge
export const NUDGE_URL =
    "https://launcher.myapps.microsoft.com/api/signin/87a335e1-bfb7-4d52-9c37-f21ff8d0f1d7?tenantId=953b0f83-1ce6-45c3-82c9-1d847e372339";

// Expenses
export const EXPENSES_DESKTOP =
    "https://apps.powerapps.com/play/e/ebaea886-d56f-e993-8648-930b9eaa6ead/a/fbdff25c-5d2d-4c31-8222-3f39fd579ee6?tenantId=953b0f83-1ce6-45c3-82c9-1d847e372339&hint=f24f1bb5-c016-481f-82f7-15fe0214352c&sourcetime=1695652394973";
export const EXPENSES_MOBILE =
    "https://apps.powerapps.com/play/e/ebaea886-d56f-e993-8648-930b9eaa6ead/a/f9d66420-b67c-4a3b-b5a6-0c90ed2cc905?tenantId=953b0f83-1ce6-45c3-82c9-1d847e372339&hint=9e4af339-e04a-49c3-90e0-5bd4c9c989d6&sourcetime=1695652367193";

//Data marketplace
export const DATA_MARKETPLACE =
    "https://dnaaxon-ssecom.msappproxy.net/marketplace/browse";

//Fieldglass
export const FIELDGLASS_SSO =
    "https://www.fieldglass.eu/SSOLogin?SSOParams=company%3DSSEG%3B%3Breturningurl%3DcloseWindow";
export const FIELDGLASS = "https://www.fieldglass.eu";

// Jaggaer
export const JAGGAER_SSO_LINK =
    "https://launcher.myapps.microsoft.com/api/signin/9de230fe-29dd-4572-9d3d-533896a47069?tenantId=953b0f83-1ce6-45c3-82c9-1d847e372339";

// LMS
export const LMS = "https://fa-exqf-saasfaprod1.fa.ocs.oraclecloud.com/";

// Misc
export const TRAVEL_HUB =
    "https://launcher.myapps.microsoft.com/api/signin/8ba41435-1829-4251-984a-ffd9c4027714?tenantId=953b0f83-1ce6-45c3-82c9-1d847e372339";
export const EMPLOYEE_PRIVACY_NOTICE =
    "https://ssecom.sharepoint.com.mcas.ms/teams/group-doc-lib/SSE%20Document%20Library/Forms/AllItems.aspx?id=%2Fteams%2Fgroup%2Ddoc%2Dlib%2FSSE%20Document%20Library%2FCorporate%2FHuman%20Resources%2FREF%2DCOR%2DHR%2D100%20Employee%20Privacy%20Notice%2Epdf&parent=%2Fteams%2Fgroup%2Ddoc%2Dlib%2FSSE%20Document%20Library%2FCorporate%2FHuman%20Resources";
export const SPOTLIGHT =
    "https://launcher.myapps.microsoft.com/api/signin/50e76207-55ae-47bc-9c3a-43b58fd2b45b?tenantId=953b0f83-1ce6-45c3-82c9-1d847e372339";
export const SSE_INTERNAL_JOBS = "https://internaljobs.sse.com/";
export const SSE_ADVANTAGE_UK =
    "https://launcher.myapps.microsoft.com/api/signin/f7344603-34b9-403e-8429-7ea78589ce9c?tenantId=953b0f83-1ce6-45c3-82c9-1d847e372339";
export const SSE_ADVANTAGE_IRE = "https://sse.groupscheme.com";
export const SSE_SHARE_PRICE =
    "https://www.sse.com/investors/shareholder-services/share-price-information/";
