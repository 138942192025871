import { Collapse, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
    ExpandMoreButton,
    TwoColDetailsGrid,
} from "@sse-digital-services/design-system";
import {
    styledCellSX,
    styledRowSX,
} from "../../styled/styledSXProperties/styledPaginatedTable";

const BaseRow = ({ itemDetails, children }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <TableRow sx={styledRowSX} onClick={() => setExpanded(!expanded)}>
                {itemDetails && (
                    <TableCell align="left" padding="none" sx={styledCellSX}>
                        <ExpandMoreButton
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    </TableCell>
                )}
                {children}
            </TableRow>
            {itemDetails && (
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: "none",
                        }}
                        colSpan={6}
                    >
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <TwoColDetailsGrid
                                    details={itemDetails}
                                    spacing={0.5}
                                />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
BaseRow.propTypes = {
    itemDetails: PropTypes.array,
    children: PropTypes.node,
};

export default BaseRow;
