import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { APP_NAME } from "../../constants/generalConstants";
import {
    CountPill,
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
    HeaderBarSection,
    ProfileSettingsHeaderSection,
    StyledTooltip,
} from "@sse-digital-services/design-system";
import useAvatarImage from "../../services/apiUtils/hooks/useAvatarImage";
import { useMsal } from "@azure/msal-react";
import { logOutUser } from "../../utils/authUtils";
import useNotifications from "../../services/apiUtils/hooks/useNotifications";
import {
    setShowNotifications,
    setShowUserInfo,
} from "../../redux/actions/generalActions";
import SettingsContent from "../modals/settings/settingsContent";
import React, { useRef } from "react";
import NotificationsDrawer from "../modals/notifications/notificationsDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { NotificationsOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";

const SfHeaderBarContent = () => {
    // Collect states and state dispatchers
    const { accounts, instance: msalInstance } = useMsal();
    const dispatch = useDispatch();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const { handheld } = getDeviceInfo();
    const notificationsRef = useRef(null);
    const showUserInfo = useSelector((state) => state.general.showUserInfo);
    const userName = useSelector((state) => state.auth.userName);
    const email = useSelector((state) => state.auth.email);
    const { refreshNotifications } = useNotifications();
    const { avatarImage } = useAvatarImage();
    const { notifications } = useNotifications();
    const showNotifications = useSelector(
        (state) => state.general.showNotifications
    );
    const unreadNotificationsCount = notifications
        ? notifications.unread_count
        : undefined;

    const headerItemSX = { color: "icon.secondary" };

    const handleSettingsClose = async () => {
        await refreshNotifications();
    };

    const notificationsLabel = (count) => {
        if (count === 0) {
            return "no notifications";
        }
        if (count > 9) {
            return "more than 9 notifications";
        }
        return `${count} notifications`;
    };

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                justifyContent:
                    isDesktopWidth && !handheld ? "space-between" : "flex-end",
            }}
        >
            <HeaderBarSection hideOnMobile={true}>
                <Typography
                    variant="h2"
                    color="secondary.contrastText"
                    ml={0.5}
                >
                    {APP_NAME}
                </Typography>
            </HeaderBarSection>
            <HeaderBarSection>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent:
                            isDesktopWidth && !handheld
                                ? "space-between"
                                : "flex-end",
                        minWidth: "143px",
                    }}
                >
                    {isDesktopWidth && !handheld && (
                        <>
                            <StyledTooltip title="Notifications">
                                <IconButton
                                    data-track-id="open notifications"
                                    aria-label={notificationsLabel(
                                        unreadNotificationsCount
                                    )}
                                    onClick={() =>
                                        dispatch(
                                            setShowNotifications(
                                                !showNotifications
                                            )
                                        )
                                    }
                                    ref={notificationsRef}
                                >
                                    <CountPill count={unreadNotificationsCount}>
                                        <NotificationsOutlined
                                            sx={{
                                                ...headerItemSX,
                                                width: "27px",
                                                height: "27px",
                                            }}
                                        />
                                    </CountPill>
                                </IconButton>
                            </StyledTooltip>
                            <NotificationsDrawer />
                        </>
                    )}
                    <ProfileSettingsHeaderSection
                        email={email}
                        userName={userName}
                        avatarImage={avatarImage}
                        settingsContent={<SettingsContent />}
                        onLogout={() => logOutUser(accounts, msalInstance)}
                        onSettingsClose={handleSettingsClose}
                        showUserInfo={showUserInfo}
                        setShowUserInfo={(show) =>
                            dispatch(setShowUserInfo(show))
                        }
                    />
                </Box>
            </HeaderBarSection>
        </Box>
    );
};
export default SfHeaderBarContent;
