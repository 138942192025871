import {
    SET_APPROVALS_TASK_COUNT,
    SET_LMS_COURSES_TASK_COUNT,
    SET_ITSC_APPROVALS_TASK_COUNT,
    SET_PROCURE_TO_PAY_APPROVALS_TASK_COUNT,
    SET_PROCURE_TO_PAY_REQUEST_ITEM_TASK_COUNT,
} from "../actionTypes";

export const setApprovalsTaskCount = (count) => {
    return {
        type: SET_APPROVALS_TASK_COUNT,
        payload: count,
    };
};

export const setItscApprovalsTaskCount = (count) => {
    return {
        type: SET_ITSC_APPROVALS_TASK_COUNT,
        payload: count,
    };
};

export const setProcureToPayApprovalsTaskCount = (count) => {
    return {
        type: SET_PROCURE_TO_PAY_APPROVALS_TASK_COUNT,
        payload: count,
    };
};

export const setProcureToPayRequestItemTaskCount = (count) => {
    return {
        type: SET_PROCURE_TO_PAY_REQUEST_ITEM_TASK_COUNT,
        payload: count,
    };
};

export const setLMSCoursesTaskCount = (count) => ({
    type: SET_LMS_COURSES_TASK_COUNT,
    payload: count,
});
