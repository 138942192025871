import React, { useState } from "react";
import PropTypes from "prop-types";
import { SERVICENOW } from "../../../constants/serviceNames";
import BaseRow from "../../tables/baseRow";
import SummaryRow from "../../tables/summaryRow";
import { formatDateTime } from "@sse-digital-services/design-system";
import ServiceNowMoreInfoModal from "../../../components/modals/serviceNowMoreInfoModal";

const ServiceNowRequestRow = ({ item, isEditing }) => {
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
    let requestDetails = [
        {
            label: "Approval status",
            boldLabel: true,
            value: item.approval?.toUpperCase(),
        },
        {
            label: "Priority",
            boldLabel: true,
            value: item.priority,
        },
        {
            label: "Last updated",
            boldLabel: true,
            value: formatDateTime(item.updatedDate),
        },
        {
            label: "Created on",
            boldLabel: true,
            value: formatDateTime(item.createdDate),
        },
    ];

    return (
        <>
            <BaseRow itemDetails={requestDetails}>
                <SummaryRow
                    item={item}
                    service={SERVICENOW}
                    isEditing={isEditing}
                    openMoreInfoModal={() => setMoreInfoModalOpen(true)}
                />
            </BaseRow>
            {moreInfoModalOpen && (
                <ServiceNowMoreInfoModal
                    request={item}
                    closeDialog={() => setMoreInfoModalOpen(false)}
                    isEditing={isEditing}
                />
            )}
        </>
    );
};
ServiceNowRequestRow.propTypes = {
    item: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

export default ServiceNowRequestRow;
