import { FormControl, InputLabel, TextField, Typography } from "@mui/material";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import React from "react";
import PropTypes from "prop-types";
const TextInput = ({
    labelText,
    subLabelText = undefined,
    dataTrackId,
    id,
    value,
    onChange,
    multiline,
    minRows,
    inputProps,
}) => {
    const { borderSX } = useHighContrastBorderSX();
    return (
        <FormControl sx={{ width: "100%", paddingTop: "24px" }}>
            <InputLabel
                htmlFor={id}
                disableAnimation={true}
                shrink={false}
                hidden={false}
                sx={{
                    position: "relative",
                    transform: "unset",
                    marginBottom: "8px",
                    color: "text.primary",
                }}
            >
                <Typography variant="h6">{labelText}</Typography>
            </InputLabel>
            {subLabelText && (
                <Typography
                    color="text.primary"
                    component="p"
                    textAlign="left"
                    marginBottom="8px"
                >
                    {subLabelText}
                </Typography>
            )}
            <TextField
                data-track-id={dataTrackId}
                id={id}
                variant="outlined"
                sx={{ ...borderSX }}
                onChange={onChange}
                value={value}
                multiline={multiline}
                minRows={minRows}
                inputProps={inputProps}
            />
        </FormControl>
    );
};

TextInput.propTypes = {
    labelText: PropTypes.string.isRequired,
    subLabelText: PropTypes.string,
    dataTrackId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    multiline: PropTypes.bool,
    minRows: PropTypes.number,
    inputProps: PropTypes.object,
};

export default TextInput;
