import usePreferences from "./usePreferences";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import iBuyRequisitionClient from "../clients/iBuyRequisitionClient";
import iBuyMapper from "../mappers/iBuyMapper";
import { PROCUREMENT } from "../../../constants/serviceNames";
import { useDispatch } from "react-redux";
import { setApprovalsTaskCount } from "../../../redux/actions/taskCountActions";
import { useCallback, useMemo } from "react";

const useIBuyApprovals = () => {
    const dispatch = useDispatch();
    const {
        servicesInMaintenanceMode,
        servicesBasedOnPermissions,
        preferencesAreLoading,
    } = usePreferences();

    const { throwAsyncError } = useErrorHandling();

    const {
        data: approvals,
        mutate: refreshApprovals,
        isLoading: iBuyApprovalsDataIsLoading,
    } = useSWR(
        () => (preferencesAreLoading ? null : "approvals"),
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(PROCUREMENT) &&
                    servicesInMaintenanceMode
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === PROCUREMENT
                        )
                    ) {
                        const approvalsData =
                            await iBuyRequisitionClient.getApprovals();
                        const approvalsMapped =
                            iBuyMapper.mapAPIApprovalsToApprovals(
                                approvalsData
                            );
                        dispatch(setApprovalsTaskCount(approvalsMapped.length));
                        return approvalsMapped;
                    }
                }
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.IBuyApprovalsAPIError,
                    "Unable to fetch approval tasks from iBuy",
                    error
                );
            }
        },
        { fallbackData: [] }
    );

    const iBuyApprovalById = useCallback(
        (id) => {
            if (approvals) {
                const approval = approvals.find((approval) => {
                    return approval.id === id;
                });
                return approval ?? {};
            }
            return {};
        },
        [approvals]
    );

    const iBuyApprovalsAreLoading = useMemo(
        () => iBuyApprovalsDataIsLoading || preferencesAreLoading,
        [iBuyApprovalsDataIsLoading, preferencesAreLoading]
    );

    return {
        approvals,
        refreshApprovals,
        iBuyApprovalsAreLoading,
        iBuyApprovalById,
    };
};

export default useIBuyApprovals;
