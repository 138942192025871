import { alpha, Card, CardContent, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {
    styledSolidTileContentEditingSX,
    styledSolidTileContentSX,
    styledSolidTileRootEditingSX,
    styledSolidTileRootSX,
} from "styled/styledSXProperties/styledDashboardTileCardSX";

const SolidTile = ({ children, isEditing, tileColor = null, contentSX }) => {
    const theme = useTheme();
    const contentStyle = isEditing
        ? {
              ...styledSolidTileContentEditingSX,
              ...contentSX,
          }
        : {
              ...styledSolidTileContentSX,
              ...contentSX,
          };

    return (
        <Card
            sx={{
                ...(isEditing
                    ? styledSolidTileRootEditingSX(theme)
                    : styledSolidTileRootSX),
                ...(tileColor && {
                    backgroundColor: alpha(tileColor, isEditing ? 0.5 : 1),
                }),
            }}
        >
            <CardContent sx={contentStyle}>{children}</CardContent>
        </Card>
    );
};

SolidTile.propTypes = {
    children: PropTypes.node.isRequired,
    isEditing: PropTypes.bool.isRequired,
    tileColor: PropTypes.string,
    contentSX: PropTypes.object,
};

export default SolidTile;
