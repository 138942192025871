import { apiConfig } from "../config/apiConfig";
import apiService from "../apiService";

const apiHeaderConfig = {
    responseType: "arraybuffer",
};

const avatarAPIService = apiService(apiConfig.avatar, apiHeaderConfig);

const avatarClient = {
    getAvatar: () => avatarAPIService.get(),
};

export default avatarClient;
