import usePWAInstall from "./usePWAInstall";
import useServiceWorker from "./useServiceWorker";

const PwaWrapper = ({ children }) => {
    useServiceWorker();
    usePWAInstall();

    return children;
};

export default PwaWrapper;
