import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";

const IBuyCartSelector = ({ cartsData, activeCart, onChange, disabled }) => {
    const { borderSX } = useHighContrastBorderSX();

    const inputPlaceholder = () => (
        <Typography
            sx={{
                textAlign: "left",
                margin: "10px 5px",
                color: "common.black",
            }}
        >
            {cartsData[activeCart].name}
        </Typography>
    );

    return (
        <FormControl
            variant="outlined"
            sx={{
                m: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                minWidth: "100px",
                borderRadius: "1px",
                boxShadow: "none",
                backgroundColor: "common.white",
                color: "common.darkTeal",
                "& .MuiSelect-select": {
                    py: "1px",
                    px: "2px",
                },
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "common.darkTeal",
                    borderWidth: "2px",
                    borderRadius: "1px",
                },
            }}
            disabled={disabled}
        >
            <Select
                id="iBuy-cart-select"
                value={activeCart}
                onChange={onChange}
                inputProps={{
                    "aria-label": "iBuy-cart-select-input",
                }}
                renderValue={inputPlaceholder}
                sx={{
                    width: "100%",
                    paddingTop: 0,
                    paddingBottom: 0,
                    "& .MuiSvgIcon-root": {
                        color: "common.darkTeal",
                    },
                }}
                MenuProps={{
                    MenuListProps: {
                        sx: borderSX,
                    },
                }}
            >
                {Object.keys(cartsData).map((cartId) => (
                    <MenuItem key={cartId} value={cartId}>
                        {cartsData[cartId].name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

IBuyCartSelector.propTypes = {
    cartsData: PropTypes.object.isRequired,
    activeCart: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default IBuyCartSelector;
