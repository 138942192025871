import notificationsClient from "../clients/notificationsClient";
import payslipsMapper from "../mappers/payslipsMapper";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import useUserProfile from "./useUserProfile";
import { PAYSLIPS } from "../../../constants/serviceNames";
import usePreferences from "./usePreferences";

const usePayslips = () => {
    const { servicesInMaintenanceMode, preferencesAreLoading } =
        usePreferences();
    const { throwAsyncError } = useErrorHandling();
    const { userProfileToken, userProfileIsLoading } = useUserProfile();

    const { data: latestPayslipsDate, error: payslipsError } = useSWR(
        () =>
            preferencesAreLoading || userProfileIsLoading
                ? null
                : "latestPayslipDate",
        async () => {
            if (
                servicesInMaintenanceMode &&
                !servicesInMaintenanceMode.find(
                    (service) => service === PAYSLIPS
                )
            ) {
                try {
                    const latestPayslipData =
                        await notificationsClient.getLatestPayslip({
                            "user-data-token": userProfileToken,
                        });
                    return payslipsMapper.mapAPIPayslipDataToDate(
                        latestPayslipData
                    );
                } catch (error) {
                    throwAsyncError(
                        ErrorTypes.PayslipsAPIError,
                        "Unable to fetch payslip data",
                        error
                    );
                    throw new Error("Unable to fetch payslip data");
                }
            }
        },
        { fallbackData: null }
    );

    return { latestPayslipsDate, errorMessage: payslipsError?.message };
};

export default usePayslips;
