import PropTypes from "prop-types";
import * as React from "react";
import QuickActionTile from "../templates/quickActionTile";
import SingleLinkTile from "../templates/singleLinkTile";
import { quickActionTilesMap } from "../tilesMap";
import { ERF_PORTAL } from "../../../constants/externalUrls";

const ErfPortalTile = ({ id }) => {
    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={ERF_PORTAL}
                linkText={quickActionTilesMap[id].description}
                title={quickActionTilesMap[id].name}
            />
        </QuickActionTile>
    );
};

ErfPortalTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ErfPortalTile;
