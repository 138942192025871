import React from "react";
import PropTypes from "prop-types";
import { iBuyMainURL } from "../../../services/apiUtils/config/urls";
import { PROCUREMENT } from "../../../constants/serviceNames";
import BaseRow from "../../tables/baseRow";
import SummaryRow from "../../tables/summaryRow";

const IBuyRequisitionRow = ({ item, isEditing }) => {
    const requisitionDetails = [
        {
            label: "Raised By",
            boldLabel: true,
            value: item.requester,
        },
        {
            label: "Description",
            boldLabel: true,
            value: item.description,
        },
        {
            label: "Supplier",
            boldLabel: true,
            value: item.supplierName,
        },
        {
            label: "Date created",
            boldLabel: true,
            value: item.createdDate,
        },
        {
            label: "Operating Unit",
            boldLabel: true,
            value: item.operatingUnit,
        },
        {
            label: "Total price",
            boldLabel: true,
            value: item.totalCost,
            boldValue: true,
            variant: "h5",
            component: "p",
            rowSX: { margin: "12px 0 0 0" },
        },
    ];

    return (
        <BaseRow itemDetails={requisitionDetails}>
            <SummaryRow
                item={item}
                service={PROCUREMENT}
                isEditing={isEditing}
                url={`${iBuyMainURL}/requisition-summary/${item.id}`}
            />
        </BaseRow>
    );
};

IBuyRequisitionRow.propTypes = {
    item: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

export default IBuyRequisitionRow;
