import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import { lcm } from "@sse-digital-services/design-system";

const useBreakpoints = () => {
    const breakpoints = useTheme().breakpoints.values;
    const theme = useTheme();
    let currentBreakpoint = "xs";
    currentBreakpoint = useMediaQuery(theme.breakpoints.only("sm"))
        ? "sm"
        : currentBreakpoint;
    currentBreakpoint = useMediaQuery(theme.breakpoints.only("md"))
        ? "md"
        : currentBreakpoint;
    currentBreakpoint = useMediaQuery(theme.breakpoints.only("lg"))
        ? "lg"
        : currentBreakpoint;
    currentBreakpoint = useMediaQuery(theme.breakpoints.only("xl"))
        ? "xl"
        : currentBreakpoint;

    const tilesPerBreakpoint = useMemo(
        () => ({
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
        }),
        []
    );

    const tasksPerBreakpoint = useMemo(
        () => ({
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
        }),
        []
    );

    const carouselBreakpoints = useMemo(() => {
        return Object.keys(breakpoints).reduce(
            (result, breakpoint) => ({
                ...result,
                [breakpoints[breakpoint]]: {
                    slidesPerView: tilesPerBreakpoint[breakpoint],
                    slidesPerGroup: tilesPerBreakpoint[breakpoint],
                },
            }),
            {}
        );
    }, [breakpoints, tilesPerBreakpoint]);

    const tasksNumGridColumns = useMemo(
        () => Object.values(tilesPerBreakpoint).reduce(lcm),
        [tilesPerBreakpoint]
    );

    const taskGridBreakpoints = useMemo(() => {
        return Object.keys(tilesPerBreakpoint).reduce(
            (result, breakpoint) => ({
                ...result,
                [breakpoint]:
                    tasksNumGridColumns / tilesPerBreakpoint[breakpoint],
            }),
            {}
        );
    }, [tilesPerBreakpoint, tasksNumGridColumns]);

    return {
        carouselBreakpoints,
        tasksNumGridColumns,
        taskGridBreakpoints,
        currentBreakpoint,
        tilesPerBreakpoint,
        tasksPerBreakpoint,
    };
};

export default useBreakpoints;
