import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import useSWR from "swr";
import integrationsClient from "../clients/integrationsClient";
import { setProcureToPayApprovalsTaskCount } from "../../../redux/actions/taskCountActions";
import { useDispatch } from "react-redux";
import serviceNowMapper from "../mappers/serviceNowMapper";
import useServiceNowUser from "./useServiceNowUser";
import { SERVICENOW } from "../../../constants/serviceNames";
import { useCallback, useMemo } from "react";

const useProcureToPayApprovals = () => {
    const dispatch = useDispatch();
    const {
        servicesInMaintenanceMode,
        servicesBasedOnPermissions,
        preferencesAreLoading,
    } = usePreferences();
    const { serviceNowUser, serviceNowUserIsLoading } = useServiceNowUser();
    const { throwAsyncError } = useErrorHandling();

    const {
        data: procureToPayApprovals,
        isLoading: procureToPayApprovalsDataIsLoading,
    } = useSWR(
        () =>
            preferencesAreLoading || serviceNowUserIsLoading
                ? null
                : "ProcureToPayApprovals",
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(SERVICENOW) &&
                    servicesInMaintenanceMode &&
                    serviceNowUser
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === SERVICENOW
                        )
                    ) {
                        const ptpApprovalsData =
                            await integrationsClient.getProcureToPayApprovals({
                                "sn-user-data-token": serviceNowUser,
                            });
                        const approvalsMapped =
                            serviceNowMapper.mapAPIPTPApprovalsToApprovals(
                                ptpApprovalsData
                            );
                        dispatch(
                            setProcureToPayApprovalsTaskCount(
                                approvalsMapped.length
                            )
                        );
                        return approvalsMapped;
                    }
                }
            } catch (error) {
                // Display message from API in case of 404 (user not found in ITSC)
                let message =
                    error?.response?.data?.errorCode === 404 ||
                    error?.response?.data?.errorCode === "SF-ITG-SN-400"
                        ? error?.response?.data?.detail
                        : "Unable to fetch Procure To Pay Approvals";
                throwAsyncError(
                    ErrorTypes.ServiceNowProcureToPayApprovalsError,
                    message,
                    error
                );
            }
        },
        { fallbackData: [] }
    );

    const ptpApprovalById = useCallback(
        (id) => {
            if (procureToPayApprovals) {
                const approval = procureToPayApprovals.find((approval) => {
                    return approval.id === id;
                });
                return approval ?? {};
            }
            return {};
        },
        [procureToPayApprovals]
    );

    const procureToPayApprovalsAreLoading = useMemo(
        () =>
            procureToPayApprovalsDataIsLoading ||
            serviceNowUserIsLoading ||
            preferencesAreLoading,
        [
            procureToPayApprovalsDataIsLoading,
            serviceNowUserIsLoading,
            preferencesAreLoading,
        ]
    );

    return {
        procureToPayApprovals,
        procureToPayApprovalsAreLoading,
        ptpApprovalById,
    };
};

export default useProcureToPayApprovals;
