import Box from "@mui/material/Box";
import React, { useRef } from "react";

import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ChatBotMessageDisplay from "./chatBotMessageDisplay";
import useSearchAssistant from "../../services/apiUtils/hooks/useSearchAssistant";
import { CHATBOT_ERROR_MESSAGE } from "../../constants/generalConstants";
import { ChevronRight } from "@sse-digital-services/design-system";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const ChatBotContent = () => {
    const { getChatResponse } = useSearchAssistant();
    const userName = useSelector((state) => state.auth.userName);
    const showChatField = useSelector((state) => state.general.showChatField);

    const [isLoading, setIsLoading] = React.useState(false);
    const [userInput, setUserInput] = React.useState("");
    const [conversation, setConversation] = React.useState([]);
    const [currentSessionId, setCurrentSessionId] = React.useState(uuidv4());
    const textFieldRef = useRef();

    const initialMessage = {
        role: "initial",
        content: `Hi ${
            userName ? userName.split(" ")[0] : ""
        }, I'm Edison. How can I help? You can ask me questions like, "Can you summarise our employee benefits for me?". As I am currently unable to distinguish what role, contract type, region or country (e.g. UK or ROI) you are in, please check the information source I provide is applicable. I am not a substitute for professional advice, so if you are in any doubt, please check with the relevant department before taking action.
       `,
        messageTime: new Date().toLocaleString("en-GB", {
            hour: "numeric",
            minute: "numeric",
            hourCycle: "h12",
        }),
    };
    const [displayConversation, setDisplayConversation] = React.useState([
        initialMessage,
    ]);

    const updateUserInput = (e) => {
        setUserInput(e.target.value);
    };
    React.useEffect(() => {
        showChatField && textFieldRef?.current.focus();
    }, [showChatField]);

    const updateConversation = (input, reset = false) => {
        const messageTime = new Date().toLocaleString("en-GB", {
            hour: "numeric",
            minute: "numeric",
            hourCycle: "h12",
        });
        if (reset) {
            setCurrentSessionId(uuidv4());
            setDisplayConversation((prevState) => [
                ...prevState,
                { ...input, messageTime },
            ]);
            setConversation([input]);
        } else {
            setDisplayConversation((prevState) => [
                ...prevState,
                { ...input, messageTime },
            ]);
            setConversation((prevState) => [...prevState, input]);
        }
    };

    const submitChat = () => {
        if (userInput !== "") {
            const input = { role: "user", content: userInput };
            updateConversation(input);
            setUserInput("");
        }
    };

    const sendChatRequest = async () => {
        setIsLoading(true);
        let formattedMessage = conversation
            .filter((message) => message.content !== CHATBOT_ERROR_MESSAGE)
            .map((item) => {
                return { role: item.role, content: item.content };
            });
        const chatResponse = await getChatResponse({
            messages: formattedMessage,
            id: currentSessionId,
        });
        updateConversation(chatResponse.data, chatResponse.reset);
        setIsLoading(false);
    };

    React.useEffect(() => {
        const fetchChat = async () => {
            const lastMessage = displayConversation.slice(-1)[0];
            if (lastMessage && lastMessage.role === "user") {
                await sendChatRequest();
            }
        };
        fetchChat();
    }, [displayConversation]);

    return (
        <>
            <ChatBotMessageDisplay
                messages={displayConversation}
                isLoading={isLoading}
            />
            <Box
                height="75px"
                m={"20px 20px 23px 20px"}
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
                <Box
                    p="0px 15px 5px 15px"
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography>Type your response</Typography>
                    <Typography>{userInput.length}/256</Typography>
                </Box>
                <TextField
                    inputRef={textFieldRef}
                    key={"chat bot submit"}
                    placeholder="Start Typing..."
                    value={userInput}
                    focused
                    fullWidth
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "text.emphasis",
                            },
                        },
                    }}
                    inputProps={{
                        "aria-label": "chat message input",
                        maxLength: 256,
                    }}
                    InputProps={{
                        style: { borderRadius: "1px" },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    data-track-id="submit chat"
                                    aria-label="submit chat"
                                    edge="end"
                                    onClick={submitChat}
                                >
                                    <ChevronRight
                                        sx={{
                                            color: "text.emphasis",
                                            borderRadius: "50%",
                                            border: "2px solid",
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => updateUserInput(e)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") submitChat();
                    }}
                />
            </Box>
        </>
    );
};

export default ChatBotContent;
