import { alpha, styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import { CopyToClipboardButton } from "./copyToClipboardButton";
import {
    StyledTooltip,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";

const chatMessageSx = {
    wordBreak: "break-word",
    justifyContent: "space-between",
    alignItems: "flex-end",
    p: "10px",
    width: "calc(100% - 60px)",
    flexDirection: "row",
    display: "flex",
    color: "text.contrastText",
};

const copyMessageButtonSx = {
    width: "20px",
    height: "20px",
    color: "text.contrastText",
};

const MarkdownStyled = styled(Markdown)(({ theme }) => ({
    a: { color: theme.palette.text.contrastText, fontWeight: "400" },
}));

const TextMessageUser = ({ content, userIcon, messageTime }) => {
    const theme = useTheme();
    const { borderSX } = useHighContrastBorderSX();
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Typography>
                    {messageTime} <b>You</b>
                </Typography>
                {userIcon}
            </Box>
            <Box
                sx={{
                    ...borderSX,
                    ...chatMessageSx,
                    mr: "60px",
                    flexDirection: "row-reverse",
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                }}
            >
                <Typography variant={"body1"} component="p" maxWidth={"80%"}>
                    {content}
                </Typography>
            </Box>
        </>
    );
};

TextMessageUser.propTypes = {
    content: PropTypes.node,
    userIcon: PropTypes.node,
    messageTime: PropTypes.string,
};

const TextMessageBot = ({
    content,
    messageTime,
    citations,
    defaultMessage,
}) => {
    const theme = useTheme();
    const { borderSX } = useHighContrastBorderSX();
    const messageFormattedToCopy =
        content +
        (citations && citations.length > 0
            ? `\nSources: \n${citations
                  .map(
                      (citation, index) =>
                          `SSE Source ${index + 1}: ${citation.url}`
                  )
                  .join("\n")}`
            : "");
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <LightbulbOutlinedIcon
                    sx={{
                        width: "50px",
                        height: "50px",
                        mr: "10px",
                        color: "icon.secondary",
                    }}
                />

                <Typography>
                    <b>Edison</b> {messageTime}
                </Typography>
            </Box>
            <Box
                sx={{
                    ...borderSX,
                    ...chatMessageSx,
                    backgroundColor: alpha(theme.palette.primary.dark, 0.03),
                    ml: "60px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        width: "80%",
                    }}
                >
                    <Typography
                        variant={"body1"}
                        component={"span"}
                        width={"100%"}
                    >
                        <MarkdownStyled
                            skipHtml
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[
                                [rehypeExternalLinks, { target: "_blank" }],
                            ]}
                        >
                            {content}
                        </MarkdownStyled>
                    </Typography>
                    {citations?.length > 0 && (
                        <Typography
                            mt={"10px"}
                            fontSize={"11px"}
                            color={"text.primary"}
                        >
                            Reference Sources:&nbsp;
                            {citations.map((citation, index) => (
                                <StyledTooltip
                                    title={citation.title}
                                    key={citation.url}
                                >
                                    <Link
                                        data-track-id={`chat citation ${citation.url}`}
                                        href={citation.url}
                                        target="_blank"
                                        underline="hover"
                                        color="text.emphasis"
                                        fontSize={"11px"}
                                    >
                                        {`${index > 0 ? ", " : ""} SSE Source ${
                                            index + 1
                                        }`}
                                    </Link>
                                </StyledTooltip>
                            ))}
                        </Typography>
                    )}
                    {!defaultMessage && (
                        <Typography
                            fontSize={"10px"}
                            variant={"body5"}
                            color={"text.primary"}
                            sx={{ width: "100%" }}
                        >
                            AI-Generated content may be incorrect.
                        </Typography>
                    )}
                </Box>
                {!defaultMessage && (
                    <CopyToClipboardButton
                        toCopy={messageFormattedToCopy}
                        buttonSX={copyMessageButtonSx}
                    />
                )}
            </Box>
        </>
    );
};

TextMessageBot.propTypes = {
    content: PropTypes.node,
    messageTime: PropTypes.string,
    citations: PropTypes.array,
    defaultMessage: PropTypes.bool,
};

const TextMessage = ({ role, content, userIcon, messageTime, citations }) => {
    return (
        <Box my={"10px"}>
            {role === "user" ? (
                <TextMessageUser
                    content={content}
                    userIcon={userIcon}
                    messageTime={messageTime}
                />
            ) : (
                <TextMessageBot
                    content={content}
                    messageTime={messageTime}
                    citations={citations}
                    defaultMessage={role === "initial"}
                />
            )}
        </Box>
    );
};

TextMessage.propTypes = {
    role: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    userIcon: PropTypes.node.isRequired,
    messageTime: PropTypes.string.isRequired,
    citations: PropTypes.array,
};

export default TextMessage;
