import { initialState } from "../initialState";
import {
    SET_DEFERRED_INSTALL_PROMPT,
    SET_SHOW_INSTALL_DIALOG,
} from "../actionTypes";

const pwaReducer = (state = initialState.pwa, action) => {
    switch (action.type) {
        case SET_DEFERRED_INSTALL_PROMPT:
            return {
                ...state,
                deferredPrompt: action.payload,
            };
        case SET_SHOW_INSTALL_DIALOG:
            return {
                ...state,
                showInstallDialog: action.payload,
            };
        default:
            return state;
    }
};

export default pwaReducer;
