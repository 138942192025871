import PropTypes from "prop-types";
import * as React from "react";
import QuickActionTile from "../templates/quickActionTile";
import SingleLinkTile from "../templates/singleLinkTile";
import { quickActionTilesMap } from "../tilesMap";
import { SPOTLIGHT } from "../../../constants/externalUrls";

const SpotlightTile = ({ id }) => {
    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={SPOTLIGHT}
                linkText={quickActionTilesMap[id].description}
                title={"SSE Spotlight"}
            />
        </QuickActionTile>
    );
};

SpotlightTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default SpotlightTile;
