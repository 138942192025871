import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import avatarClient from "../clients/avatarClient";
import imageMapper from "../mappers/imageMapper";
import useSWR from "swr";

const useAvatarImage = () => {
    const { throwAsyncError } = useErrorHandling();

    const { data: avatarImage } = useSWR(
        "avatarImage",
        async () => {
            try {
                const imageBinary = await avatarClient.getAvatar();
                return imageMapper.mapAPIImageToImageString(imageBinary);
            } catch (error) {
                // Skip 404 errors as they only imply a user has not uploaded an image
                if (error.response?.status !== 404) {
                    throwAsyncError(
                        ErrorTypes.GraphAPIError,
                        "Unable to fetch user avatar",
                        error
                    );
                }
            }
        },
        { fallbackData: "" }
    );

    return { avatarImage };
};

export default useAvatarImage;
