import {
    PROCURE_TO_PAY_APPROVALS,
    PROCURE_TO_PAY_REQUEST_ITEMS,
    SERVICE_NOW_APPROVALS,
} from "../../../constants/taskNames";
import { PROCURETOPAY, SERVICENOW } from "../../../constants/serviceNames";

const serviceNowMapper = {
    mapAPIApprovalsToApprovals: (apiApprovals) => {
        if (apiApprovals.data) {
            return apiApprovals.data.map((apiApproval) => ({
                id: apiApproval.request_number,
                requester: apiApproval.opened_by,
                requestedFor: apiApproval.requested_for,
                createdDate: apiApproval.sys_created_on,
                updatedDate: apiApproval.sys_updated_on,
                supplier: apiApproval.supplierName,
                url: apiApproval.url,
                taskType: SERVICE_NOW_APPROVALS,
                service: SERVICENOW,
            }));
        }
        return [];
    },
    mapAPIPTPApprovalsToApprovals: (apiApprovals) => {
        if (apiApprovals.data) {
            return apiApprovals.data.map((apiApproval) => ({
                id: apiApproval.number,
                requester: apiApproval.opened_by,
                requestedFor: apiApproval.caller_id,
                createdDate: apiApproval.sys_created_on,
                updatedDate: apiApproval.sys_updated_on,
                latestComment:
                    apiApproval?.latest_comment?.length > 80
                        ? `${apiApproval.latest_comment.substring(0, 80)}...`
                        : apiApproval?.latest_comment,
                commentBy: apiApproval.comment_by,
                url: apiApproval.url,
                taskType: PROCURE_TO_PAY_APPROVALS,
                service: PROCURETOPAY,
            }));
        }
        return [];
    },
    mapAPIPTPRequestItemTasksToTasks: (apiTasks) => {
        if (apiTasks.data) {
            return apiTasks.data.map((apiTask) => ({
                id: apiTask.number,
                requester: apiTask.opened_by,
                requestedFor: apiTask.requested_for,
                createdDate: apiTask.sys_created_on,
                updatedDate: apiTask.sys_updated_on,
                latestComment:
                    apiTask?.latest_comment?.length > 80
                        ? `${apiTask.latest_comment.substring(0, 80)}...`
                        : apiTask?.latest_comment,
                commentBy: apiTask.comment_by,
                url: apiTask.url,
                taskType: PROCURE_TO_PAY_REQUEST_ITEMS,
                service: PROCURETOPAY,
            }));
        }
        return [];
    },
    mapAPIIncidentsToIncidents: (apiIncidents) => {
        if (apiIncidents.count && apiIncidents.data) {
            return {
                count: apiIncidents.count,
                data: apiIncidents.data.map((apiIncident) => ({
                    id: apiIncident.number,
                    urgency: apiIncident.urgency,
                    displayState: apiIncident.incident_state,
                    createdDate: apiIncident.sys_created_on,
                    updatedDate: apiIncident.sys_updated_on,
                    url: apiIncident.url,
                    description: apiIncident.description,
                    affectedUser: apiIncident.caller_id,
                })),
            };
        }
        return { count: 0, data: [] };
    },
    mapAPIPtpIncidentsToIncidents: (apiPtpIncidents) => {
        if (apiPtpIncidents.count && apiPtpIncidents.data) {
            return {
                count: apiPtpIncidents.count,
                data: apiPtpIncidents.data.map((apiIncident) => ({
                    id: apiIncident.number,
                    url: apiIncident.url,
                    description: apiIncident.description,
                    displayState: apiIncident.incident_state,
                    createdDate: apiIncident.sys_created_on,
                    updatedDate: apiIncident.sys_updated_on,
                })),
            };
        }
        return { count: 0, data: [] };
    },
    mapAPIARequestsToRequests: (apiRequests) => {
        if (apiRequests.count && apiRequests.data) {
            return {
                count: apiRequests.count,
                data: apiRequests.data.map((apiRequest) => ({
                    id: apiRequest.number,
                    approval: apiRequest.approval,
                    priority: apiRequest.priority,
                    displayState: apiRequest.request_state,
                    createdDate: apiRequest.sys_created_on,
                    updatedDate: apiRequest.sys_updated_on,
                    url: apiRequest.url,
                    externalId: apiRequest.sys_id,
                })),
            };
        }
        return { count: 0, data: [] };
    },
};

export default serviceNowMapper;
