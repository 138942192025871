import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

// The API decrypts encrypted data and might take longer than others
const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
    timeout: 30000,
};

const edisonReportingAPIService = apiService(
    apiConfig.edisonReporting,
    apiHeaderConfig
);

const edisonReportingClient = {
    getReport: (params) => edisonReportingAPIService.get("report", params),
};

export default edisonReportingClient;
