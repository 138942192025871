import { quickActionTilesMap } from "./tilesMap";
import React, { useMemo } from "react";
import useHomePage from "../pages/homepage/useHomepage";
import useBreakpoints from "../../utils/useBreakpoints";
import {
    CardCarousel,
    ChevronRight,
} from "@sse-digital-services/design-system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DashboardTile from "./templates/dashboardTile";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import {
    setIsEditing,
    setSelectedCustomisationTab,
} from "../../redux/actions/generalActions";
import { QUICK_LINKS_PER_COL } from "../../constants/tileGridConstants";
import SearchField from "../headerBar/searchField";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

const QuickActionsAreaTile = ({ id, transform, addRectGetter }) => {
    const { quickActionTileCollection } = useHomePage();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { carouselBreakpoints } = useBreakpoints();
    const [searchTerm, setSearchTerm] = React.useState("");

    const createQuickActionTile = (tileIds) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {tileIds.map((tileId) => {
                    const Tile = quickActionTilesMap[tileId].component;
                    return (
                        <Box
                            key={`quick-action-wrapper-${tileId}`}
                            sx={{ position: "relative" }}
                        >
                            <Tile
                                id={tileId}
                                key={`quick-action-card-${tileId}`}
                            />
                        </Box>
                    );
                })}
            </Box>
        );
    };

    const quickActionCards = useMemo(() => {
        if (quickActionTileCollection?.currentTileOrder) {
            const filteredTiles =
                searchTerm === ""
                    ? quickActionTileCollection.currentTileOrder
                    : quickActionTileCollection.currentTileOrder
                          .concat(
                              quickActionTileCollection.currentHiddenTileIds
                          )
                          .filter(
                              (tileId) =>
                                  quickActionTilesMap[tileId].name
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase()) ||
                                  quickActionTilesMap[tileId].description
                                      ?.toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                          );

            return filteredTiles
                .map((tileId, index) => {
                    if (index % QUICK_LINKS_PER_COL === 0) {
                        return filteredTiles.slice(
                            index,
                            index + QUICK_LINKS_PER_COL
                        );
                    } else {
                        return undefined;
                    }
                })
                .filter((result) => !!result)
                .map((tileIds) => createQuickActionTile(tileIds));
        } else {
            return [];
        }
    }, [quickActionTileCollection.currentTileOrder, searchTerm]);

    const openQuickLinksCustomisation = () => {
        dispatch(setSelectedCustomisationTab(1));
        dispatch(setIsEditing(true));
    };

    return (
        <DashboardTile
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            id={id}
            tileColor={theme.palette.background.paper}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <Grid container>
                <Grid
                    item
                    lg={6}
                    xs={6}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        variant="h2"
                        component="p"
                        color="text.primary"
                        textAlign="left"
                        alignItems="center"
                    >
                        Quick links
                    </Typography>
                    <Button
                        data-track-id={"edit-your-links"}
                        aria-label={"Edit your links"}
                        variant="standard"
                        endIcon={
                            <ChevronRight
                                fontSize={"large"}
                                sx={{
                                    borderRadius: "50%",
                                    border: "2px solid",
                                }}
                            />
                        }
                        sx={{
                            px: "0px",
                            minWidth: "60px",
                            width: "150px",
                            color: "text.emphasis",
                            textTransform: "none",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            outline: "revert",
                        }}
                        onClick={openQuickLinksCustomisation}
                        disableRipple={true}
                        disableTouchRipple={true}
                    >
                        <Typography variant={"h6"} component="p">
                            Edit your links
                        </Typography>
                    </Button>
                </Grid>
                <Grid
                    item
                    lg={6}
                    xs={6}
                    alignItems={"center"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                >
                    <SearchField
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        boxSx={{ my: "0px", width: "250px" }}
                    />
                </Grid>
            </Grid>
            {quickActionTileCollection.currentTileOrder &&
            quickActionTileCollection.currentTileOrder.length > 0 ? (
                <Box>
                    <CardCarousel
                        cards={quickActionCards}
                        id="quick action cards"
                        breakpoints={carouselBreakpoints}
                    />
                </Box>
            ) : (
                <Typography color="text.primary">
                    No quick links to display
                </Typography>
            )}
        </DashboardTile>
    );
};

QuickActionsAreaTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default QuickActionsAreaTile;
