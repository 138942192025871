import preferencesClient from "../clients/preferencesClient";
import preferencesMapper, {
    filterPreferencesServices,
} from "../mappers/preferencesMapper";
import { useMemo } from "react";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import { useMediaQuery } from "@mui/material";
import {
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
} from "@sse-digital-services/design-system";
import { desktopOnlyTiles } from "components/tiles/tilesMap";
import { ADMIN, EDISON_ADMIN, MANAGER } from "../../../constants/serviceNames";

const usePreferences = () => {
    const { throwAsyncError } = useErrorHandling();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const { handheld } = getDeviceInfo();

    const {
        data: shopfrontPreferences,
        mutate: refreshShopfrontPreferences,
        isLoading: preferencesAreLoading,
    } = useSWR("shopfrontPreferences", async () => {
        try {
            return await preferencesClient.getShopfrontPreferences();
        } catch (error) {
            throwAsyncError(
                ErrorTypes.FetchPreferencesAPIError,
                "Unable to fetch user preferences",
                error
            );
        }
    });

    const servicesBasedOnPermissions = useMemo(() => {
        if (shopfrontPreferences) {
            return filterPreferencesServices(shopfrontPreferences);
        }
        return [];
    }, [shopfrontPreferences]);

    const tilePreferences = useMemo(() => {
        if (shopfrontPreferences) {
            const tilePreferences =
                preferencesMapper.mapShopfrontPreferencesToTilePreferences(
                    shopfrontPreferences
                );
            if (!isDesktopWidth || handheld) {
                desktopOnlyTiles.forEach((tile) => {
                    delete tilePreferences[tile];
                });
            }
            return tilePreferences;
        }
        return null;
    }, [shopfrontPreferences]);

    const notificationPreferences = useMemo(() => {
        if (shopfrontPreferences) {
            return preferencesMapper.mapShopfrontPreferencesToNotificationPreferences(
                shopfrontPreferences
            );
        }
        return null;
    }, [shopfrontPreferences]);

    const servicesInMaintenanceMode = useMemo(() => {
        if (shopfrontPreferences && servicesBasedOnPermissions) {
            return preferencesMapper.mapShopfrontPreferencesToServiceStatuses(
                shopfrontPreferences,
                servicesBasedOnPermissions
            );
        }
        return null;
    }, [shopfrontPreferences, servicesBasedOnPermissions]);

    const updateTilePreferences = async (newTilePreferences) => {
        try {
            const mostRecentPreferences = await refreshShopfrontPreferences();
            const updatedPreferences =
                preferencesMapper.mapTilePreferenceUpdateToShopfrontPreferences(
                    newTilePreferences,
                    mostRecentPreferences
                );
            await preferencesClient.updateShopfrontPreferences(
                updatedPreferences
            );
            // Updating the cache directly without an API call, no need to await
            refreshShopfrontPreferences(updatedPreferences);
        } catch (error) {
            throwAsyncError(
                ErrorTypes.PostPreferencesAPIError,
                "Unable to update your preferences - your changes may not have been saved",
                error
            );
        }
    };

    const updateNotificationPreferences = async (
        newNotificationPreferences
    ) => {
        try {
            const mostRecentPreferences = await refreshShopfrontPreferences();
            const updatedPreferences =
                preferencesMapper.mapNotificationPreferenceUpdateToShopfrontPreferences(
                    newNotificationPreferences,
                    mostRecentPreferences
                );
            await preferencesClient.updateShopfrontPreferences(
                updatedPreferences
            );
            // Updating the cache directly without an API call, no need to await
            refreshShopfrontPreferences(updatedPreferences);
        } catch (error) {
            throwAsyncError(
                ErrorTypes.PostPreferencesAPIError,
                "Unable to update your preferences - your changes may not have been saved",
                error
            );
        }
    };

    const isManager = servicesBasedOnPermissions?.includes(MANAGER);
    const isAdmin = servicesBasedOnPermissions?.includes(ADMIN);
    const isEdisonAdmin = servicesBasedOnPermissions?.includes(EDISON_ADMIN);

    return {
        preferencesAreLoading,
        tilePreferences,
        notificationPreferences,
        servicesInMaintenanceMode,
        refreshShopfrontPreferences,
        servicesBasedOnPermissions,
        updateTilePreferences,
        updateNotificationPreferences,
        isManager,
        isAdmin,
        isEdisonAdmin,
    };
};

export default usePreferences;
