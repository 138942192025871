import React, { useState } from "react";
import { IconButton, InputBase, useTheme } from "@mui/material";
import { iBuyMainURL } from "../../../services/apiUtils/config/urls";
import { textInputFieldSX } from "../../../styled/styledSXProperties/styledUserInput";
import PropTypes from "prop-types";
import { Search } from "@sse-digital-services/design-system";
import Paper from "@mui/material/Paper";

const IBuySearch = ({ isEditing }) => {
    const theme = useTheme();
    const [searchInput, setSearchInput] = useState("");

    const submitSearch = () => {
        const searchParams = new URLSearchParams();
        searchParams.set("search_query", searchInput);
        window.open(
            `${iBuyMainURL}/${encodeURI(
                "product-results" + "?" + searchParams
            )}`,
            "_blank"
        );
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            submitSearch();
        }
    };

    return (
        <Paper
            elevation={0}
            variant="outlined"
            sx={{
                margin: "16px 8px",
                borderRadius: "1px",
                borderColor: "common.black",
                backgroundColor: "common.white",
                display: "flex",
                alignItems: "center",
            }}
        >
            <InputBase
                inputProps={{
                    "aria-label": "search term input",
                    maxLength: 50,
                }}
                placeholder={"Search iBuy"}
                value={searchInput}
                disabled={isEditing}
                onChange={(event) => setSearchInput(event.target.value)}
                onKeyDown={handleKeyDown}
                sx={textInputFieldSX}
            />
            <IconButton
                aria-label="Search in iBuy"
                onClick={submitSearch}
                disabled={isEditing}
                sx={{
                    color: theme.palette.primary.dark,
                    backgroundColor: "common.white",
                    "&.Mui-disabled": {
                        backgroundColor: "disabled.main",
                    },
                }}
            >
                <Search />
            </IconButton>
        </Paper>
    );
};

IBuySearch.propTypes = {
    isEditing: PropTypes.bool.isRequired,
};

export default IBuySearch;
