import {
    SET_DEFERRED_INSTALL_PROMPT,
    SET_SHOW_INSTALL_DIALOG,
} from "../actionTypes";

export const setDeferredInstallPrompt = (deferredInstallPrompt) => {
    return {
        type: SET_DEFERRED_INSTALL_PROMPT,
        payload: deferredInstallPrompt,
    };
};

export const setShowInstallDialog = (showInstallDialog) => {
    return { type: SET_SHOW_INSTALL_DIALOG, payload: showInstallDialog };
};
