import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
    timeout: 0,
};

const documentProcessorAPIService = apiService(
    apiConfig.documentProcessor,
    apiHeaderConfig
);

const documentProcessorAPIServiceNoResponseNeeded = apiService(
    apiConfig.documentProcessor,
    {
        headers: {
            "api-version": "V1",
        },
    }
);

const documentProcessorClient = {
    postScrape: (request) =>
        documentProcessorAPIService.post("scrape", request),
    postScrapeAll: () =>
        documentProcessorAPIServiceNoResponseNeeded.post("scrape"),
    postDelete: (request) =>
        documentProcessorAPIService.post("delete", request),
    getTimestamp: () => documentProcessorAPIService.get("timestamp"),
};

export default documentProcessorClient;
