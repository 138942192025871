import { LXP } from "constants/serviceNames";
import { LMS_LEARNINGS } from "constants/taskNames";

const lmsMapper = {
    mapAPICoursesToCourses(coursesData) {
        if (coursesData.count && coursesData.courses) {
            return {
                count: coursesData.count,
                data: coursesData.courses.map((course) => ({
                    id: course.CourseTitle,
                    displayState: course.Status,
                    progress: course.Progress,
                    url: course.DeepLink.replace("https://", ""),
                    dueDate: course.DueDate,
                    requester: course.AssignedTo, // for LMS there is no requester, so its always assigned to displayed in task filter
                    courseId: course.Id.toString(),
                    assignedDate: course.AssignedDate,
                    type: course.Type,
                    taskType: LMS_LEARNINGS,
                    createdDate: course.CreatedDate,
                    service: LXP,
                })),
            };
        }
        return { count: 0, data: [] };
    },
};

export default lmsMapper;
