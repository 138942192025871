import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import useIBuyCarts from "services/apiUtils/hooks/useIBuyCarts";
import { iBuyMainURL } from "services/apiUtils/config/urls";
import IBuyCartSelector from "./iBuyCartSelector";
import IBuyCartDetails from "./iBuyCartDetails";
import useDashboardTiles from "../useDashboardTiles";
import DashboardTile from "../templates/dashboardTile";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import { buttonSX } from "styled/styledSXProperties/styledUserInput";

const IBuyCartsTile = ({ id, transform, addRectGetter }) => {
    const { isEditing } = useDashboardTiles();
    const theme = useTheme();
    const { iBuyCarts, errorMessage } = useIBuyCarts();
    const { borderSX } = useHighContrastBorderSX();

    const [activeCart, setActiveCart] = useState();

    const changeCart = (event) => {
        setActiveCart(event.target.value);
    };

    useEffect(() => {
        if (iBuyCarts) {
            setActiveCart(Object.keys(iBuyCarts)[0]);
        }
    }, [iBuyCarts, setActiveCart]);

    return (
        <DashboardTile
            id={id}
            tileColor={theme.palette.background.paper}
            errorMessage={errorMessage}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <Typography
                sx={{ margin: "0 0 20px 0" }}
                color="text.primary"
                variant="h2"
                component="p"
                textAlign="left"
                alignItems="center"
            >
                Your recent iBuy carts
            </Typography>
            {iBuyCarts && activeCart && Object.keys(iBuyCarts).length ? (
                <>
                    <Grid
                        container
                        sx={{
                            ml: "0px",
                            padding: "0px",
                            display: "flex",
                        }}
                    >
                        <Grid
                            item
                            xs={6}
                            align={"left"}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <IBuyCartSelector
                                cartsData={iBuyCarts ?? {}}
                                activeCart={activeCart}
                                onChange={changeCart}
                                disabled={isEditing}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                href={`${iBuyMainURL}/cart/${activeCart}`}
                                target="_blank"
                                disabled={isEditing}
                                variant="contained"
                                sx={{
                                    ...borderSX,
                                    ...buttonSX(theme),
                                    height: "43px",
                                    m: 1,
                                    textTransform: "none",
                                }}
                            >
                                <Typography variant="h6" component="p">
                                    View in iBuy
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <IBuyCartDetails
                        cartsData={iBuyCarts}
                        activeCart={activeCart}
                    />
                </>
            ) : (
                <Typography
                    sx={{ margin: "5px" }}
                    color="text.primary"
                    variant="body1"
                    component="p"
                >
                    No carts available
                </Typography>
            )}
        </DashboardTile>
    );
};

IBuyCartsTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default IBuyCartsTile;
