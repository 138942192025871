import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import * as PropTypes from "prop-types";
import { styledCarouselCardSX } from "../../styled/styledSXProperties/styledDashboardTileCardSX";
import CardHeader from "@mui/material/CardHeader";
import BaseTaskButton from "./baseTaskButton";
import { ActionWrapper, CardTitle } from "./baseTaskCard";

const DashboardTaskCard = ({ title, fields, action, children }) => {
    return (
        <Card
            sx={{
                ...styledCarouselCardSX,
                padding: "16px 24px 24px 24px",
                justifyContent: "flex-start",
            }}
            data-track-id="task card"
        >
            <CardHeader
                sx={{ padding: "8px 0px 0px" }}
                title={<CardTitle title={title} />}
            />
            <ActionWrapper action={action} isDashboard={true}>
                <CardContent
                    sx={{
                        padding: "0px",
                        "&:last-child": { paddingBottom: "0px" },
                    }}
                >
                    {fields.map((row) => (
                        <React.Fragment key={`${row.field}-row`}>
                            {row.action ? (
                                <BaseTaskButton row={row} />
                            ) : (
                                <Typography
                                    color={"text.primary"}
                                    sx={{ paddingY: "2px", paddingX: "0px" }}
                                >
                                    {`${row.field}: ${row.value}`}
                                </Typography>
                            )}
                        </React.Fragment>
                    ))}
                </CardContent>
            </ActionWrapper>
            {children}
        </Card>
    );
};

DashboardTaskCard.propTypes = {
    title: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    action: PropTypes.func,
    children: PropTypes.node,
};

export default DashboardTaskCard;
