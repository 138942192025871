import { Typography, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import useDashboardTiles from "../useDashboardTiles";
import Divider from "@mui/material/Divider";
import DashboardTile from "../templates/dashboardTile";
import usePreferences from "../../../services/apiUtils/hooks/usePreferences";
import {
    PLANONFM,
    PROCUREMENT,
    SERVICENOW,
} from "../../../constants/serviceNames";
import ServiceNowRequestsSection from "./serviceNowRequestsSection";
import IBuyRequisitionsSection from "./iBuyRequisitionsSection";
import PlanonFMSection from "./planonFMSection";
import Box from "@mui/material/Box";
import TileFilterButton from "../../buttons/tileFilterButton";

const RequestsTile = ({ id, transform, addRectGetter }) => {
    const theme = useTheme();
    const [viewClosed, setViewClosed] = React.useState(false);

    const { isEditing } = useDashboardTiles();
    const { servicesInMaintenanceMode } = usePreferences();
    const { servicesBasedOnPermissions } = usePreferences();

    const handleFilterButtonClick = () => {
        setViewClosed(!viewClosed);
    };

    return (
        <DashboardTile
            id={id}
            tileColor={theme.palette.background.paper}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{ margin: "0 0 20px 0" }}
            >
                <Typography
                    color="text.primary"
                    variant="h2"
                    component="p"
                    textAlign="left"
                    alignItems="center"
                >
                    Requests
                </Typography>
                <TileFilterButton
                    handleClick={handleFilterButtonClick}
                    viewClosed={viewClosed}
                />
            </Box>
            <Divider sx={{ borderBottomWidth: 1, m: "0px" }} variant="middle" />
            {servicesBasedOnPermissions?.includes(SERVICENOW) && (
                <ServiceNowRequestsSection
                    isEditing={isEditing}
                    maintenanceMode={servicesInMaintenanceMode?.includes(
                        SERVICENOW
                    )}
                    viewClosed={viewClosed}
                />
            )}
            {servicesBasedOnPermissions?.includes(PROCUREMENT) && (
                <IBuyRequisitionsSection
                    isEditing={isEditing}
                    maintenanceMode={servicesInMaintenanceMode?.includes(
                        PROCUREMENT
                    )}
                />
            )}
            {servicesBasedOnPermissions?.includes(PLANONFM) && (
                <PlanonFMSection
                    isEditing={isEditing}
                    maintenanceMode={servicesInMaintenanceMode?.includes(
                        PLANONFM
                    )}
                />
            )}
        </DashboardTile>
    );
};

RequestsTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default RequestsTile;
