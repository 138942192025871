import PropTypes from "prop-types";
import * as React from "react";
import { SSE_NET_HR_HUB } from "../../../constants/externalUrls";
import QuickActionTile from "../templates/quickActionTile";
import SingleLinkTile from "../templates/singleLinkTile";
import { quickActionTilesMap } from "../tilesMap";

const HRHubTile = ({ id }) => {
    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={SSE_NET_HR_HUB}
                linkText={quickActionTilesMap[id].description}
                title={"HR Hub"}
            />
        </QuickActionTile>
    );
};

HRHubTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default HRHubTile;
