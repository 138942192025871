import notificationsClient from "../clients/notificationsClient";
import { useEffect, useMemo } from "react";
import notificationsMapper from "../mappers/notificationsMapper";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import useSWRInfinite from "swr/infinite";
import { NOTIFICATION_COUNT_PER_PAGE } from "../../../constants/generalConstants";
import { useDispatch, useSelector } from "react-redux";
import useUserProfile from "./useUserProfile";
import { triggerSnackbar } from "utils/alertsUtils";

const useNotifications = () => {
    const taskCount = useSelector((state) => state.taskCount);
    const dispatch = useDispatch();
    const { throwAsyncError } = useErrorHandling();
    const {
        notificationPreferences,
        servicesInMaintenanceMode,
        preferencesAreLoading,
    } = usePreferences();
    const { userProfileToken, getTokenExpiryTSinMS } = useUserProfile();
    const servicesByPreference =
        notificationPreferences && !preferencesAreLoading
            ? Object.keys(notificationPreferences)
                  .filter((service) => notificationPreferences[service])
                  .sort()
            : null;

    const getCacheKey = (pageIndex) => {
        if (
            !servicesByPreference ||
            !userProfileToken ||
            getTokenExpiryTSinMS(userProfileToken) < Date.now()
        ) {
            return null;
        }
        return ["notifications", pageIndex, ...servicesByPreference];
    };

    const {
        data: notificationsData,
        size: lastPageIndex,
        setSize: setPageIndex,
        mutate: refreshNotifications,
        isLoading: notificationsAreLoading,
    } = useSWRInfinite(
        getCacheKey,
        async (args) => {
            const params = {
                limit: NOTIFICATION_COUNT_PER_PAGE,
                offset: args[1] * NOTIFICATION_COUNT_PER_PAGE,
                services: args.slice(2),
            };
            try {
                const newNotifications =
                    await notificationsClient.getNotifications(params, {
                        "user-data-token": userProfileToken,
                    });
                return notificationsMapper.mapNotificationsWithMaintenanceMode(
                    newNotifications,
                    servicesInMaintenanceMode
                );
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.NotificationsAPIError,
                    "Unable to fetch notifications",
                    error
                );
            }
        },
        { fallbackData: [], parallel: true, refreshInterval: 10 * 60 * 1000 }
    );

    const notifications = useMemo(() => {
        if (notificationsData.length > 0) {
            return notificationsData.reduce(
                (result, notificationPage) => {
                    if (notificationPage)
                        return {
                            ...notificationPage,
                            data: [...result.data, ...notificationPage.data],
                        };
                    else return result;
                },
                {
                    data: [],
                    total_count: 0,
                    unread_count: 0,
                }
            );
        }
        return {
            data: [],
            total_count: 0,
            unread_count: 0,
        };
    }, [notificationsData]);

    const updateNotificationsStatus = async () => {
        if (
            servicesByPreference &&
            notifications &&
            notifications.data.length > 0
        ) {
            const latestNotification = notifications.data[0];
            const request = {
                latest_notification: [latestNotification],
                services: servicesByPreference,
            };

            try {
                await notificationsClient.updateNotificationStatus(request);
                const updatedNotificationsData =
                    notificationsMapper.mapNotificationStatus(
                        notificationsData
                    );
                refreshNotifications([...updatedNotificationsData]);
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.NotificationsAPIError,
                    "Unable to update notification status",
                    error
                );
            }
        }
    };

    const submitAdminNotification = async (adminNotification) => {
        try {
            const notificationToSubmit =
                notificationsMapper.mapAdminNotification(adminNotification);
            await notificationsClient.postAdminNotification(
                notificationToSubmit
            );
            await refreshNotifications();
            triggerSnackbar(
                "success",
                "Notification submitted",
                null,
                dispatch
            );
        } catch (error) {
            throwAsyncError(
                ErrorTypes.NPSAPIError,
                "Unable to submit notification. There may be an error with the notification text or url. Try using fewer special characters.",
                error
            );
        }
    };

    useEffect(() => {
        (async () => {
            let badgeCount = Object.values(taskCount).reduce(
                (a, b) => a + b,
                0
            );
            if (navigator && "setAppBadge" in navigator) {
                if (notifications?.unread_count) {
                    navigator.setAppBadge(
                        notifications.unread_count + badgeCount
                    );
                } else {
                    navigator.setAppBadge(badgeCount);
                }
            }
        })();
    }, [notifications, taskCount]);

    return {
        notifications,
        updateNotificationsStatus,
        refreshNotifications,
        lastPageIndex,
        setPageIndex,
        notificationsAreLoading,
        submitAdminNotification,
    };
};
export default useNotifications;
