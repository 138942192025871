import Card from "@mui/material/Card";
import { styledCarouselCardSX } from "../../styled/styledSXProperties/styledDashboardTileCardSX";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

const TasksCardSkeleton = () => {
    return (
        <Card
            sx={{
                ...styledCarouselCardSX,
                minWidth: "90%",
                height: "165px",
                justifyContent: "top",
            }}
        >
            <CardHeader
                sx={{
                    textAlign: "center",
                    pb: 0,
                }}
                title={<Skeleton variant="text" height="50px" width="100%" />}
                titleTypographyProps={{
                    sx: {
                        display: "flex",
                        justifyContent: "center",
                    },
                }}
            />
            <CardContent sx={{ textAlign: "left", height: "100%" }}>
                <Grid container height="100%" spacing={1}>
                    <Grid
                        item
                        lg={12}
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <Skeleton
                            variant="rounded"
                            height="30px"
                            width="100%"
                        />
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Skeleton variant="rounded" height="30px" width="50%" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TasksCardSkeleton;
