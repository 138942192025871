import { appendToSnackbarQueue } from "../redux/actions/alertsActions";

export const triggerSnackbar = (severity, message, action, dispatch) => {
    /*
    severity : string
    message : string
    action : func
    dispatch : func
    */
    dispatch(
        appendToSnackbarQueue({
            action,
            message,
            severity,
        })
    );
};
