import React from "react";
import Box from "@mui/material/Box";
import { closestCenter, DndContext, MeasuringStrategy } from "@dnd-kit/core";
import {
    restrictToParentElement,
    restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import { alpha, Stack, useTheme } from "@mui/material";
import DraggableTileHandle from "./draggableTileHandle";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import useTileCustomisation from "./useTileCustomisation";
import useAccessibility from "../../services/apiUtils/hooks/useAccessibility";
import SearchField from "../headerBar/searchField";

const CustomiseTilesPanel = ({ tileCollection }) => {
    const [searchTerm, setSearchTerm] = React.useState("");
    const {
        sensors,
        reorderTiles,
        updateTiles,
        handleRemoveTile,
        handleAddTile,
        sortingStrategy,
    } = useTileCustomisation(tileCollection);

    const theme = useTheme();
    const { accessibilitySettings } = useAccessibility();
    const inHCMode = accessibilitySettings?.highContrastMode;
    const filteredTiles =
        searchTerm && tileCollection
            ? tileCollection.currentTileOrder.filter((tile) =>
                  tileCollection.tilesMap[tile].name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
              )
            : tileCollection.currentTileOrder;
    const filteredHiddenTiles =
        searchTerm && tileCollection
            ? tileCollection.currentHiddenTileIds.filter((tile) =>
                  tileCollection.tilesMap[tile].name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
              )
            : tileCollection.currentHiddenTileIds;
    return (
        <Box sx={{ padding: "16px", overflowX: "hidden", overflowY: "auto" }}>
            <SearchField
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
            <Box>
                <DndContext
                    sensors={sensors}
                    modifiers={[
                        restrictToVerticalAxis,
                        restrictToParentElement,
                    ]}
                    collisionDetection={closestCenter}
                    onDragEnd={reorderTiles}
                    onDragOver={updateTiles}
                    measuring={{
                        droppable: {
                            strategy: MeasuringStrategy.Always,
                        },
                    }}
                >
                    <SortableContext
                        items={tileCollection.tileHandles}
                        strategy={sortingStrategy}
                    >
                        <Stack spacing={1}>
                            {filteredTiles.map((tileId) => (
                                <DraggableTileHandle
                                    id={tileId}
                                    key={`customise-tilehandle-drag-${tileId}`}
                                    name={tileCollection.tilesMap[tileId].name}
                                    onRemove={() => handleRemoveTile(tileId)}
                                />
                            ))}
                            {tileCollection.currentHiddenTileIds.length > 0 && (
                                <DraggableTileHandle
                                    name={"Add tile"}
                                    id={"visibleTilesPlaceholder"}
                                    onRemove={() => {}}
                                    hidden={true}
                                />
                            )}

                            <Box sx={{ padding: "16px 0" }}>
                                <Divider
                                    sx={{
                                        borderBottomWidth: 1,
                                        backgroundColor: inHCMode
                                            ? theme.palette.text.main
                                            : alpha(
                                                  theme.palette.text.main,
                                                  0.12
                                              ),
                                    }}
                                    variant="middle"
                                />
                            </Box>
                            {filteredHiddenTiles.map((tileId) => (
                                <DraggableTileHandle
                                    id={tileId}
                                    key={`customise-tilehandle-add-${tileId}`}
                                    name={tileCollection.tilesMap[tileId].name}
                                    onAdd={() => handleAddTile(tileId)}
                                    addable={true}
                                    onRemove={() => {}}
                                />
                            ))}
                            <DraggableTileHandle
                                name={"Hidden tiles placeholder"}
                                id={"hiddenTilesPlaceholder"}
                                onRemove={() => {}}
                                hidden={true}
                            />
                        </Stack>
                    </SortableContext>
                </DndContext>
            </Box>
        </Box>
    );
};

CustomiseTilesPanel.propTypes = {
    tileCollection: PropTypes.object.isRequired,
};

export default CustomiseTilesPanel;
