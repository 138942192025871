import PropTypes from "prop-types";
import { ChevronRight } from "@sse-digital-services/design-system";
import React from "react";
const { Typography, Grid } = require("@mui/material");

const QuickActionTileContent = ({
    title,
    descriptionText,
    trackingId,
    showChevron,
    titleSx,
    descriptionSx,
}) => {
    return (
        <Grid
            container
            spacing={1}
            columns={12}
            sx={{
                justifyContent: "space-between",
                alignContent: "space-between",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                marginTop: "0",
            }}
        >
            <Grid item xs={12} sm={showChevron ? 9 : 12}>
                <Typography
                    align={"left"}
                    variant="h6"
                    component="p"
                    mb={"4px"}
                    sx={{
                        fontWeight: "bold",
                        ...titleSx,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    data-track-id={trackingId ?? `${title} link`}
                    sx={{
                        fontWeight: "light",
                        ...descriptionSx,
                    }}
                    align="left"
                >
                    {descriptionText}
                </Typography>
            </Grid>
            {showChevron && (
                <Grid
                    item
                    xs={"auto"}
                    sm={"auto"}
                    data-track-id={`${title}-quick-link-icon`}
                    aria-label={`${title}-quick-link-icon`}
                    sx={{
                        display: "flex",
                        width: "100%",
                        px: "8px",
                        py: 0,
                        textTransform: "none",
                        alignItems: "center",
                        justifyContent: "center",
                        outline: "revert",
                    }}
                >
                    <ChevronRight
                        fontSize={"medium"}
                        sx={{
                            width: "1em",
                            height: "1em",
                            borderRadius: "50%",
                            border: "2px solid",
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

QuickActionTileContent.propTypes = {
    title: PropTypes.string.isRequired,
    descriptionText: PropTypes.string.isRequired,
    trackingId: PropTypes.string,
    showChevron: PropTypes.bool.isRequired,
    titleSx: PropTypes.object,
    descriptionSx: PropTypes.object,
};

export default QuickActionTileContent;
