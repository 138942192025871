// Returns action creators for each auth action type
import { SET_ACTIVE_OU, SET_AUTH } from "../actionTypes";

// Action creators for auth slice
export const setActiveOU = (OU) => {
    return { type: SET_ACTIVE_OU, payload: OU };
};

export const setAuth = (auth) => {
    return { type: SET_AUTH, payload: auth };
};
