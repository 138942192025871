import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

import { styledDashboardTileSX } from "../../styled/styledSXProperties/styledDashboardTileCardSX";

const NotificationCardSkeleton = () => {
    return (
        <Card
            sx={{
                ...styledDashboardTileSX,
                height: "200px",
                padding: "10px",
            }}
        >
            <Skeleton
                data-testid="notification-card-skeleton1"
                variant="text"
                height="30%"
                width="100%"
            />
            <Skeleton
                data-testid="notification-card-skeleton2"
                variant="rectangular"
                height="70%"
                width="100%"
            />
        </Card>
    );
};

export default NotificationCardSkeleton;
