import { initialState } from "../initialState";

// Action Types
import { SET_SNACKBAR_QUEUE, APPEND_TO_SNACKBAR_QUEUE } from "../actionTypes";

const alertsReducer = (state = initialState.alerts, action) => {
    switch (action.type) {
        case SET_SNACKBAR_QUEUE:
            return {
                ...state,
                snackbarQueue: action.payload,
            };
        case APPEND_TO_SNACKBAR_QUEUE:
            return {
                ...state,
                snackbarQueue: [...state.snackbarQueue, action.payload],
            };
        default:
            return state;
    }
};

export default alertsReducer;
