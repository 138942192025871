import SSENetTile from "./quickLinkTiles/sseNetTile";
import IBuyCartsTile from "./iBuyCarts/iBuyCartsTile";
import IBuySearchTile from "./iBuySearch/iBuySearchTile";
import RecognitionTile from "./demoTiles/recognition";
// import AnnualLeaveTile from "./quickLinkTiles/annualLeaveTile";
import RequestsTile from "./requests/requestsTile";
import IncidentsTile from "./incidents/incidentsTile";
import PayslipsInfoTile from "./quickLinkTiles/payslipsInfoTile";
import SharePriceTile from "./demoTiles/shareprice";
import NotificationCounterTile from "./notificationCounterTile";
import PlanonFMTile from "./quickLinkTiles/planonFMTile";
import TravelHubTile from "./quickLinkTiles/travelHubTile";
import DataMarketplaceTile from "./quickLinkTiles/dataMarketplaceTile";
import ExpensesTile from "./quickLinkTiles/expensesTile";
import CareersTile from "./quickLinkTiles/careersTile";
import QuickActionsAreaTile from "./quickActionsAreaTile";
import AdvantageTile from "./quickLinkTiles/advantageTile";
import FieldglassTile from "./quickLinkTiles/fieldglassTile";
import NudgeTile from "./quickLinkTiles/nudgeTile";
import HRHubTile from "./quickLinkTiles/hrHubTile";
import FieldglassNonSSOTile from "./quickLinkTiles/fieldglassNonSSOTile";
import SpotlightTile from "./quickLinkTiles/spotlightTile";
import BenefitsTile from "./quickLinkTiles/benefitsTile";
import EdisonTile from "./edisonTile";
import JaggaerTile from "./quickLinkTiles/jaggaerTile";
import LxpInfoTile from "./quickLinkTiles/lxpInfoTile";
import ManagersHubTile from "./quickLinkTiles/managersHubTile";
import ErfPortalTile from "./quickLinkTiles/erfPortalTile";
import LMSCoursesTile from "./lxpInfo/lmsCourseTile.jsx";

export const dashboardTilesMap = {
    procurementCart: {
        component: IBuyCartsTile,
        name: "iBuy Carts",
    },
    procurementSearch: {
        component: IBuySearchTile,
        name: "iBuy Search",
    },
    rewards: {
        component: RecognitionTile,
        name: "Recognition",
    },
    requests: {
        component: RequestsTile,
        name: "Requests",
    },
    lxpStatus: {
        component: LMSCoursesTile,
        name: "My Learning",
        description: "My Learning",
    },
    incidents: {
        component: IncidentsTile,
        name: "Incidents",
    },
    quickLinks: {
        component: QuickActionsAreaTile,
        name: "Quick Links",
    },
    notificationCounter: {
        component: NotificationCounterTile,
        name: "Notification Counter",
    },
    edison: {
        component: EdisonTile,
        name: "Ask Edison",
    },
};

export const quickActionTilesMap = {
    payslipsInfo: {
        component: PayslipsInfoTile,
        name: "Latest Payslip",
    },
    hrHub: {
        component: HRHubTile,
        name: "HR Hub",
        description: "HR information, policies and advice",
    },
    sseNet: {
        component: SSENetTile,
        name: "SSENet",
        description: "Go To SSENet",
    },
    sharePrice: {
        component: SharePriceTile,
        name: "Share Price",
    },
    // equatePlus: {
    //     component: EquatePlusTile,
    //     name: "Equate Plus",
    // },
    advantage: {
        component: AdvantageTile,
        name: "SSE Advantage",
        description: "Savings that add up!",
    },
    careers: {
        component: CareersTile,
        name: "Careers",
        description: "View all live vacancies",
    },
    planonFM: {
        component: PlanonFMTile,
        name: "AskFM",
        description: "Go To AskFM",
    },
    expenses: {
        component: ExpensesTile,
        name: "Expenses",
        description: "Go to Expenses",
    },
    travelHub: {
        component: TravelHubTile,
        name: "Travel Hub",
        description: "Manage your travel",
    },
    nudgeAccess: {
        component: NudgeTile,
        name: "Nudge for SSE",
        description: "Financial Wellbeing",
    },
    dataMarketplace: {
        component: DataMarketplaceTile,
        name: "Data Marketplace",
        description: "Shared business data",
    },
    fieldglass: {
        component: FieldglassTile,
        name: "Fieldglass SSO",
        description: "SAP Fieldglass SSO login",
    },
    fieldglassNonSSO: {
        component: FieldglassNonSSOTile,
        name: "Fieldglass",
        description: "Contractor Fieldglass login",
    },
    spotlight: {
        component: SpotlightTile,
        name: "SSE Spotlight",
        description: "Say thank you or well done",
    },
    benefits: {
        component: BenefitsTile,
        name: "SSE Benefits",
        description: "Health, wealth, and wellbeing",
    },
    jaggaer: {
        component: JaggaerTile,
        name: "Jaggaer SSO",
        description: "Source to Contract",
    },
    lxpInfo: {
        component: LxpInfoTile,
        name: "My Learning",
        description: "Develop and maintain your knowledge and skills",
    },
    managersHub: {
        component: ManagersHubTile,
        name: "Managers Hub",
        description: "Helping you support your teams",
    },
    // annualLeave: {
    //     component: AnnualLeaveTile,
    //     name: "Annual Leave",
    //     description: "View/request annual leave",
    // },
    erfPortal: {
        component: ErfPortalTile,
        name: "SSE ERF Portal",
        description: "Employee Record File",
    },
};

export const desktopOnlyTiles = ["edison"];
