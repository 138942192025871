import React, { useEffect } from "react";
import {
    SERVICENOW_INCIDENT_ROWS_PER_PAGE,
    SERVICENOW_REQUEST_ROWS_PER_PAGE,
} from "../../../constants/generalConstants";
import PropTypes from "prop-types";
import PaginatedTable from "../../tables/paginatedTable";
import { itscUrl } from "../../../services/apiUtils/config/urls";
import {
    PROCURETOPAY,
    serviceNames,
    SERVICENOW,
} from "../../../constants/serviceNames";
import TileAccordion from "../tileAccordion";
import ServiceNowRequestRow from "./serviceNowRequestRow";
import useServiceNowRequests from "../../../services/apiUtils/hooks/useServiceNowRequests";

const ServiceNowRequestsSection = ({
    isEditing,
    maintenanceMode = false,
    viewClosed = false,
}) => {
    const {
        serviceNowRequests,
        serviceNowRequestsCount,
        loadingPageCount,
        setPagesToLoad,
        errorMessage,
    } = useServiceNowRequests(viewClosed);

    const preloadNextPage = (newPage) => {
        if (
            newPage + 1 >= loadingPageCount &&
            serviceNowRequestsCount >
                (newPage + 1) * SERVICENOW_REQUEST_ROWS_PER_PAGE
        ) {
            setPagesToLoad(newPage + 2);
        }
    };

    useEffect(() => {
        if (
            loadingPageCount === 1 &&
            serviceNowRequestsCount > SERVICENOW_REQUEST_ROWS_PER_PAGE
        ) {
            setPagesToLoad(2);
        }
    }, [loadingPageCount, serviceNowRequestsCount, setPagesToLoad]);

    return (
        <TileAccordion
            serviceName={`${serviceNames[SERVICENOW]}/${serviceNames[PROCURETOPAY]}`}
            tileName="requests"
            maintenanceMode={maintenanceMode}
            errorMessage={errorMessage}
            count={serviceNowRequestsCount}
            isEditing={isEditing}
        >
            <PaginatedTable
                totalItems={serviceNowRequestsCount}
                isEditing={isEditing}
                serviceUrl={`${itscUrl}?id=requests`}
                itemsPerPage={SERVICENOW_INCIDENT_ROWS_PER_PAGE}
                service={`${serviceNames[SERVICENOW]}/${serviceNames[PROCURETOPAY]}`}
                items={serviceNowRequests ?? []}
                Row={ServiceNowRequestRow}
                onPageChange={preloadNextPage}
            />
        </TileAccordion>
    );
};

ServiceNowRequestsSection.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    maintenanceMode: PropTypes.bool,
    viewClosed: PropTypes.bool,
};

export default ServiceNowRequestsSection;
