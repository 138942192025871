import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const iBuyCartsAPIService = apiService(apiConfig.carts);

const iBuyCartsClient = {
    getCarts: () => iBuyCartsAPIService.get(""),
};

export default iBuyCartsClient;
