const accessibilityPreferencesMapper = {
    mapAccessibilitySettings: (newSettings) => {
        return {
            darkMode: newSettings.synced.settings.darkMode,
            highContrastMode: newSettings.synced.settings.highContrastMode,
            highContrastBackground:
                newSettings.synced.settings.highContrastColorBackground,
            highContrastForeground:
                newSettings.synced.settings.highContrastColorOutline,
        };
    },
    mapAccessibilitySettingsToShopfront: (newSettings) => {
        return {
            synced: {
                settings: {
                    darkMode: newSettings.darkMode,
                    highContrastMode: newSettings.highContrastMode,
                    highContrastColorBackground:
                        newSettings.highContrastBackground,
                    highContrastColorOutline:
                        newSettings.highContrastForeground,
                },
            },
        };
    },
};

export default accessibilityPreferencesMapper;
