import PropTypes from "prop-types";
import * as React from "react";
import { useRef, useState } from "react";
import {
    isEnvProd,
    PLANON_PROD_RAISE_REQUESTS_FAULTS,
    PLANON_PROD_VIEW_REQUESTS_FAULTS,
    PLANON_REQUESTS_FAULTS,
    PLANON_RESERVATIONS,
    PLANON_URL,
    PLANON_VISITORS,
} from "../../../constants/externalUrls";
import QuickActionTile from "../templates/quickActionTile";
import { ButtonBase, useMediaQuery } from "@mui/material";
import LinkMenu from "../../modals/linkMenu";
import {
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
} from "@sse-digital-services/design-system";
import { quickActionTilesMap } from "../tilesMap";
import QuickActionTileContent from "../templates/quickActionTileContent";
import { quickActionTileButtonSX } from "styled/styledSXProperties/styledDashboardTileCardSX";

export const planonSharedLinks = {
    "View/Make Bookings": PLANON_RESERVATIONS,
    "View/Book Visitors": PLANON_VISITORS,
};

export const planonLinks = isEnvProd
    ? {
          ...planonSharedLinks,
          "Raise Requests/Faults": PLANON_PROD_RAISE_REQUESTS_FAULTS,
          "View Requests/Faults": PLANON_PROD_VIEW_REQUESTS_FAULTS,
      }
    : {
          ...planonSharedLinks,
          "View/Report Faults": PLANON_REQUESTS_FAULTS,
          "View/Raise Requests": PLANON_REQUESTS_FAULTS,
      };

const PlanonFMTile = ({ id, transform, addRectGetter }) => {
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const [anchorEl, setAnchorEl] = useState(null);
    const hovering = useRef(false);
    const btnRef = useRef();
    const open = Boolean(anchorEl);
    const { handheld } = getDeviceInfo();
    const handleClick = (event) => {
        if (!anchorEl) {
            btnRef.current.focus();
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuMouseOver = !handheld
        ? () => {
              hovering.current = true;
          }
        : undefined;

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMouseLeave = !handheld
        ? () => {
              hovering.current = false;
              setTimeout(() => {
                  if (!hovering.current) {
                      handleMenuClose();
                  }
              }, 50);
          }
        : undefined;

    const extendedPlanonLinks = { "Visit AskFM": PLANON_URL, ...planonLinks };

    return (
        <QuickActionTile
            id={id}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <ButtonBase
                ref={btnRef}
                id="askFM links"
                data-track-id="AskFM-links-options"
                aria-controls={open ? "askFM-link-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                onMouseOver={handleClick}
                onMouseLeave={handleMouseLeave}
                sx={quickActionTileButtonSX(isDesktopWidth)}
            >
                <QuickActionTileContent
                    title="AskFM"
                    descriptionText={quickActionTilesMap[id].description}
                    showChevron={false}
                    titleSx={{ fontWeight: "bold" }}
                    descriptionSx={undefined}
                />
            </ButtonBase>
            <LinkMenu
                anchorEl={anchorEl}
                menuId={"askFM-link-menu"}
                links={extendedPlanonLinks}
                onClose={handleMenuClose}
                onMouseEnter={handleMenuMouseOver}
                onMouseLeave={handleMouseLeave}
                menuSx={{ left: "7px" }}
            />
        </QuickActionTile>
    );
};

PlanonFMTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default PlanonFMTile;
