import useDashboardTiles from "../useDashboardTiles";
import useTileMaintenanceMode from "../maintentance/useTileMaintenanceMode";
import DisabledOverlay from "../maintentance/disabledOverlay";
import SolidTile from "./solidTile";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
    styledSolidTileRootEditingSX,
    styledSolidTileRootSX,
} from "../../../styled/styledSXProperties/styledDashboardTileCardSX";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useEffect, useRef } from "react";

const DashboardTile = ({
    children,
    id,
    xs = 12,
    sm,
    md = 6,
    lg = 4,
    xl,
    tileColor,
    contentSX,
    transform,
    errorMessage,
    addRectGetter,
}) => {
    const { isEditing } = useDashboardTiles();
    const { maintenanceMode, serviceDisplayNames } = useTileMaintenanceMode(id);
    const ref = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        if (addRectGetter) {
            addRectGetter(id, () => {
                return ref.current?.getBoundingClientRect();
            });
        }
    }, []);

    return (
        <Grid
            key={id}
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            data-track-id={`tile-${id}`}
            sx={{
                zIndex: "content",
                padding: 0,
                ...transform,
            }}
        >
            <div
                ref={ref}
                style={{
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        height: "100%",
                        ...(maintenanceMode &&
                            (isEditing
                                ? styledSolidTileRootEditingSX(theme)
                                : styledSolidTileRootSX)),
                    }}
                >
                    {(maintenanceMode || errorMessage) && (
                        <DisabledOverlay
                            maintenanceMode={maintenanceMode}
                            isEditing={isEditing}
                            serviceNames={serviceDisplayNames}
                            errorMessage={errorMessage}
                        />
                    )}
                    <SolidTile
                        isEditing={isEditing}
                        tileColor={tileColor}
                        contentSX={contentSX}
                    >
                        {children}
                    </SolidTile>
                </Box>
            </div>
        </Grid>
    );
};

DashboardTile.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    tileColor: PropTypes.string,
    contentSX: PropTypes.object,
    transform: PropTypes.object,
    errorMessage: PropTypes.string,
    addRectGetter: PropTypes.func,
};

export default DashboardTile;
