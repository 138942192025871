import queryString from "query-string";
import { tokenManager } from "./tokenManager";
import axios from "axios";

const apiService = (apiConfig, additionalConfig = {}) => {
    const getUrl = (path) =>
        path ? `${apiConfig.url}/${path}` : apiConfig.url;
    const getQueryString = (params) => {
        if (!params) {
            return "";
        }
        const queryParamString = queryString.stringify(params, {
            arrayFormat: "separator",
            arrayFormatSeparator: "+",
        });
        return `?${queryParamString}`;
    };

    const getAxiosConfig = async () => {
        const token = await tokenManager.getTokenForAPI(apiConfig);
        return {
            timeout: 90000,
            ...additionalConfig,
            headers: {
                ...additionalConfig?.headers,
                Authorization: token ? `Bearer ${token}` : undefined,
            },
        };
    };

    return {
        get: async (path, params, requestHeaders = {}) => {
            const axiosConfig = await getAxiosConfig();
            const response = await axios.get(
                `${getUrl(path)}${getQueryString(params)}`,
                {
                    timeout: 50000,
                    ...axiosConfig,
                    headers: {
                        ...axiosConfig?.headers,
                        ...requestHeaders,
                    },
                }
            );
            return response.data;
        },
        post: async (path, body) => {
            const axiosConfig = await getAxiosConfig();
            const response = await axios.post(`${getUrl(path)}`, body, {
                ...axiosConfig,
                headers: {
                    ...axiosConfig.headers,
                    "Content-type": "application/json",
                },
            });
            return response.data;
        },
        patch: async (path, body) => {
            const axiosConfig = await getAxiosConfig();
            const response = await axios.patch(`${getUrl(path)}`, body, {
                ...axiosConfig,
                headers: {
                    ...axiosConfig.headers,
                    "Content-type": "application/json",
                },
            });
            return response.data;
        },
    };
};

export default apiService;
