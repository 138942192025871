import PropTypes from "prop-types";
import * as React from "react";
import QuickActionTile from "../templates/quickActionTile";
import { SSE_SHARE_PRICE } from "../../../constants/externalUrls";
import useSharePrice from "../../../services/apiUtils/hooks/useSharePrice";
import SingleLinkTile from "../templates/singleLinkTile";

const SharePriceTile = ({ id }) => {
    const { latestSharePrice } = useSharePrice();

    const formatPriceChange = (change) => {
        if (change > 0) {
            return "+" + change;
        }
        return change;
    };

    const linkText = latestSharePrice
        ? `${latestSharePrice.latestPrice} ${
              latestSharePrice.currency
          }, ${formatPriceChange(latestSharePrice.change)} (${formatPriceChange(
              latestSharePrice.percentageChange
          )}%)`
        : undefined;
    const alternativeText = latestSharePrice
        ? undefined
        : "No share price data found";

    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={SSE_SHARE_PRICE}
                title={"Share Price"}
                linkText={linkText}
                alternativeText={alternativeText}
            />
        </QuickActionTile>
    );
};

SharePriceTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default SharePriceTile;
