import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { APP_NAME } from "../../../constants/generalConstants";
import { VERSION_BEARS } from "../../../constants/bears";

const ContentFooter = () => {
    const appName = APP_NAME;
    const version = GLOBAL_CONFIG.version;
    const versionBear =
        VERSION_BEARS[
            parseInt(version?.split(".")[0] - 1) % VERSION_BEARS.length
        ];
    const releaseDate = GLOBAL_CONFIG.releaseDate;
    return (
        <Box sx={{ marginTop: "auto", marginBottom: "10px" }}>
            <Typography
                align="center"
                sx={{
                    fontSize: 14,
                    color: "text.primary",
                }}
            >
                {appName} Version
            </Typography>
            <Typography
                align="center"
                sx={{
                    fontSize: 14,
                    color: "text.primary",
                    mb: 1,
                }}
            >
                {`${versionBear} | ${version}`}
            </Typography>
            <Typography
                align="center"
                sx={{
                    fontSize: 14,
                    color: "text.primary",
                }}
            >
                Release Date
            </Typography>
            <Typography
                align="center"
                sx={{
                    fontSize: 14,
                    color: "text.primary",
                }}
            >
                {releaseDate}
            </Typography>
        </Box>
    );
};

export default ContentFooter;
