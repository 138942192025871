import iBuyRequisitionClient from "../clients/iBuyRequisitionClient";
import iBuyMapper from "../mappers/iBuyMapper";
import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import useSWR from "swr";
import { REQUISITIONS_TO_LOAD } from "../../../constants/generalConstants";
import { PROCUREMENT } from "../../../constants/serviceNames";

const useIBuyRequisitions = () => {
    const {
        servicesInMaintenanceMode,
        preferencesAreLoading,
        servicesBasedOnPermissions,
    } = usePreferences();
    const { throwAsyncError } = useErrorHandling();

    const { data: requisitionList, error: iBuyError } = useSWR(
        () => (preferencesAreLoading ? null : "requisitions"),
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(PROCUREMENT) &&
                    servicesInMaintenanceMode
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === PROCUREMENT
                        )
                    ) {
                        const requisitionsData =
                            await iBuyRequisitionClient.getRequisitionList(
                                REQUISITIONS_TO_LOAD
                            );
                        return iBuyMapper.mapAPIRequisitionsToRequisitions(
                            requisitionsData
                        );
                    }
                }
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.IBuyRequisitionsAPIError,
                    "Unable to fetch requisitions from iBuy",
                    error
                );
                throw new Error("Unable to fetch requisitions from iBuy");
            }
        },
        { fallbackData: [] }
    );

    return { requisitionList, errorMessage: iBuyError?.message };
};

export default useIBuyRequisitions;
