import Box from "@mui/material/Box";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";

import * as routes from "./constants/routes";
import {
    contentBoxL2,
    PageNotFound,
} from "@sse-digital-services/design-system";
import { DESKTOP, MOBILE } from "./constants/generalConstants";
import Homepage from "./components/pages/homepage/homepage";
import Tasks from "./components/pages/tasks/tasks";
import usePreferences from "./services/apiUtils/hooks/usePreferences";
import AdminPage from "./components/pages/admin/adminPage";
import EdisonAdminPage from "./components/pages/edisonAdmin/edisonAdminPage";

const homeURL = encodeURI("/");
const defaultURL = encodeURI("/");

const PageRouter = () => {
    const { isAdmin, isManager, isEdisonAdmin } = usePreferences();
    return (
        <Routes>
            <Route path={routes.HOMEPAGE} element={<Homepage />} />
            <Route
                path="*"
                element={
                    <Box sx={contentBoxL2}>
                        <PageNotFound homePageRoute={routes.HOMEPAGE} />
                    </Box>
                }
            />
            <Route
                exact
                path={defaultURL}
                element={<Navigate to={homeURL} />}
            />
            <Route path={routes.TASKS} element={<Tasks />} />
            {(isAdmin || isManager) && (
                <Route path={routes.ADMIN} element={<AdminPage />} />
            )}
            {isEdisonAdmin && (
                <Route
                    path={routes.EDISON_ADMIN}
                    element={<EdisonAdminPage />}
                />
            )}
        </Routes>
    );
};

PageRouter.propTypes = {
    mode: PropTypes.oneOf([DESKTOP, MOBILE]),
};

export default PageRouter;
