import PropTypes from "prop-types";
import useDashboardTiles from "../useDashboardTiles";
import Card from "@mui/material/Card";
import {
    quickActionOverlaySX,
    styledQuickActionTileSX,
} from "../../../styled/styledSXProperties/styledDashboardTileCardSX";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import DisabledOverlay from "../maintentance/disabledOverlay";
import useTileMaintenanceMode from "../maintentance/useTileMaintenanceMode";
import Box from "@mui/material/Box";
import { alpha, useMediaQuery, useTheme } from "@mui/material";
import { DESKTOP_MEDIA_QUERY } from "@sse-digital-services/design-system";
import useHomePage from "../../pages/homepage/useHomepage";
import Backdrop from "@mui/material/Backdrop";
import { styledQuickTileBoxSx } from "../../../styled/styledSXProperties/styledQuickTileBoxSx";

const QuickActionTile = ({ children, id, errorMessage }) => {
    const theme = useTheme();
    const { isEditing } = useDashboardTiles();
    const { maintenanceMode, serviceDisplayNames } = useTileMaintenanceMode(id);
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const { quickActionTileCollection } = useHomePage();
    const isHiddenTile =
        quickActionTileCollection.currentHiddenTileIds.includes(id);
    return (
        <Card
            sx={{
                ...styledQuickActionTileSX,
                height: isDesktopWidth ? "6em" : "10em",
                overflow: "hidden",
            }}
            data-track-id="quick action card"
        >
            {(maintenanceMode || errorMessage) && (
                <Box sx={quickActionOverlaySX}>
                    <DisabledOverlay
                        maintenanceMode={maintenanceMode}
                        isEditing={isEditing}
                        serviceNames={serviceDisplayNames}
                        errorMessage={errorMessage}
                    />
                </Box>
            )}
            {isHiddenTile && ( //Overlay present when a non chosen  tile is brought up in quick tile search
                <Backdrop
                    sx={{
                        ...quickActionOverlaySX,
                        backgroundColor: alpha(
                            theme.palette.common.slateGrey20,
                            0.35
                        ),
                    }}
                    open={isHiddenTile}
                />
            )}
            <CardContent
                sx={{
                    height: isDesktopWidth ? "6em" : "10em",
                    ...styledQuickTileBoxSx(theme),
                }}
            >
                <Box sx={{ flex: 1, width: "100%" }}>{children}</Box>
            </CardContent>
        </Card>
    );
};

QuickActionTile.propTypes = {
    id: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    children: PropTypes.node,
};

export default QuickActionTile;
