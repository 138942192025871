import { arrayIntersection } from "../../../utils/arrayUtils";

export const filterPreferencesServices = (preferences, key = "aadGroup") => {
    // Remove tiles/services where the user is not in the required aadGroup or any other subsequent exclusion
    if (preferences?.services) {
        return Object.entries(preferences.services)
            .filter(([, v]) => v[key])
            .map((service) => service[0])
            .sort();
    }
    return [];
};

const allGivenServicesInMaintenance = (givenServices, preferences) => {
    return givenServices.every((service) =>
        filterPreferencesServices(preferences, "maintenanceMode").includes(
            service
        )
    );
};

const preferencesMapper = {
    mapShopfrontPreferencesToTilePreferences: (preferences) => {
        const permittedServices = filterPreferencesServices(preferences);
        const isTilePermitted = (tileServices) =>
            permittedServices.some((service) => tileServices.includes(service));

        let tilePreferences = {};

        const tiles = preferences.tiles;
        const tileNames = Object.keys(tiles);
        tileNames.forEach((key) => {
            if (isTilePermitted(tiles[key].services)) {
                tilePreferences[key] = {
                    ...tiles[key],
                    maintenanceMode: allGivenServicesInMaintenance(
                        arrayIntersection(
                            tiles[key].services,
                            permittedServices
                        ),
                        preferences
                    ),
                };
            }
        });
        return tilePreferences;
    },

    mapTilePreferenceUpdateToShopfrontPreferences: (
        tilePreferences,
        preferences
    ) => {
        const newPreferences = { ...preferences };
        const tilesToUpdate = Object.keys(tilePreferences);

        tilesToUpdate.forEach((tileName) => {
            newPreferences.tiles[tileName] = {
                ...newPreferences.tiles[tileName],
                priority: tilePreferences[tileName].priority,
                display: tilePreferences[tileName].display,
            };
        });

        return newPreferences;
    },
    mapShopfrontPreferencesToNotificationPreferences: (preferences) => {
        const filteredShopfrontServices =
            filterPreferencesServices(preferences);
        const notificationPreferences = {};
        filteredShopfrontServices.forEach((service) => {
            const notifications = preferences.services[service].notifications;
            if (notifications !== undefined) {
                notificationPreferences[service] = notifications;
            }
        });
        return notificationPreferences;
    },
    mapNotificationPreferenceUpdateToShopfrontPreferences: (
        notificationPreferences,
        preferences
    ) => {
        const newPreferences = { ...preferences };
        const notificationToUpdate = Object.keys(notificationPreferences);
        notificationToUpdate.forEach((serviceName) => {
            newPreferences.services[serviceName].notifications =
                notificationPreferences[serviceName];
        });
        return newPreferences;
    },
    mapShopfrontPreferencesToServiceStatuses: (
        preferences,
        filteredPreferences
    ) => {
        return filteredPreferences.filter(
            (service) => preferences.services[service].maintenanceMode
        );
    },
};

export default preferencesMapper;
