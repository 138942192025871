import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import React from "react";
import PropTypes from "prop-types";
import {
    styledDividerSX,
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
} from "@sse-digital-services/design-system";
import { useDispatch, useSelector } from "react-redux";
import TasksArea from "../../tasks/tasksArea";
import { dashboardTilesMap } from "components/tiles/tilesMap";
import HomepageTileSkeleton from "components/skeletons/homepageTileSkeleton";
import useHomePage from "./useHomepage";
import { setIsEditing } from "../../../redux/actions/generalActions";
import { GRID_SPACING } from "../../../constants/tileGridConstants";
import InstallPWADialog from "../../PWA/installPWADialog/installPWADialog";
import TriggerNotificationRequestDialog from "../../PWA/notificationRequest/triggerNotificationRequestDialog";
import Grid from "@mui/material/Grid";
import FeedbackTile from "../../tiles/feedbackTile";
import CustomiseTilesDrawer from "../../customisation/customiseTilesDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";

const Homepage = ({ isLoading = false }) => {
    const isEditing = useSelector((state) => state.general.isEditing);
    const dispatch = useDispatch();
    const { handheld } = getDeviceInfo();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);

    const startEditing = () => dispatch(setIsEditing(true));

    const {
        dashboardTileCollection,
        quickActionTileCollection,
        saveTileLayout,
    } = useHomePage();

    const dividerStyle = {
        ...styledDividerSX,
        margin: "24px 0px",
        opacity: isEditing ? 0 : 1,
    };

    const gridPadding =
        isDesktopWidth && !handheld ? "0 30px 0 14px" : "0 16px 0 0";

    const createDashboardTile = (tileId, index) => {
        if (dashboardTilesMap[tileId]?.component) {
            const DashboardTile = dashboardTilesMap[tileId].component;
            return (
                <DashboardTile
                    id={tileId}
                    key={`outer-tile-${tileId}`}
                    transform={dashboardTileCollection.tileTransforms[index]}
                    addRectGetter={dashboardTileCollection.addRectGetter}
                />
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <Box>
                {isLoading ? (
                    <HomepageTileSkeleton />
                ) : (
                    <>
                        <Box>
                            <TasksArea />
                        </Box>
                        <Box>
                            <Grid
                                sx={{
                                    margin: 0,
                                    width: "100%",
                                    padding: gridPadding,
                                }}
                                container
                                spacing={GRID_SPACING}
                                columns={12}
                                alignItems="stretch"
                            >
                                {dashboardTileCollection.currentTileOrder.map(
                                    (tileId, index) =>
                                        createDashboardTile(tileId, index)
                                )}
                                {dashboardTileCollection.tileBeingAdded &&
                                    createDashboardTile(
                                        dashboardTileCollection.tileBeingAdded,
                                        dashboardTileCollection.currentTileOrder
                                            .length
                                    )}
                                <FeedbackTile />
                            </Grid>
                        </Box>
                        <CustomiseTilesDrawer
                            open={isEditing}
                            onOpen={startEditing}
                            tileCollections={[
                                dashboardTileCollection,
                                quickActionTileCollection,
                            ]}
                            onClose={saveTileLayout}
                        />
                    </>
                )}
                <Divider sx={dividerStyle} />
            </Box>
            <InstallPWADialog />
            <TriggerNotificationRequestDialog />
        </>
    );
};

Homepage.propTypes = {
    isLoading: PropTypes.bool,
};

export default Homepage;
