import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import useNotificationCounter from "../../services/apiUtils/hooks/useNotificationsCounter";
import PropTypes from "prop-types";
import DashboardTile from "./templates/dashboardTile";

const NotificationCounterTile = ({ id, transform, addRectGetter }) => {
    const theme = useTheme();

    const { notificationCounter } = useNotificationCounter();
    const currentMonth = new Date().toLocaleString("en-GB", { month: "long" });
    return (
        <DashboardTile
            id={id}
            tileColor={theme.palette.background.paper}
            contentSX={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                flex: 1,
                alignItems: "flex-start",
                color: theme.palette.text.primary,
            }}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <Typography
                sx={{ margin: "0 0 20px 0" }}
                color="text.primary"
                variant="h2"
                component="p"
                textAlign="left"
                alignItems="center"
            >
                Notification Counter
            </Typography>
            {notificationCounter ? (
                <Box align={"left"}>
                    <Typography variant="body1" component="p">
                        Your {currentMonth} notifications:{" "}
                        <b>{notificationCounter.userCounter}</b>
                    </Typography>
                    <Typography variant="body1" component="p">
                        Global {currentMonth} notifications:{" "}
                        <b>{notificationCounter.globalCounter} </b>
                    </Typography>
                </Box>
            ) : (
                <Typography variant="body1" component="p" align="left">
                    No notification counter data found
                </Typography>
            )}
        </DashboardTile>
    );
};

NotificationCounterTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default NotificationCounterTile;
