import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import useTasks from "./useTasks";
import { MAX_TASKS_IN_CAROUSEL } from "../../constants/generalConstants";
import { useNavigate } from "react-router-dom";
import * as Routes from "../../constants/routes";
import Card from "@mui/material/Card";
import { styledCarouselCardSX } from "../../styled/styledSXProperties/styledDashboardTileCardSX";
import CardActions from "@mui/material/CardActions";
import TaskActionButton from "../buttons/taskActionButton";
import useBreakpoints from "../../utils/useBreakpoints";
import TasksCardSkeleton from "../skeletons/tasksCardSkeleton";
import PropTypes from "prop-types";
import { CardCarousel } from "@sse-digital-services/design-system";
import { Grid, useTheme } from "@mui/material";

const ViewMoreCard = ({ numTasks }) => {
    const navigateTo = useNavigate();

    const goToTasks = () => navigateTo(Routes.TASKS);
    return (
        <Card
            sx={{
                ...styledCarouselCardSX,
                height: "100%",
                paddingTop: "24px",
                alignItems: "center",
                justifyContent: "center",
            }}
            data-track-id="view more tasks card"
        >
            <CardActions
                sx={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <TaskActionButton
                    mode={"neutral"}
                    action={`View all ${numTasks} tasks`}
                    onClick={goToTasks}
                    typographyVariant="h2"
                />
            </CardActions>
        </Card>
    );
};

const TasksArea = () => {
    const taskCount = useSelector((state) => state.taskCount);
    const userName = useSelector((state) => state.auth.userName);
    const {
        currentBreakpoint,
        tasksPerBreakpoint,
        tasksNumGridColumns,
        taskGridBreakpoints,
    } = useBreakpoints();
    const theme = useTheme();

    const skeletons = new Array(tasksPerBreakpoint[currentBreakpoint])
        .fill({})
        .map((_, index) => (
            <TasksCardSkeleton key={`task-card-skeleton-${index}`} />
        ));
    const [sortedTasks, setSortedTasks] = React.useState([...skeletons]);
    const { taskUnion, tasksAreLoading, taskCards } = useTasks();

    let totalTasks = Object.values(taskCount).reduce((a, b) => a + b, 0);

    const getGreeting = () => {
        let welcomeText;
        const currentHour = new Date().getHours();
        if (currentHour < 12) welcomeText = "Good morning";
        else if (currentHour < 18) welcomeText = "Good afternoon";
        else welcomeText = "Good evening";

        if (userName) {
            const fullName = userName.toString().split(" ");
            const firstName = fullName[0];
            welcomeText = `${welcomeText} ${firstName},`;
        }
        return welcomeText;
    };

    useEffect(() => {
        totalTasks = Object.values(taskCount).reduce((a, b) => a + b, 0);
    }, [taskCount]);

    useEffect(() => {
        if (!tasksAreLoading) {
            let formattedCards = taskCards(taskUnion);
            const maxCardsOnPage = tasksPerBreakpoint[currentBreakpoint];
            if (maxCardsOnPage === 1) {
                if (taskUnion.length > MAX_TASKS_IN_CAROUSEL) {
                    formattedCards = formattedCards.slice(
                        0,
                        MAX_TASKS_IN_CAROUSEL
                    );
                    formattedCards.push(
                        <ViewMoreCard numTasks={taskUnion.length} />
                    );
                }
            } else if (taskUnion.length > maxCardsOnPage) {
                formattedCards = formattedCards.slice(0, maxCardsOnPage - 1);
                formattedCards.push(
                    <ViewMoreCard numTasks={taskUnion.length} />
                );
            }
            setSortedTasks(formattedCards);
        }
    }, [tasksAreLoading, taskUnion, currentBreakpoint]);

    const gradientPercentage =
        tasksPerBreakpoint[currentBreakpoint] === 1 ? "25%" : "15%";

    return (
        <Box
            sx={{
                py: totalTasks === 0 ? "24px" : null,
                background:
                    totalTasks === 0
                        ? theme.palette.background.dark
                        : `linear-gradient(to top, ${theme.palette.background.main} 0%, ${theme.palette.background.main} ${gradientPercentage}, ${theme.palette.background.dark} ${gradientPercentage}, ${theme.palette.background.dark} 100%)`,
            }}
        >
            <Box
                p="30px"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "flex-start",
                    justifyContent: "center",
                }}
            >
                <Typography
                    align={"left"}
                    variant="h1"
                    component="p"
                    color="common.white"
                >
                    {`${getGreeting()}`}
                </Typography>
                <Typography
                    align={"left"}
                    variant="h1"
                    component="p"
                    color="common.white"
                >
                    {`you have ${totalTasks} ${
                        totalTasks === 1 ? "task" : "tasks"
                    } waiting for you.`}
                </Typography>
            </Box>
            {tasksPerBreakpoint[currentBreakpoint] === 1 ? (
                <>
                    {(tasksAreLoading || totalTasks !== 0) && (
                        <Box sx={{ mx: "16px" }}>
                            <CardCarousel cards={sortedTasks} id="tasks area" />
                        </Box>
                    )}
                </>
            ) : (
                <Grid
                    container
                    spacing={2}
                    columns={tasksNumGridColumns}
                    sx={{ padding: "16px 30px" }}
                >
                    {sortedTasks.map((card, index) => (
                        <Grid
                            item
                            {...taskGridBreakpoints}
                            key={`quick-link-${index}`}
                        >
                            {card}
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

ViewMoreCard.propTypes = {
    numTasks: PropTypes.number,
};

export default TasksArea;
