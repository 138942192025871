import useMediaQuery from "@mui/material/useMediaQuery";
import {
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
} from "@sse-digital-services/design-system";
import * as React from "react";
import { Button, alpha, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    setShowFeedbackForm,
    setShowFeedbackPrompt,
} from "../../redux/actions/generalActions";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import useDashboardTiles from "./useDashboardTiles";
import SolidTile from "./templates/solidTile";
import { buttonSX } from "styled/styledSXProperties/styledUserInput";

const FeedbackTile = () => {
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const { handheld } = getDeviceInfo();
    const dispatch = useDispatch();
    const { isEditing } = useDashboardTiles();
    const theme = useTheme();

    const setShowPrompt = (newShowPrompt) => {
        dispatch(setShowFeedbackPrompt(newShowPrompt));
    };

    const setShowForm = (newShowForm) => {
        dispatch(setShowFeedbackForm(newShowForm));
    };

    const onPromptClick = () => {
        setShowPrompt(false);
        setShowForm(true);
    };

    return (
        <>
            {(!isDesktopWidth || handheld) && (
                <Grid
                    item
                    xs={12}
                    data-track-id={`tile-feedback`}
                    sx={{
                        zIndex: "content",
                        padding: 0,
                    }}
                >
                    <SolidTile
                        isEditing={false}
                        tileColor={theme.palette.common.darkTeal}
                        contentSX={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            sx={{ color: "common.white" }}
                            variant="body1"
                            component="p"
                        >
                            Could we do better?{" "}
                            <Button
                                onClick={onPromptClick}
                                sx={{ padding: "0px 0px 3px 0px" }}
                            >
                                <Typography
                                    sx={{
                                        color: "common.white",
                                        textDecoration: "underline",
                                        textTransform: "none",
                                    }}
                                    variant="body1"
                                    component="p"
                                >
                                    Let us know
                                </Typography>
                            </Button>{" "}
                            how to improve your dashboard. Feedback is quick and
                            anonymous
                        </Typography>
                        <Button
                            onClick={onPromptClick}
                            disabled={isEditing}
                            variant="contained"
                            sx={{
                                ...buttonSX(theme),
                                backgroundColor: "primary.main",
                                color: "primary.contrastText",
                                m: 1,
                                ":hover": {
                                    backgroundColor: alpha(
                                        theme.palette.primary.main,
                                        0.5
                                    ),
                                },
                            }}
                        >
                            <Typography variant="h6" component="p">
                                Give Feedback
                            </Typography>
                        </Button>
                    </SolidTile>
                </Grid>
            )}
        </>
    );
};

export default FeedbackTile;
