import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import notificationsClient from "../clients/notificationsClient";
import notificationsMapper from "../mappers/notificationsMapper";

const useNotificationCounter = () => {
    const { throwAsyncError } = useErrorHandling();

    const { data: notificationCounter, error: notificationCounterError } =
        useSWR(
            "notificationCounter",
            async () => {
                try {
                    const notificationCounterData =
                        await notificationsClient.getNotificationCounter();
                    return notificationsMapper.mapNotificationCounter(
                        notificationCounterData
                    );
                } catch (error) {
                    throwAsyncError(
                        ErrorTypes.NotificationCounterAPIError,
                        "Unable to fetch notification count data",
                        error
                    );
                    throw new Error("Unable to fetch notification count data");
                }
            },
            { fallbackData: null }
        );

    return {
        notificationCounter,
        errorMessage: notificationCounterError?.message,
    };
};

export default useNotificationCounter;
