import {
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const useTileCustomisation = (tileCollection) => {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 5 },
        }),
        useSensor(TouchSensor, {
            activationConstraint: { delay: 150, tolerance: 100 },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const reorderTiles = (event) => {
        const { active, over } = event;
        if (
            active?.id === "visibleTilesPlaceholder" ||
            active?.id === "hiddenTilesPlaceholder"
        ) {
            return;
        }
        tileCollection.resetTransforms();
        if (active && over && active.id !== over.id) {
            const activeIndex = tileCollection.currentTileOrder.indexOf(
                active.id
            );
            const overIndex = tileCollection.currentTileOrder.indexOf(over.id);
            if (overIndex > -1 || over.id === "visibleTilesPlaceholder") {
                if (activeIndex === -1) {
                    tileCollection.addTile(active.id);
                }
                tileCollection.reorderTiles(active.id, over.id);
            } else if (activeIndex > -1) {
                tileCollection.removeTile(active.id);
            }
        }
    };

    const updateTiles = (event) => {
        const { active, over } = event;
        if (
            active?.id === "visibleTilesPlaceholder" ||
            active?.id === "hiddenTilesPlaceholder"
        ) {
            return;
        }
        if (active && over) {
            tileCollection.updateTransforms(active.id, over.id);
        }
    };

    const handleRemoveTile = (tileId) => {
        tileCollection.resetTransforms();
        tileCollection.removeTile(tileId);
    };

    const handleAddTile = (tileId) => {
        tileCollection.resetTransforms();
        tileCollection.addTile(tileId);
    };

    const sortingStrategy = (args) => {
        const { activeIndex, index, rects } = args;
        if (
            activeIndex === tileCollection.currentTileOrder.length ||
            (rects && activeIndex === rects.length - 1)
        ) {
            return rects[index];
        }
        if (index > tileCollection.currentTileOrder.length) {
            return rects[index];
        }
        return verticalListSortingStrategy(args);
    };

    return {
        sensors,
        reorderTiles,
        updateTiles,
        handleRemoveTile,
        handleAddTile,
        sortingStrategy,
    };
};

export default useTileCustomisation;
