import { Card, Grid, Link, Typography } from "@mui/material";
import {
    formatDateTime,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import ButtonArea from "components/forms/buttonArea";
import TextInput from "components/forms/textInput";
import { ConfirmationDialog } from "components/modals/confirmationDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useDocumentProcessor from "services/apiUtils/hooks/useDocumentProcessing";
import { triggerSnackbar } from "utils/alertsUtils";
import { validateHttpsUrl } from "utils/urlUtils";
import Chip from "@mui/material/Chip";

const DocumentProcessorAdminCard = () => {
    const {
        triggerSharepointScraping,
        addToCognitiveSearch,
        deleteFromCognitiveSearch,
        latestScrapedDate,
    } = useDocumentProcessor();
    const [requestPending, setRequestPending] = useState(false);
    const dispatch = useDispatch();
    const { borderSX } = useHighContrastBorderSX();
    const [documentUrl, setDocumentUrl] = useState("");

    const validateSharepointUrl = () => {
        const isValid =
            validateHttpsUrl(documentUrl) &&
            (new URL(documentUrl).hostname.endsWith(".sharepoint.com") ||
                new URL(documentUrl).hostname.endsWith(
                    ".sharepoint.com.mcas.ms"
                ));
        if (!isValid) {
            triggerSnackbar(
                "info",
                "Please make sure the document url is a valid sharepoint url",
                null,
                dispatch
            );
        }
        return isValid;
    };

    const [
        openSharepointScrapingConfirmationDialog,
        setOpenSharepointScrapingConfirmationDialog,
    ] = useState(false);
    const onClickTriggerSharepointScraping = () =>
        setOpenSharepointScrapingConfirmationDialog(true);

    const [
        openAddToCognitiveSearchConfirmationDialog,
        setOpenAddToCognitiveSearchConfirmationDialog,
    ] = useState(false);
    const onClickAddToCognitiveSearch = () => {
        validateSharepointUrl() &&
            setOpenAddToCognitiveSearchConfirmationDialog(true);
    };

    const [
        openRemoveFromCognitiveSearchConfirmationDialog,
        setOpenRemoveFromCognitiveSearchConfirmationDialog,
    ] = useState(false);
    const onClickRemoveFromCognitiveSearch = () => {
        validateSharepointUrl() &&
            setOpenRemoveFromCognitiveSearchConfirmationDialog(true);
    };

    const submit = (action, setOpenDialog, onSuccess) => async () => {
        setRequestPending(true);
        await action(documentUrl, onSuccess);
        setOpenDialog(false);
        setRequestPending(false);
    };
    return (
        <Grid xs={12} md={6} item>
            <Card sx={{ padding: "16px", ...borderSX }}>
                <Typography
                    sx={{ margin: "0 0 20px 0" }}
                    color="text.primary"
                    variant="h2"
                    component="p"
                    textAlign="left"
                    alignItems="center"
                >
                    Edison SharePoint Document Processing Admin
                </Typography>
                <Chip
                    size="small"
                    label={
                        <Typography
                            sx={{
                                whiteSpace: "normal",
                                textAlign: "center",
                            }}
                        >
                            {`Last Scraped: ${
                                latestScrapedDate
                                    ? formatDateTime(latestScrapedDate)
                                    : "Timestamp could not be retrieved"
                            }`}
                        </Typography>
                    }
                    sx={{
                        height: "100%",
                        mb: 2,
                        borderRadius: "1px",
                        "& .MuiChip-label": {
                            padding: "0px",
                        },
                    }}
                />
                <Typography
                    color="text.primary"
                    component="p"
                    textAlign="left"
                    alignItems="center"
                >
                    A task is run daily to update Edison&#39;s knowledge
                    database with documents from the configured sharepoint
                    sites. This process can be manually triggered here, or
                    SharePoint pages/documents can be individually ingested or
                    removed from Cosmos and Cognitive Search by their URL. Note
                    the items to be added should already have access permissions
                    applied to the sites they are in for the ingestion to be
                    successful.
                </Typography>
                <ButtonArea
                    buttonList={[
                        {
                            text: "Trigger sharepoint scraping",
                            onClick: onClickTriggerSharepointScraping,
                            dataTrackId:
                                "sharepoint-admin-trigger-sharepoint-scraping",
                            disabled: requestPending,
                        },
                    ]}
                />
                <TextInput
                    labelText="Document/Page URL"
                    subLabelText="Please provide the URL from the &#39;Copy link&#39; button on Sharepoint and not the URL from the browser address bar."
                    dataTrackId="sharepoint-admin-document-url-input"
                    id="sharepoint-admin-document-url-input"
                    value={documentUrl}
                    onChange={(event) => setDocumentUrl(event.target.value)}
                />
                <ButtonArea
                    buttonList={[
                        {
                            text: "Add",
                            onClick: onClickAddToCognitiveSearch,
                            dataTrackId:
                                "sharepoint-admin-add-document-to-cognitive-search",
                            disabled: requestPending,
                        },
                        {
                            text: "Remove",
                            onClick: onClickRemoveFromCognitiveSearch,
                            dataTrackId:
                                "sharepoint-admin-remove-document-from-cognitive-search",
                            disabled: requestPending,
                        },
                    ]}
                />
                <ConfirmationDialog
                    dialogTitle="Are you sure you want to trigger sharepoint scraping?"
                    open={openSharepointScrapingConfirmationDialog}
                    onCancel={() =>
                        setOpenSharepointScrapingConfirmationDialog(false)
                    }
                    onConfirm={() => {
                        triggerSharepointScraping();
                        setOpenSharepointScrapingConfirmationDialog(false);
                    }}
                    actionInProgress={requestPending}
                />
                <ConfirmationDialog
                    dialogTitle="Are you sure you want to add this document to Edison's knowledge database?"
                    open={openAddToCognitiveSearchConfirmationDialog}
                    onCancel={() =>
                        setOpenAddToCognitiveSearchConfirmationDialog(false)
                    }
                    onConfirm={submit(
                        addToCognitiveSearch,
                        setOpenAddToCognitiveSearchConfirmationDialog,
                        () => setDocumentUrl("")
                    )}
                    actionInProgress={requestPending}
                >
                    <Typography sx={{ wordWrap: "break-word" }}>
                        The link you entered:{" "}
                        <Link
                            target="_blank"
                            href={documentUrl}
                            color="text.primary"
                        >
                            {documentUrl}
                        </Link>
                    </Typography>
                </ConfirmationDialog>
                <ConfirmationDialog
                    dialogTitle="Are you sure you want to remove this document from Edison's knowledge database?"
                    open={openRemoveFromCognitiveSearchConfirmationDialog}
                    onCancel={() =>
                        setOpenRemoveFromCognitiveSearchConfirmationDialog(
                            false
                        )
                    }
                    onConfirm={submit(
                        deleteFromCognitiveSearch,
                        setOpenRemoveFromCognitiveSearchConfirmationDialog,
                        () => setDocumentUrl("")
                    )}
                    actionInProgress={requestPending}
                >
                    <Typography sx={{ wordWrap: "break-word" }}>
                        The link you entered:{" "}
                        <Link target="_blank" href={documentUrl}>
                            {documentUrl}
                        </Link>
                    </Typography>
                </ConfirmationDialog>
            </Card>
        </Grid>
    );
};

export default DocumentProcessorAdminCard;
