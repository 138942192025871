import * as React from "react";
import * as PropTypes from "prop-types";
import TaskActionButton from "../../buttons/taskActionButton";
import { CardActions } from "@mui/material";
import useServiceNowApprovals from "../../../services/apiUtils/hooks/useServiceNowApprovals";
import BaseTaskCard from "../baseTaskCard";
import { formatDate } from "@sse-digital-services/design-system";

const ServiceNowTaskCard = ({ id, isDashboard }) => {
    const { serviceNowApprovalById } = useServiceNowApprovals();
    const { requester, url, requestedFor, createdDate } =
        serviceNowApprovalById(id);
    const rows = [
        { field: "Request", value: id },
        { field: "Requested by", value: requester },
    ];
    !isDashboard &&
        rows.push(
            { field: "Requested for", value: requestedFor },
            { field: "Submitted", value: formatDate(createdDate) }
        );
    return (
        <BaseTaskCard
            fields={rows}
            title={`Approval Request`}
            isDashboard={isDashboard}
        >
            <CardActions sx={{ px: 0 }}>
                <TaskActionButton
                    mode="neutral"
                    action="View in ITSC"
                    url={`https://${url}`}
                />
            </CardActions>
        </BaseTaskCard>
    );
};

ServiceNowTaskCard.propTypes = {
    id: PropTypes.string.isRequired,
    isDashboard: PropTypes.bool,
};

export default ServiceNowTaskCard;
