import {
    dashboardTilesMap,
    quickActionTilesMap,
} from "components/tiles/tilesMap";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setIsEditing } from "../../../redux/actions/generalActions";
import usePreferences from "services/apiUtils/hooks/usePreferences";
import useTileCollection from "../../tiles/useTileCollection";

const useHomePage = () => {
    const dashboardTileCollection = useTileCollection(
        "Dashboard",
        dashboardTilesMap
    );
    const quickActionTileCollection = useTileCollection(
        "Quick Links",
        quickActionTilesMap
    );

    const dispatch = useDispatch();
    const { updateTilePreferences } = usePreferences();

    const saveTileLayout = useCallback(async () => {
        dispatch(setIsEditing(false));
        const newDashboardTilePreferences =
            dashboardTileCollection.getTilePreferencesToSave();
        const newQuickActionTilePreferences =
            quickActionTileCollection.getTilePreferencesToSave();
        await updateTilePreferences({
            ...newDashboardTilePreferences,
            ...newQuickActionTilePreferences,
        });
        dashboardTileCollection.updateTilePreferences(
            newDashboardTilePreferences
        );
        quickActionTileCollection.updateTilePreferences(
            newQuickActionTilePreferences
        );
    }, [
        dashboardTileCollection.getTilePreferencesToSave,
        quickActionTileCollection.getTilePreferencesToSave,
        dashboardTileCollection.updateTilePreferences,
        quickActionTileCollection.updateTilePreferences,
        updateTilePreferences,
        dispatch,
    ]);

    return {
        dashboardTileCollection,
        quickActionTileCollection,
        saveTileLayout,
    };
};

export default useHomePage;
