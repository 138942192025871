export const countAPIItems = (items) => {
    if (items.length > 0 && items[0]?.count) {
        return items[0].count;
    }
    return 0;
};

export const reduceAPIItems = (items, fallback) => {
    if (items.length > 0) {
        return items.reduce((result, page) => {
            if (page) return [...result, ...page.data];
            else return result;
        }, fallback);
    }
    return fallback;
};
