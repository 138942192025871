import React from "react";
import PropTypes from "prop-types";
import { SERVICENOW } from "../../../constants/serviceNames";
import BaseRow from "../../tables/baseRow";
import SummaryRow from "../../tables/summaryRow";
import { formatDateTime } from "@sse-digital-services/design-system";

const ItscIncidentsRow = ({ item, isEditing }) => {
    let incidentDetails = [
        {
            label: "Description",
            boldLabel: true,
            value: item.description,
        },
        {
            label: "Affected User",
            boldLabel: true,
            value: item.affectedUser,
        },
        {
            label: "Urgency",
            boldLabel: true,
            value: item.urgency,
        },
        {
            label: "Updated on",
            boldLabel: true,
            value: formatDateTime(item.updatedDate),
        },
        {
            label: "Created on",
            boldLabel: true,
            value: formatDateTime(item.createdDate),
        },
    ];

    return (
        <BaseRow itemDetails={incidentDetails}>
            <SummaryRow
                item={item}
                service={SERVICENOW}
                isEditing={isEditing}
            />
        </BaseRow>
    );
};
ItscIncidentsRow.propTypes = {
    item: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

export default ItscIncidentsRow;
