// Service name constants as per preferences API
export const MANAGER = "shopfrontManager";
export const ADMIN = "shopfrontAdministrator";
export const EDISON_ADMIN = "edisonAdmin";
export const PROCUREMENT = "procurement";
export const SHAREPOINT = "sharepoint";
export const SEARS = "sears";
export const PAYSLIPS = "payslips";
export const SERVICENOW = "servicenow";
export const DEMO = "demo";
export const QUICKACTIONS = "quickactions";
export const PLANONFM = "planonFM";
export const EXPENSES = "expenses";
export const SEARCHASSISTANT = "searchAssistant";
export const TRAVELHUB = "travelHub";
export const NUDGE = "nudge";
export const DATAMARKETPLACE = "dataMarketplace";
export const FIELDGLASS = "fieldglass";
export const FIELDGLASS_NON_SSO = "fieldglassNonSSO";
export const ADVANTAGE = "advantage";
export const SPOTLIGHT = "spotlight";
export const JAGGAER = "jaggaer";
export const ANNUALLEAVE = "annualLeave";
export const LXP = "lxp";

// Semi service
export const PROCURETOPAY = "procuretopay";

// Service names for display
export const serviceNames = {
    [SHAREPOINT]: "SSENet",
    [PROCUREMENT]: "iBuy",
    [PAYSLIPS]: "Payslips",
    [SEARS]: "SEARS",
    [SERVICENOW]: "ITSC",
    [DEMO]: "Demo",
    [PLANONFM]: "AskFM",
    [EXPENSES]: "Expenses",
    [SEARCHASSISTANT]: "Search Assistant",
    [QUICKACTIONS]: "Quick Actions",
    [PROCURETOPAY]: "Procure To Pay",
    [TRAVELHUB]: "Travel Hub",
    [NUDGE]: "Nudge",
    [MANAGER]: "Manager",
    [ADMIN]: "Admin",
    [EDISON_ADMIN]: "Edison Admin",
    [DATAMARKETPLACE]: "Data Marketplace",
    [FIELDGLASS]: "SSO Fieldglass",
    [FIELDGLASS_NON_SSO]: "Fieldglass",
    [ADVANTAGE]: "Advantage",
    [SPOTLIGHT]: "Spotlight",
    [JAGGAER]: "Jaggaer",
    [ANNUALLEAVE]: "Annual Leave",
    [LXP]: "My Learning",
};
