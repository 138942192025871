import PropTypes from "prop-types";
import * as React from "react";
import { JAGGAER_SSO_LINK } from "../../../constants/externalUrls";
import QuickActionTile from "../templates/quickActionTile";
import SingleLinkTile from "../templates/singleLinkTile";
import { quickActionTilesMap } from "../tilesMap";

const JaggaerTile = ({ id }) => {
    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={JAGGAER_SSO_LINK}
                linkText={quickActionTilesMap[id].description}
                title={"Jaggaer SSO"}
            />
        </QuickActionTile>
    );
};

JaggaerTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default JaggaerTile;
