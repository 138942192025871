import useNPS from "../../../services/apiUtils/hooks/useNPS";
import React, { useEffect } from "react";
import { Button, IconButton, Slide, useMediaQuery } from "@mui/material";
import {
    Close,
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styledDashboardTileActionButtonSX } from "../../../styled/styledSXProperties/styledDashboardTileCardSX";
import { useDispatch, useSelector } from "react-redux";
import {
    setShowFeedbackForm,
    setShowFeedbackPrompt,
} from "../../../redux/actions/generalActions";

const NpsBanner = () => {
    const { shouldSeekFeedback, setCheckFeedbackAPIState } = useNPS();
    const showPrompt = useSelector((state) => state.general.showFeedbackPrompt);
    const checkFeedbackAPIState = useSelector(
        (state) => state.general.checkFeedbackAPI
    );
    const { borderSX } = useHighContrastBorderSX();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const { handheld } = getDeviceInfo();
    const dispatch = useDispatch();

    const checkAPITimerEnded = () => {
        if (isDesktopWidth && !handheld) {
            setCheckFeedbackAPIState(true);
        }
    };

    const setShowPrompt = (newShowPrompt) => {
        dispatch(setShowFeedbackPrompt(newShowPrompt));
    };

    const setShowForm = (newShowForm) => {
        dispatch(setShowFeedbackForm(newShowForm));
    };

    useEffect(() => {
        if (!checkFeedbackAPIState) {
            const timeout = setTimeout(() => {
                checkAPITimerEnded();
            }, 10 * 1000);
            return () => clearTimeout(timeout);
        }
    }, []);

    const onPromptClick = () => {
        setShowPrompt(false);
        setShowForm(true);
    };

    const onPromptClose = () => {
        setShowPrompt(false);
    };

    const shouldShow =
        shouldSeekFeedback && showPrompt && isDesktopWidth && !handheld;

    return (
        <Slide direction="up" in={shouldShow} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    position: "sticky",
                    bottom: 0,
                    zIndex: "headerBar",
                }}
            >
                <Paper
                    sx={{
                        ...borderSX,
                        backgroundColor: "common.darkTeal",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "0px",
                        boxSizing: "border-box",
                        position: "sticky",
                        padding: 0,
                        bottom: 0,
                        zIndex: "headerBar",
                    }}
                >
                    <Typography
                        sx={{ color: "common.white" }}
                        variant={"h6"}
                        component="p"
                    >
                        Could we do better?{" "}
                        <Button
                            data-track-id="nps-banner-button"
                            onClick={onPromptClick}
                            sx={{ padding: "0px 0px 3px 0px" }}
                        >
                            <Typography
                                sx={{
                                    color: "common.white",
                                    textDecoration: "underline",
                                    textTransform: "none",
                                }}
                                variant={"h6"}
                                component="p"
                            >
                                Let us know
                            </Typography>
                        </Button>{" "}
                        how to improve your dashboard. Feedback is quick and
                        anonymous
                    </Typography>
                    <IconButton
                        data-track-id="nps-banner-close"
                        aria-label="close"
                        onClick={onPromptClose}
                        size="small"
                        sx={{ padding: "16px" }}
                    >
                        <Close sx={styledDashboardTileActionButtonSX} />
                    </IconButton>
                </Paper>
            </Box>
        </Slide>
    );
};

export default NpsBanner;
