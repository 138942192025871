import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};

const notificationsAPIService = apiService(
    apiConfig.notifications,
    apiHeaderConfig
);

const npsClient = {
    shouldShowFeedbackForm: () => notificationsAPIService.get("nps-prompt"),
    getAverageRating: () => notificationsAPIService.get("nps-response"),
    getFeedback: (params) =>
        notificationsAPIService.get("nps-response-list", params),
    postFeedback: (feedbackData) =>
        notificationsAPIService.post("nps-response", feedbackData),
};

export default npsClient;
