import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};

const preferencesAPIService = apiService(
    apiConfig.preferences,
    apiHeaderConfig
);

const preferencesClient = {
    getShopfrontPreferences: () => preferencesAPIService.get("shopfront"),
    updateShopfrontPreferences: (request) =>
        preferencesAPIService.post("shopfront", request),
};

export default preferencesClient;
