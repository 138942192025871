import React from "react";

import { IconButton, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Close, Search } from "@sse-digital-services/design-system";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const SearchField = ({ searchTerm, setSearchTerm, boxSx = {} }) => {
    const [searchFocus, setSearchFocus] = React.useState(false);
    return (
        <Box
            sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                alignItems: "center",
                ...boxSx,
            }}
        >
            <TextField
                onFocus={() => setSearchFocus(true)}
                onBlur={() => setSearchFocus(false)}
                sx={{
                    height: "34px",
                    width: "100%",
                    justifyContent: "center",
                    "& .MuiOutlinedInput-notchedOutline": {
                        color: "icon.secondary",
                        border: "1px solid",
                    },
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "text.emphasis",
                        },
                        "&:hover fieldset": {
                            borderColor: "text.emphasis",
                        },
                    },
                    input: {
                        "&::placeholder": {
                            opacity: 0.5,
                        },
                    },
                }}
                InputProps={{
                    style: { borderRadius: "1px", padding: "4px" },
                    startAdornment: !searchFocus && !searchTerm && (
                        <InputAdornment position="start">
                            <Search
                                sx={{ color: "icon.secondary", opacity: 0.5 }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: searchTerm && (
                        <IconButton
                            sx={{ py: "0px" }}
                            data-track-id="clear search"
                            aria-label="clear search"
                            onClick={() => setSearchTerm("")}
                        >
                            <Close sx={{ color: "icon.secondary" }} />
                        </IconButton>
                    ),
                }}
                placeholder={searchFocus ? "" : "Search"}
                aria-describedby="search-helper-text"
                inputProps={{
                    "aria-label": "search term input",
                    style: { padding: "4px" },
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
            />
        </Box>
    );
};

SearchField.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    boxSx: PropTypes.object,
};
export default SearchField;
