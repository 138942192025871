import { useEffect, useState } from "react";
import usePWAInstall from "./usePWAInstall";

const useServiceWorker = () => {
    const [swRegistered, setSwRegistered] = useState(false);
    const [registeredSW, setRegisteredSW] = useState();
    const { alreadyInPWA } = usePWAInstall();

    useEffect(() => {
        (async () => {
            if (!swRegistered && "serviceWorker" in navigator) {
                const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
                const serviceWorker =
                    await navigator.serviceWorker.register(swUrl);
                setRegisteredSW(serviceWorker);
                setSwRegistered(true);
                if (alreadyInPWA) {
                    const registration = await navigator.serviceWorker.ready;
                    if (registration?.periodicSync?.register) {
                        await registration.periodicSync.register(
                            "update-notifications-and-tasks",
                            { minInterval: 60 * 1000 }
                        );
                    }
                }
            }
        })();
    }, []);

    return { registeredSW };
};

export default useServiceWorker;
