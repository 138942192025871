import PropTypes from "prop-types";
import * as React from "react";
import QuickActionTile from "../templates/quickActionTile";
import { TRAVEL_HUB } from "../../../constants/externalUrls";
import SingleLinkTile from "../templates/singleLinkTile";
import { quickActionTilesMap } from "../tilesMap";

const TravelHubTile = ({ id }) => {
    return (
        <QuickActionTile id={id}>
            <SingleLinkTile
                link={TRAVEL_HUB}
                linkText={quickActionTilesMap[id].description}
                title={"Travel Hub"}
            />
        </QuickActionTile>
    );
};

TravelHubTile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default TravelHubTile;
