import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};
const profileAPIService = apiService(apiConfig.userProfile, apiHeaderConfig);

const userProfileClient = {
    getUserProfile: () => profileAPIService.get("profile"),
};

export default userProfileClient;
