import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {
    ArrowDown,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const MultiSelectFilter = ({
    filterKey,
    onChange,
    filterOptions,
    currentFilters,
    filterName = null,
    itemCounts = {},
    displayNames = {},
    buttonSx = {},
    icon = null,
}) => {
    const { borderSX } = useHighContrastBorderSX();
    return (
        <FormControl size="small" fullWidth variant={"filled"}>
            <Select
                IconComponent={ArrowDown}
                sx={{
                    ...buttonSx,
                    alignItems: "center",
                    alignContent: "center",
                    "& .MuiFilledInput-input": {
                        padding: "5px",
                    },
                    "& .MuiSvgIcon-root": {
                        color: "icon.secondary",
                    },
                }}
                inputProps={{
                    "aria-label": `${filterKey}-multiple-checkbox-input`,
                }}
                labelId={`${filterKey}-multiple-checkbox-label}`}
                id={`${filterKey}-multiple-checkbox`}
                multiple
                value={currentFilters[filterKey]}
                onChange={onChange}
                displayEmpty
                disableUnderline
                renderValue={() => {
                    return (
                        <Box sx={{ display: "flex", gap: 1 }}>
                            {icon}
                            <Typography
                                sx={{
                                    alignItems: "center",
                                    display: "flex",
                                }}
                                variant="body3"
                                component="p"
                            >
                                {`Filter by ${filterName ?? filterKey} ${
                                    currentFilters[filterKey]?.length
                                        ? `(${currentFilters[filterKey]?.length})`
                                        : ""
                                }`}
                            </Typography>
                        </Box>
                    );
                }}
                MenuProps={{
                    MenuListProps: {
                        sx: borderSX,
                    },
                }}
            >
                <MenuItem value={"clear"} sx={{ padding: "9px", mx: "5px" }}>
                    <ListItemText
                        sx={{ textDecoration: "underline" }}
                        primary={"Clear"}
                    />
                </MenuItem>
                {filterOptions[filterKey]?.map((item) => (
                    <MenuItem
                        disabled={!itemCounts[item]}
                        key={item}
                        value={item}
                        sx={{ padding: 0, mx: 0 }}
                    >
                        <Checkbox
                            sx={{
                                color: "icon.secondary",
                                "&.Mui-checked": {
                                    color: "icon.secondary",
                                },
                            }}
                            checked={
                                currentFilters[filterKey]?.indexOf(item) > -1
                            }
                        />
                        <ListItemText
                            primary={`${displayNames[item] ?? item} ${
                                itemCounts[item]
                                    ? `(${itemCounts[item]})`
                                    : "(0)"
                            }`}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

MultiSelectFilter.propTypes = {
    filterKey: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    filterOptions: PropTypes.object.isRequired,
    currentFilters: PropTypes.object.isRequired,
    itemCounts: PropTypes.object,
    displayNames: PropTypes.object,
    buttonSx: PropTypes.object,
    icon: PropTypes.node,
    filterName: PropTypes.string,
};

export default MultiSelectFilter;
