import { useDispatch, useSelector } from "react-redux";
import { AlertSnackbar } from "@sse-digital-services/design-system";
import { setSnackbarQueue } from "../../../redux/actions/alertsActions";

const SfSnackBar = () => {
    const snackbarQueue = useSelector((state) => state.alerts.snackbarQueue);
    const dispatch = useDispatch();
    return (
        <AlertSnackbar
            snackbarQueue={snackbarQueue}
            setSnackbarQueue={(newQueue) =>
                dispatch(setSnackbarQueue(newQueue))
            }
        />
    );
};

export default SfSnackBar;
