import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};

const notificationsAPIService = apiService(
    apiConfig.notifications,
    apiHeaderConfig
);

const notificationsClient = {
    getNotifications: (params, requestHeaders) =>
        notificationsAPIService.get("notification", params, requestHeaders),
    updateNotificationStatus: (request) =>
        notificationsAPIService.post("notification", request),
    getLatestPayslip: (requestHeaders) =>
        notificationsAPIService.get(
            "latest-payslip-notification",
            null,
            requestHeaders
        ),
    getNotificationCounter: () =>
        notificationsAPIService.get("notification-counter"),
    postAdminNotification: (notificationData) =>
        notificationsAPIService.post("notification-admin", notificationData),
};

export default notificationsClient;
