import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};

const accessibilityAPIService = apiService(
    apiConfig.preferences,
    apiHeaderConfig
);

const accessibilityClient = {
    getShopfrontAccessibilitySettings: () =>
        accessibilityAPIService.get("accessibility"),
    updateShopfrontAccessibilitySettings: (request) =>
        accessibilityAPIService.patch("accessibility", request),
};

export default accessibilityClient;
