import { formatDate } from "@sse-digital-services/design-system";

export const getCreatedTimeLabel = (createdTimestampInSeconds) => {
    const timeFormatter = new Intl.RelativeTimeFormat("en", { style: "long" });
    const now = Math.floor(Date.now() / 1000);
    const secondsAgo = now - createdTimestampInSeconds;
    const minutesAgo = Math.floor(secondsAgo / 60);
    if (minutesAgo < 60) {
        return timeFormatter.format(-1 * minutesAgo, "minutes");
    }
    const hoursAgo = Math.floor(secondsAgo / (60 * 60));
    if (hoursAgo > 0 && hoursAgo < 24) {
        return timeFormatter.format(-1 * hoursAgo, "hours");
    }
    const daysAgo = Math.floor(secondsAgo / (60 * 60 * 24));
    if (daysAgo > 0 && daysAgo < 7) {
        return timeFormatter.format(-1 * daysAgo, "days");
    }
    return formatDate(createdTimestampInSeconds * 1000);
};

export const daysBetweenDates = (firstDate, secondDate) => {
    // Parse the input strings as dates
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);

    const timeDifference = date2 - date1;

    // Convert milliseconds to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return Math.round(daysDifference);
};
