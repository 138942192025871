import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import * as routes from "../../../constants/routes";
import { useLocation } from "react-router-dom";
import useNavigationItems from "../../navigation/useNavigationItems";
import {
    DESKTOP_MEDIA_QUERY,
    getDeviceInfo,
    PageLayout,
} from "@sse-digital-services/design-system";
import CreateNotificationCard from "./createNotificationCard";
import { GRID_SPACING } from "constants/tileGridConstants";
import UserFeedbackResultsCard from "./userFeedbackResultsCard";
import DocumentProcessorAdminCard from "./documentProcessorAdminCard";
import usePreferences from "../../../services/apiUtils/hooks/usePreferences";

const AdminPage = () => {
    const location = useLocation();
    const { navItems } = useNavigationItems();
    const { isManager, isAdmin } = usePreferences();

    const { handheld } = getDeviceInfo();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const gridPadding =
        isDesktopWidth && !handheld ? "0 30px 0 14px" : "0 16px 0 0";

    return (
        <Box>
            <PageLayout
                homeName="Dashboard"
                homeRoute={routes.HOMEPAGE}
                currentPath={location.pathname}
                navItems={navItems}
            >
                <Grid
                    sx={{
                        margin: 0,
                        width: "100%",
                        padding: gridPadding,
                    }}
                    container
                    spacing={GRID_SPACING}
                    columns={12}
                    alignItems="stretch"
                >
                    {isManager && <UserFeedbackResultsCard />}
                    {isAdmin && (
                        <>
                            <CreateNotificationCard />
                            <DocumentProcessorAdminCard />
                        </>
                    )}
                </Grid>
            </PageLayout>
        </Box>
    );
};

export default AdminPage;
