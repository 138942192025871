import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
    ReactPlugin,
    withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { createBrowserHistory } from "history";
import { APP_NAME } from "./constants/generalConstants";

// eslint-disable-next-line no-undef
const appInsightKey = GLOBAL_CONFIG.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;
const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const clickPlugin = new ClickAnalyticsPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: appInsightKey,
        extensions: [reactPlugin, clickPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
            [clickPlugin.identifier]: {
                autoCapture: true,
                dataTags: {
                    customDataPrefix: "data-track-",
                    parentDataTag: "parent-group",
                    useDefaultContentNameOrId: true,
                },
                defaultRightClickBhvr: "rc",
            },
        },
        disableCookiesUsage: false,
        isStorageUseDisabled: false,
        autoTrackPageVisitTime: true,
    },
});
const roleName = APP_NAME + "-FE";
ai.addTelemetryInitializer((envelope) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": roleName });
});

ai.loadAppInsights();
export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
