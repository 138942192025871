import React, { useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setShowInstallDialog } from "../../../redux/actions/pwaActions";
import Avatar from "@mui/material/Avatar";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { Button, IconButton, useTheme } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
    Close,
    HIGH_CONTRAST_THEME,
    Logo,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import { styledDashboardTileActionButtonSX } from "../../../styled/styledSXProperties/styledDashboardTileCardSX";
import { daysBetweenDates } from "../../../utils/dateUtils";
import MoreInfoModal from "../moreInfoModal";
import { triggerSnackbar } from "../../../utils/alertsUtils";
import { MoreInfoButton } from "components/buttons/moreInfoButton";
import { buttonSX } from "styled/styledSXProperties/styledUserInput";
import { APP_NAME } from "../../../constants/generalConstants";

const InstallPWADialog = () => {
    const uaParser = new UAParser(navigator?.userAgent);
    const browser = uaParser.getBrowser();
    const platform = uaParser.getOS();
    const desktop =
        platform.name.search(/windows/i) >= 0 ||
        platform.name.search(/mac os/i) >= 0;
    const ios = platform.name.search(/ios/i) >= 0;
    const android = platform.name.search(/android/i) >= 0;
    const chrome = browser.name.search(/chrome/i) >= 0;
    const edge = browser.name.search(/edge/i) >= 0;
    const safari = browser.name.search(/safari/i) >= 0;
    const supportedNonIos =
        (desktop && (chrome || edge)) || (android && chrome);
    const supportedIos = ios && safari;
    const showInstallDialog = useSelector(
        (state) => state.pwa.showInstallDialog
    );
    const [installPromptAlreadyShown, setInstallPromptAlreadyShown] =
        useState(true);
    const alreadyInPWA =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator?.standalone;

    const deferredPrompt = useSelector((state) => state.pwa.deferredPrompt);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { borderSX } = useHighContrastBorderSX();
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);

    const currentDate = new Date().toISOString();

    useEffect(() => {
        if (!alreadyInPWA) {
            const storedPrompt = localStorage.getItem("pwaPrompt");
            const installPromptShown = Boolean(
                storedPrompt && daysBetweenDates(storedPrompt, currentDate) < 90
            );
            setInstallPromptAlreadyShown(installPromptShown);
        }
    }, []);

    const closePrompt = () => {
        dispatch(setShowInstallDialog(false));
        localStorage.setItem("pwaPrompt", currentDate);
        setInstallPromptAlreadyShown(true);
        triggerSnackbar(
            "info",
            "If you change your mind, you can always install the app from your settings menu!",
            null,
            dispatch
        );
    };

    // Calculate description (text) and action (button or instructions) component based on browser and platform
    const title = `Install ${APP_NAME} for an app-like experience`;
    let content;
    if (supportedNonIos) {
        content = (
            <Button
                onClick={() => {
                    deferredPrompt.prompt();
                    closePrompt();
                }}
                variant="contained"
                sx={{
                    ...borderSX,
                    ...buttonSX(theme),
                    m: "16px",
                    pl: "6px",
                    pr: "6px",
                }}
            >
                Start Install
            </Button>
        );
    } else if (supportedIos) {
        content = (
            <Typography>
                To install, click on the <IosShareIcon /> icon and then select
                Add to Home Screen
            </Typography>
        );
    }

    return (
        <>
            <Dialog
                open={
                    !installPromptAlreadyShown &&
                    ((showInstallDialog && deferredPrompt) || supportedIos) &&
                    !moreInfoModalOpen
                }
                onClose={() => closePrompt()}
                PaperProps={{
                    sx: borderSX,
                }}
            >
                <DialogTitle>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "16px",
                        }}
                    >
                        {theme.name !== HIGH_CONTRAST_THEME && (
                            <Avatar
                                sx={{
                                    m: 2,
                                    bgcolor: theme.palette.icon.secondary,
                                    color: theme.palette.icon.main,
                                }}
                                variant="rounded"
                            >
                                <Logo
                                    sx={{
                                        height: "30px",
                                        width: "30px",
                                    }}
                                />
                            </Avatar>
                        )}
                        <Typography variant="h4" component="p">
                            {title}
                            <MoreInfoButton
                                ariaLabel="open more info modal"
                                dataTrackId="open pwa info modal"
                                openMoreInfoModal={() =>
                                    setMoreInfoModalOpen(true)
                                }
                            />
                        </Typography>
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={() => closePrompt()}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <Close
                            sx={{
                                ...styledDashboardTileActionButtonSX,
                                backgroundColor: "background.paper",
                            }}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {content}
                </DialogContent>
            </Dialog>
            <MoreInfoModal
                open={moreInfoModalOpen}
                setOpen={setMoreInfoModalOpen}
            />
        </>
    );
};

export default InstallPWADialog;
