// Returns action creators for each general action type
import {
    SET_IS_EDITING,
    SET_CHECK_FEEDBACK_API,
    SET_SHOW_CHAT_FIELD,
    SET_SHOW_FEEDBACK_FORM,
    SET_SHOW_FEEDBACK_PROMPT,
    SET_SHOW_NOTIFICATIONS,
    SET_SHOW_USER_INFO,
    SET_SELECTED_CUSTOMISATION_TAB,
} from "../actionTypes";

export const setShowUserInfo = (isOpen) => {
    return {
        type: SET_SHOW_USER_INFO,
        payload: isOpen,
    };
};
export const setShowNotifications = (isOpen) => {
    return {
        type: SET_SHOW_NOTIFICATIONS,
        payload: isOpen,
    };
};

export const setShowChatField = (isOpen) => {
    return {
        type: SET_SHOW_CHAT_FIELD,
        payload: isOpen,
    };
};

export const setIsEditing = (isEditing) => {
    return {
        type: SET_IS_EDITING,
        payload: isEditing,
    };
};

export const setSelectedCustomisationTab = (selectedTab) => {
    return {
        type: SET_SELECTED_CUSTOMISATION_TAB,
        payload: selectedTab,
    };
};

export const setShowFeedbackForm = (showFeedbackForm) => {
    return {
        type: SET_SHOW_FEEDBACK_FORM,
        payload: showFeedbackForm,
    };
};

export const setShowFeedbackPrompt = (showFeedbackPrompt) => {
    return {
        type: SET_SHOW_FEEDBACK_PROMPT,
        payload: showFeedbackPrompt,
    };
};

export const setCheckFeedbackAPI = (shouldCheckFeedbackAPI) => {
    return {
        type: SET_CHECK_FEEDBACK_API,
        payload: shouldCheckFeedbackAPI,
    };
};
