import { alpha } from "@mui/material";

export const styledQuickTileBoxSx = (theme) => ({
    padding: 0,
    backgroundColor: "background.highlightButton",
    ":hover": {
        backgroundColor: alpha(theme.palette.background.highlightButton, 0.9),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    alignItems: "flex-start",
    "&:last-child": {
        paddingBottom: "0px",
    },
    width: "100%",
});
