import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { styledDividerSX } from "@sse-digital-services/design-system";
import { useSelector } from "react-redux";
import useHomePage from "../../pages/homepage/useHomepage";
import { useLocation } from "react-router-dom";
import * as routes from "../../../constants/routes";
import ContentFooter from "./contentFooter";
import CustomiseTilesSection from "./customiseTilesSection";
import AccessibilitySection from "./accessibilitySection";
import NotificationsSection from "./notificationsSection";
import CustomiseTilesDrawer from "../../customisation/customiseTilesDrawer";
import React from "react";
import PWAInstallSection from "./pwaInstallSection";

const SettingsContent = () => {
    const isEditing = useSelector((state) => state.general.isEditing);
    const location = useLocation();
    const onHomepage = routes.HOMEPAGE === location.pathname;

    const {
        dashboardTileCollection,
        quickActionTileCollection,
        saveTileLayout,
    } = useHomePage();

    return (
        <>
            <Box
                mx="30px"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "98%",
                    overflow: "hidden",
                    flexGrow: 0,
                    flexShrink: 0,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                    }}
                >
                    <Typography
                        variant="h2"
                        component="p"
                        textAlign="left"
                        mt={2}
                    >
                        Settings
                    </Typography>
                </Box>
                <NotificationsSection />
                <Divider
                    sx={{
                        ...styledDividerSX,
                        width: "100%",
                        margin: "30px 0px",
                    }}
                />
                <AccessibilitySection />
                <Divider
                    sx={{
                        ...styledDividerSX,
                        width: "100%",
                        margin: "16px 0px",
                    }}
                />
                <CustomiseTilesSection />
                <PWAInstallSection />
                <Divider
                    sx={{
                        ...styledDividerSX,
                        width: "100%",
                        margin: "16px 0px",
                    }}
                />
                <ContentFooter />
            </Box>
            {!onHomepage && (
                <CustomiseTilesDrawer
                    open={isEditing}
                    onOpen={() => {}}
                    tileCollections={[
                        dashboardTileCollection,
                        quickActionTileCollection,
                    ]}
                    onClose={saveTileLayout}
                />
            )}
        </>
    );
};

export default SettingsContent;
