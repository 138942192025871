import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { styledDividerSX } from "@sse-digital-services/design-system";
import { serviceNames } from "../../../constants/serviceNames";
import usePreferences from "../../../services/apiUtils/hooks/usePreferences";
import SettingsToggle from "./settingsToggle";

const NotificationsSection = () => {
    const { notificationPreferences, updateNotificationPreferences } =
        usePreferences();
    const [notificationSwitch, setNotificationSwitch] = useState({});
    useEffect(() => {
        if (notificationPreferences) {
            setNotificationSwitch(notificationPreferences);
        }
    }, [notificationPreferences]);

    const handleChange = async (event) => {
        setNotificationSwitch({
            ...notificationSwitch,
            [event.target.name]: event.target.checked,
        });
        await updateNotificationPreferences({
            ...notificationSwitch,
            [event.target.name]: event.target.checked,
        });
    };
    return (
        <>
            {Object.keys(notificationSwitch).length !== 0 && (
                <>
                    <Divider
                        sx={{
                            ...styledDividerSX,
                            width: "100%",
                            margin: "30px 0px",
                        }}
                    />
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            component="p"
                            textAlign="left"
                            mb={1}
                        >
                            Notifications
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            justifyContent: "space-between",
                            width: "100%",
                            marginX: 0,
                        }}
                    >
                        <FormControl
                            component="fieldset"
                            variant="standard"
                            sx={{ width: "100%" }}
                        >
                            <FormGroup variant="standard">
                                {Object.keys(notificationSwitch).map(
                                    (service) => (
                                        <SettingsToggle
                                            key={service}
                                            name={service}
                                            trackingId={`${service} notifications ${
                                                notificationSwitch[service]
                                                    ? "on"
                                                    : "off"
                                            }`}
                                            label={serviceNames[service]}
                                            checked={Boolean(
                                                notificationSwitch[service]
                                            )}
                                            handleChange={handleChange}
                                        />
                                    )
                                )}
                            </FormGroup>
                        </FormControl>
                    </Box>
                </>
            )}
        </>
    );
};

export default NotificationsSection;
