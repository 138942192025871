import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useRef } from "react";
import PropTypes from "prop-types";

const BaseTaskButton = ({ row }) => {
    const btnRef = useRef();
    const onClick = () => {
        btnRef.current.focus();
        row.action();
    };

    return (
        <Button
            ref={btnRef}
            aria-label={`${row.field}-detail`}
            data-track-id={`${row.field}-detail`}
            onClick={onClick}
            sx={{ textTransform: "none", p: 0, outline: "revert" }}
            disableRipple={true}
            disableTouchRipple={true}
        >
            <Typography color={"text.primary"}>
                <b>{row.field}:</b> {row.value}
            </Typography>
        </Button>
    );
};

BaseTaskButton.propTypes = {
    row: PropTypes.shape({
        field: PropTypes.string,
        value: PropTypes.string,
        action: PropTypes.func,
    }).isRequired,
};

export default BaseTaskButton;
