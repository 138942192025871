import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DashboardCustomizeOutlined } from "@mui/icons-material";
import {
    setIsEditing,
    setSelectedCustomisationTab,
} from "../../../redux/actions/generalActions";
import { useDispatch } from "react-redux";
import { ButtonBase } from "@mui/material";

const CustomiseTilesSection = () => {
    const dispatch = useDispatch();
    const startEditing = () => {
        dispatch(setSelectedCustomisationTab(0));
        dispatch(setIsEditing(true));
    };
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight="bold"
                    component="p"
                    textAlign="left"
                    mb={1}
                >
                    Customisation
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginY: "5px",
                }}
            >
                <ButtonBase
                    data-track-id={"customise tiles"}
                    aria-label="customise tiles"
                    onClick={startEditing}
                >
                    <DashboardCustomizeOutlined
                        sx={{ color: "icon.secondary", padding: "0 8px 0 0" }}
                    />
                    <Typography variant="body1" component="p">
                        Edit layout
                    </Typography>
                </ButtonBase>
            </Box>
        </>
    );
};

export default CustomiseTilesSection;
