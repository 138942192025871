const sharePriceMapper = {
    mapAPISharePriceToSharePrice: (apiSharePrice) => {
        if (apiSharePrice) {
            return {
                latestPrice: apiSharePrice.CURRENT_PRICE.toLocaleString(
                    "en-GB",
                    { minimumFractionDigits: 2 }
                ),
                change: apiSharePrice.CHANGE.toFixed(2),
                percentageChange: apiSharePrice.PERCENTAGE_CHANGE.toFixed(2),
                currency: apiSharePrice.CURRENCY,
            };
        }
        return {};
    },
};

export default sharePriceMapper;
