import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { styledNotificationCardSX } from "../../../styled/styledSXProperties/styledDashboardTileCardSX";
import PropTypes from "prop-types";
import {
    iBuyMainURL,
    payslipsMainURL,
} from "../../../services/apiUtils/config/urls";
import { useDispatch } from "react-redux";
import { triggerSnackbar } from "../../../utils/alertsUtils";
import {
    PAYSLIPS,
    PROCUREMENT,
    serviceNames,
} from "../../../constants/serviceNames";
import Card from "@mui/material/Card";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import { CardActionArea } from "@mui/material";
import { getCreatedTimeLabel } from "../../../utils/dateUtils";
import Box from "@mui/material/Box";
import TaskActionButton from "../../buttons/taskActionButton";
import { HTTPS_PLACEHOLDER } from "../../../constants/generalConstants";

const notificationsServiceToURL = {
    [PAYSLIPS]: payslipsMainURL,
    [PROCUREMENT]: iBuyMainURL,
};
const NotificationCard = ({
    userViewed,
    resourceUri,
    source,
    item_created_ts,
    data,
}) => {
    const { borderSX } = useHighContrastBorderSX();
    const service = serviceNames[source];
    const dispatch = useDispatch();
    const showMaintenanceWarning = () => {
        if (data.maintenanceMode) {
            triggerSnackbar(
                "warning",
                `${service} is down for maintenance`,
                null,
                dispatch
            );
        }
    };
    const cardSX = {
        ...styledNotificationCardSX,
        ...borderSX,
    };
    const unViewedCardBorderSx = {
        borderLeftWidth: "5px",
        borderLeftStyle: "solid",
        borderLeftColor: "text.emphasis",
        paddingLeft: "10px",
        fontWeight: "bold",
    };
    const cardBorderSx = userViewed ? {} : unViewedCardBorderSx;

    const maintenanceCardsSX = {
        ...cardSX,
        borderColor: "disabled.main",
        border: "1px solid",
    };
    const parsedUrl = `${notificationsServiceToURL[source] ?? ""}${
        resourceUri?.replace(HTTPS_PLACEHOLDER, "https://") ?? ""
    }`;

    const sx = (data.maintenanceMode && maintenanceCardsSX) || cardSX;
    return (
        <Card sx={sx} data-track-id={`${service} notification`}>
            <CardActionArea
                href={parsedUrl}
                target="_blank"
                onClick={showMaintenanceWarning}
                disabled={data.maintenanceMode || !parsedUrl}
                sx={{ font: "inherit" }}
            >
                <CardContent
                    sx={{
                        padding: "5px 10px",
                        "&:last-child": {
                            pb: 0,
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: parsedUrl ? "pointer" : "default",
                    }}
                >
                    <Box
                        sx={{
                            padding: 0,
                            "&:last-child": {
                                pb: 0,
                            },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            ...cardBorderSx,
                        }}
                    >
                        <Typography
                            variant="body2"
                            component={"p"}
                            color={"text.primary"}
                            sx={{ fontWeight: "inherit" }}
                        >
                            {data.displayComment}
                        </Typography>
                        <Typography
                            variant="body4"
                            component={"p"}
                            color={"text.primary"}
                            sx={{
                                padding: "4px 0",
                            }}
                        >
                            {getCreatedTimeLabel(item_created_ts)}
                        </Typography>
                        {parsedUrl && (
                            <TaskActionButton
                                mode={"neutral"}
                                onClick={() => {}}
                                action={"View"}
                                buttonSx={{ mt: "10px", py: "0px" }}
                                typographyVariant={"body2"}
                            />
                        )}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

NotificationCard.propTypes = {
    userViewed: PropTypes.bool.isRequired,
    resourceUri: PropTypes.string,
    source: PropTypes.string,
    data: PropTypes.object.isRequired,
    item_created_ts: PropTypes.number.isRequired,
};

export default NotificationCard;
