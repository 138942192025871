import apiService from "../apiService";
import { apiConfig } from "../config/apiConfig";

const apiHeaderConfig = {
    headers: {
        "api-version": "V1",
    },
};

const lmsApiService = apiService(apiConfig.lmsLearning, apiHeaderConfig);

const lmsLearningClient = {
    getLearnings: (params, requestHeaders) =>
        lmsApiService.get("v1/learning-records", params, requestHeaders),
};

export default lmsLearningClient;
