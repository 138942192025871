import React, { useRef } from "react";
import Box from "@mui/material/Box";
import {
    CustomAvatar,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { Typography } from "@mui/material";
import ChatLoadingSkeleton from "../skeletons/chatLoadingSkeleton";
import ChatBotSecurityDisclaimer from "./chatBotSecurityDisclaimer";
import useAvatarImage from "../../services/apiUtils/hooks/useAvatarImage";
import { useSelector } from "react-redux";
import TextMessage from "./chatBotMessageTextBoxes";
import Link from "@mui/material/Link";
import { SSE_EDISON } from "../../constants/externalUrls";
import PropTypes from "prop-types";

const chatMessageSx = {
    wordBreak: "break-word",
    alignItems: "center",
    justifyContent: "flex-start",
    p: "10px",
    width: "calc(100% - 60px)",
    flexDirection: "row",
    display: "flex",
    color: "text.contrastText",
};

const ChatBotMessageDisplay = ({ messages, isLoading }) => {
    const messagesContainerRef = useRef(null);
    const { borderSX } = useHighContrastBorderSX();
    const { avatarImage } = useAvatarImage();
    const email = useSelector((state) => state.auth.email);

    const userIcon = (
        <CustomAvatar
            avatarSx={{
                ...borderSX,
                backgroundColor: "button.main",
                height: "50px",
                width: "50px",
                color: "button.contrastText",
                m: "0px",
                ml: "10px",
            }}
            avatarImage={avatarImage}
            email={email}
        />
    );

    const scrollToBottom = () => {
        window.requestAnimationFrame(() =>
            messagesContainerRef.current?.scrollIntoView()
        );
    };

    React.useEffect(() => {
        scrollToBottom();
    }, [messages, isLoading]);

    return (
        <Box
            sx={{
                overflowY: "auto",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    ...borderSX,
                    backgroundColor: "secondary.dark",
                    px: "20px",
                    py: "8px",
                }}
            >
                <Link
                    variant={"h4"}
                    target="_blank"
                    underline="always"
                    color="common.white"
                    href={SSE_EDISON}
                    data-track-id={`edison learn about our pilot`}
                >
                    We are currently testing Edison’s performance - learn about
                    our pilot here
                </Link>
            </Box>
            <Box p={"20px"} id="messageBody">
                <ChatBotSecurityDisclaimer />
                {messages.map((message, index) => (
                    <TextMessage
                        key={index}
                        content={message.content}
                        role={message.role}
                        userIcon={userIcon}
                        messageTime={message.messageTime}
                        citations={message.citations}
                    />
                ))}
                {isLoading && (
                    <Box>
                        <Typography
                            sx={{
                                padding: "10px 0 0 12px",
                                color: "text.light",
                            }}
                        >
                            Edison is generating a response...
                        </Typography>
                        <Box sx={chatMessageSx}>
                            <LightbulbOutlinedIcon
                                sx={{ color: "icon.secondary" }}
                            />
                            <ChatLoadingSkeleton />
                        </Box>
                    </Box>
                )}
                <div ref={messagesContainerRef} />
            </Box>
        </Box>
    );
};

ChatBotMessageDisplay.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            role: PropTypes.string,
            content: PropTypes.string,
            messageTime: PropTypes.string,
            citations: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    url: PropTypes.string,
                })
            ),
        })
    ),
    isLoading: PropTypes.bool.isRequired,
};

export default ChatBotMessageDisplay;
