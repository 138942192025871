import { alpha } from "@mui/material";

export const textInputFieldSX = {
    ml: 2,
    mr: 2,
    color: "common.black",
    flex: 1,
};

export const buttonSX = (theme) => ({
    backgroundColor: "common.darkTeal",
    color: "common.white",
    ":hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
    },
    "&.Mui-disabled": {
        backgroundColor: "disabled.main",
    },
    minWidth: "140px",
    boxShadow: "none",
    textTransform: "capitalize",
    borderRadius: "1px",
});
