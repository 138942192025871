import NpsForm from "./npsForm";
import { useDispatch, useSelector } from "react-redux";
import {
    DESKTOP_MEDIA_QUERY,
    SwipeableDrawerWithHeader,
} from "@sse-digital-services/design-system";
import { useMediaQuery } from "@mui/material";
import { setShowFeedbackForm } from "../../../redux/actions/generalActions";

const NpsDrawer = () => {
    const showForm = useSelector((state) => state.general.showFeedbackForm);
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setShowFeedbackForm(false));
    };

    return (
        <SwipeableDrawerWithHeader
            open={showForm}
            title={"Let us know what you think"}
            onOpen={() => {}} // Required prop but not used in this usecase
            onClose={handleClose}
            id={"NPS"}
            paperSx={{
                width: isDesktopWidth ? 400 : "100%",
            }}
            modalProps={{
                keepMounted: false, // Needed for Drawer content to update according to state changes
            }}
        >
            <NpsForm />
        </SwipeableDrawerWithHeader>
    );
};

export default NpsDrawer;
