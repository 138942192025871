import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWR from "swr";
import integrationsClient from "../clients/integrationsClient";
import sharePriceMapper from "../mappers/sharePriceMapper";

const useSharePrice = () => {
    const { throwAsyncError } = useErrorHandling();

    const { data: latestSharePrice, error: sharePriceError } = useSWR(
        "latestSharePrice",
        async () => {
            try {
                const latestSharePriceData =
                    await integrationsClient.getSharePrice();
                return sharePriceMapper.mapAPISharePriceToSharePrice(
                    latestSharePriceData.data
                );
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.SharePriceAPIError,
                    "Unable to fetch share price data",
                    error
                );
                throw new Error("Unable to fetch share price data");
            }
        },
        { fallbackData: null }
    );

    return { latestSharePrice, errorMessage: sharePriceError?.message };
};

export default useSharePrice;
