export const avatarURL = "https://graph.microsoft.com/v1.0/me/photo/$value";

// API URLs
export const preferencesURL = `${GLOBAL_CONFIG.preferencesURL}/preferences`;
export const notificationsURL = GLOBAL_CONFIG.notificationsURL;
export const integrationAPIURL = GLOBAL_CONFIG.integrationURL;
export const searchUrl = GLOBAL_CONFIG.searchURL;
export const iBuyAPICartsURL = `${GLOBAL_CONFIG.iBuyAPICartsURL}/shopping-cart`;
export const iBuyAPIRequisitionURL = GLOBAL_CONFIG.iBuyAPIRequisitionURL;
export const iBuyAPISearchURL = GLOBAL_CONFIG.iBuyAPISearchURL;
export const documentProcessorURL = GLOBAL_CONFIG.documentProcessorURL;
export const edisonReportingURL = GLOBAL_CONFIG.edisonReportingURL;

// Redirect URLs
export const iBuyMainURL = GLOBAL_CONFIG.iBuyMainURL;
export const payslipsMainURL = GLOBAL_CONFIG.payslipsMainURL;
export const annualLeaveUrl = GLOBAL_CONFIG.annualLeaveURL;

// Service now env variables
export const itscUrl = `${GLOBAL_CONFIG.serviceNowURL}/ssesp`;
export const procureToPayURL = `${GLOBAL_CONFIG.serviceNowURL}/sseproc`;

//User profile URLs
export const userProfileURL = GLOBAL_CONFIG.userProfileURL;

//LMS Learning url
export const lmsApiUrl = GLOBAL_CONFIG.lmsApiUrl;
export const lmsLearningPortalURL = GLOBAL_CONFIG.lmsLearningPortal;
