import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const ChatLoadingSkeleton = () => {
    return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
            {[...Array(3)].map((item, i) => (
                <Skeleton
                    key={i}
                    variant="circular"
                    width={10}
                    height={10}
                    sx={{ ml: 2, bgcolor: "common.slateGrey20" }}
                />
            ))}
        </Box>
    );
};

export default ChatLoadingSkeleton;
