import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import searchAssistantMapper from "../mappers/searchAssistantMapper";
import searchAssistantClient from "../clients/searchAssistantClient";
import { CHATBOT_ERROR_MESSAGE } from "../../../constants/generalConstants";
import { triggerSnackbar } from "../../../utils/alertsUtils";
import { useDispatch } from "react-redux";
import {
    CONTENT_FILTERING_DOWN_ERROR,
    CONTENT_FILTERING_TRIGGERED,
} from "../../../constants/customErrorCodes";

const useSearchAssistant = () => {
    const dispatch = useDispatch();
    const { throwAsyncError } = useErrorHandling();

    const fetchChatResponse = async (request, id) => {
        const chatResponseData = await searchAssistantClient.chat({
            chat_history: request,
            id: id,
        });
        return searchAssistantMapper.mapAPIChatResponseToChatResponse(
            chatResponseData
        );
    };
    const customErrorCodes = new Set([
        CONTENT_FILTERING_DOWN_ERROR,
        CONTENT_FILTERING_TRIGGERED,
    ]);

    const handleErrorResponse = async (mappedRequest, error) => {
        if (error?.response?.status === 403) {
            triggerSnackbar(
                "warning",
                "Sorry, something odd was found in your chat history, so it has been reset!",
                null,
                dispatch
            );
            try {
                // Disregard chat history and only send the last user input
                const responseData = await fetchChatResponse(
                    mappedRequest.slice(-1)
                );
                return { data: responseData, reset: true };
            } catch {
                // Continue
            }
        }
        let message = customErrorCodes.has(
            error?.response?.data?.custom_error_code
        )
            ? error.response.data.detail
            : "Unable to get search assistant response";
        throwAsyncError(ErrorTypes.SearchAssistantAPIError, message, error);
        return {
            data: {
                role: "assistant",
                content: CHATBOT_ERROR_MESSAGE,
            },
        };
    };

    const getChatResponse = async (request) => {
        if (request) {
            const mappedRequest = searchAssistantMapper.mapChatToAPIChat(
                request.messages
            );
            try {
                const responseData = await fetchChatResponse(
                    mappedRequest,
                    request.id
                );
                return { data: responseData };
            } catch (error) {
                return handleErrorResponse(mappedRequest, error);
            }
        }
    };
    return {
        getChatResponse,
    };
};

export default useSearchAssistant;
