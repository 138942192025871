import { Box, Button, Typography, useTheme } from "@mui/material";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import React from "react";
import PropTypes from "prop-types";
import { buttonSX } from "styled/styledSXProperties/styledUserInput";
const ButtonArea = ({ buttonList, sx }) => {
    const { borderSX } = useHighContrastBorderSX();
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                flexWrap: "wrap",
                marginTop: "12px",
                ...sx,
            }}
        >
            {buttonList.map((button) => (
                <Button
                    key={button.dataTrackId}
                    data-track-id={button.dataTrackId}
                    onClick={button.onClick}
                    disabled={button.disabled}
                    sx={{
                        ...borderSX,
                        ...buttonSX(theme),
                        marginLeft: "8px",
                        marginTop: "4px",
                    }}
                >
                    <Typography variant="h6" component="p">
                        {button.text}
                    </Typography>
                </Button>
            ))}
        </Box>
    );
};

ButtonArea.propTypes = {
    buttonList: PropTypes.array,
    sx: PropTypes.object,
};

export default ButtonArea;
