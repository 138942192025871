import { MAX_CHAT_HISTORY } from "../../../constants/generalConstants";

const searchAssistantMapper = {
    mapAPIChatResponseToChatResponse: (apiResponse) => {
        return {
            role: "assistant",
            content: apiResponse.content,
            citations: apiResponse.citations?.map((citation) => {
                return {
                    title: citation.title,
                    url: citation.url,
                };
            }),
        };
    },
    mapChatToAPIChat: (request) => {
        return request.slice(-MAX_CHAT_HISTORY).map((message) => {
            return { role: message.role, content: message.content };
        });
    },
};

export default searchAssistantMapper;
