import React, { useEffect } from "react";
import { PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE } from "../../../constants/generalConstants";
import PropTypes from "prop-types";
import PaginatedTable from "../../tables/paginatedTable";
import { procureToPayURL } from "../../../services/apiUtils/config/urls";
import { PROCURETOPAY, serviceNames } from "../../../constants/serviceNames";
import TileAccordion from "../tileAccordion";
import useServiceNowPtpIncidents from "../../../services/apiUtils/hooks/useProcureToPayIncidents";
import ProcureToPayIncidentsRow from "./procureToPayIncidentsRow";

const PtpIncidentsSection = ({
    isEditing,
    maintenanceMode,
    viewClosed = false,
}) => {
    const {
        ptpIncidentList,
        setPagesToLoad,
        loadedPageCount,
        ptpIncidentCount,
        errorMessage,
    } = useServiceNowPtpIncidents(viewClosed);

    const preloadNextPage = (newPage) => {
        if (
            newPage + 1 >= loadedPageCount &&
            ptpIncidentCount >
                (newPage + 1) * PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE
        ) {
            setPagesToLoad(newPage + 2);
        }
    };

    useEffect(() => {
        if (
            loadedPageCount === 1 &&
            ptpIncidentCount > PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE
        ) {
            setPagesToLoad(2);
        }
    }, [loadedPageCount, ptpIncidentCount, setPagesToLoad]);

    return (
        <TileAccordion
            serviceName={serviceNames[PROCURETOPAY]}
            tileName="incidents"
            maintenanceMode={maintenanceMode}
            errorMessage={errorMessage}
            count={ptpIncidentCount}
            isEditing={isEditing}
        >
            <PaginatedTable
                totalItems={ptpIncidentCount}
                isEditing={isEditing}
                serviceUrl={`${procureToPayURL}?id=incidents`}
                itemsPerPage={PROCURE_TO_PAY_INCIDENT_ROWS_PER_PAGE}
                service={PROCURETOPAY}
                items={ptpIncidentList ?? []}
                Row={ProcureToPayIncidentsRow}
                onPageChange={preloadNextPage}
            />
        </TileAccordion>
    );
};

PtpIncidentsSection.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    maintenanceMode: PropTypes.bool,
    viewClosed: PropTypes.bool,
};

export default PtpIncidentsSection;
