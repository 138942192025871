const npsMapper = {
    mapAPIAverageRatingToAverageRating: (apiAverageRating) =>
        apiAverageRating?.average_nps,
    mapAPIFeedbackDataToNPSFeedback: (apiRatingsData) => ({
        count: apiRatingsData.total_count,
        data: apiRatingsData?.data?.map((apiRating) => ({
            rating: apiRating.starRating,
            reason: apiRating.visitReason,
            comments: apiRating.npsComments,
            timestamp: apiRating.timestamp,
        })),
    }),
    mapAPIShowformToShowform: (apiShowformResponse) =>
        apiShowformResponse?.display,
    mapNewFeedbackToAPIFeedback: (newAPIFeedback) => ({
        starRating: newAPIFeedback.rating,
        visitReason: newAPIFeedback.reason,
        npsComments:
            (newAPIFeedback.additionalReason
                ? `Reason for visit: ${newAPIFeedback.additionalReason}, Comments: `
                : "") + newAPIFeedback.comments,
    }),
};

export default npsMapper;
