const payslipsMapper = {
    mapAPIPayslipDataToDate: (apiPayslipData) => {
        return (
            apiPayslipData.data?.eventDate &&
            new Date(apiPayslipData.data?.eventDate)
        );
    },
};

export default payslipsMapper;
