import { TableCell, TablePagination } from "@mui/material";
import PropTypes from "prop-types";

const StyledPagination = ({
    totalItems,
    page,
    rowsPerPage,
    handlePageChange,
    disabled = false,
}) => {
    return (
        <>
            {totalItems > rowsPerPage ? (
                <TablePagination
                    count={totalItems}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[rowsPerPage]}
                    onPageChange={handlePageChange}
                    disabled={disabled}
                    align="right"
                    nextIconButtonProps={{
                        "data-track-id": "next page",
                        sx: {
                            paddingRight: "0px",
                            marginLeft: "0px",
                        },
                    }}
                    backIconButtonProps={{
                        "data-track-id": "previous page",
                    }}
                    sx={{
                        color: "text.primary",
                        borderBottom: "none",
                        ".MuiTablePagination-toolbar": {
                            ml: "6px",
                            p: "0px",
                            ".MuiTablePagination-actions": {
                                ml: "0px",
                            },
                            ".MuiButtonBase-root": {
                                p: "4px",
                            },
                        },
                    }}
                />
            ) : (
                <TableCell
                    align="right"
                    component="td"
                    sx={{ borderBottom: "none" }}
                />
            )}
        </>
    );
};

StyledPagination.propTypes = {
    totalItems: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default StyledPagination;
