import { Card, Grid, Typography } from "@mui/material";
import { useHighContrastBorderSX } from "@sse-digital-services/design-system";
import ButtonArea from "components/forms/buttonArea";
import TextInput from "components/forms/textInput";
import { ConfirmationDialog } from "components/modals/confirmationDialog";
import NotificationCard from "components/modals/notifications/notificationCard";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useNotifications from "services/apiUtils/hooks/useNotifications";
import { triggerSnackbar } from "utils/alertsUtils";
import { validateHttpsUrl } from "utils/urlUtils";
import { ADMIN } from "../../../constants/serviceNames";

const CreateNotificationCard = () => {
    const [message, setMessage] = useState("");
    const [url, setUrl] = useState("");
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const { submitAdminNotification } = useNotifications();
    const { borderSX } = useHighContrastBorderSX();
    const dispatch = useDispatch();

    const onSubmit = () => {
        if (message === "") {
            triggerSnackbar(
                "info",
                "Please enter a notification message",
                null,
                dispatch
            );
        } else if (/^http:\/\//i.test(url)) {
            triggerSnackbar(
                "info",
                "Only secure (https) urls can be used. Please do not use http",
                null,
                dispatch
            );
        } else if (url && !validateHttpsUrl(url)) {
            triggerSnackbar(
                "info",
                "Please make sure the notification url is a valid https url",
                null,
                dispatch
            );
        } else {
            setOpenConfirmDialog(true);
        }
    };

    const onConfirm = () => {
        submitAdminNotification({ message, url: url !== "" ? url : undefined });
        setOpenConfirmDialog(false);
        setMessage("");
        setUrl("");
    };
    return (
        <Grid xs={12} md={6} item>
            <Card sx={{ padding: "16px", ...borderSX }}>
                <Typography
                    sx={{ margin: "0 0 20px 0" }}
                    color="text.primary"
                    variant="h2"
                    component="p"
                    textAlign="left"
                    alignItems="center"
                >
                    Send a notification to all users
                </Typography>
                <TextInput
                    labelText="Notification message:"
                    dataTrackId="admin-notification-message"
                    id="admin-notification-message"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                />
                <TextInput
                    labelText="Notification Link (optional):"
                    dataTrackId="admin-notification-uri"
                    id="admin-notification-uri"
                    value={url}
                    onChange={(event) => setUrl(event.target.value)}
                />
                <ButtonArea
                    buttonList={[
                        {
                            text: "Submit",
                            onClick: onSubmit,
                            dataTrackId: "admin-notifications-submit",
                        },
                    ]}
                />
                <ConfirmationDialog
                    open={openConfirmDialog}
                    onCancel={() => setOpenConfirmDialog(false)}
                    onConfirm={onConfirm}
                    dialogTitle={
                        "Are you sure you want to send a notification to all users?"
                    }
                >
                    <>
                        <Typography variant="h6" component="p">
                            Preview:
                        </Typography>
                        <NotificationCard
                            source={ADMIN}
                            resourceUri={url}
                            item_created_ts={Date.now() / 1000 - 50}
                            data={{
                                displayComment: message,
                            }}
                            userViewed={false}
                        />
                    </>
                </ConfirmationDialog>
            </Card>
        </Grid>
    );
};

export default CreateNotificationCard;
