import { Button, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    DESKTOP_MEDIA_QUERY,
    styledDividerSX,
} from "@sse-digital-services/design-system";
import { UAParser } from "ua-parser-js";
import IosShareIcon from "@mui/icons-material/IosShare";
import Divider from "@mui/material/Divider";
import MoreInfoModal from "../../PWA/moreInfoModal";
import { MoreInfoButton } from "components/buttons/moreInfoButton";
import { APP_NAME } from "../../../constants/generalConstants";

const PWAInstallSection = () => {
    const deferredPrompt = useSelector((state) => state.pwa.deferredPrompt);
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const uaParser = new UAParser(navigator?.userAgent);
    const browser = uaParser.getBrowser();
    const platform = uaParser.getOS();
    const ios = platform.name.search(/ios/i) >= 0;
    const safari = browser.name.search(/safari/i) >= 0;
    const supportedIos = ios && safari;
    const alreadyInPWA =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator?.standalone;
    const [installTriggered, setInstallTriggered] = useState(false);
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);

    const handleClick = async () => {
        if (deferredPrompt) {
            const installResult = await deferredPrompt.prompt();
            if (installResult.outcome === "accepted") {
                setInstallTriggered(true);
            }
        }
    };

    const content = supportedIos ? (
        <>
            To install {APP_NAME} as an app, click on the <IosShareIcon /> icon
            and then select Add to Home Screen
        </>
    ) : (
        <>
            <Button onClick={handleClick} sx={{ padding: "0px 0px 3px 0px" }}>
                <Typography
                    sx={{
                        color: "text.primary",
                        textDecoration: "underline",
                        textTransform: "none",
                    }}
                    variant={isDesktopWidth ? "body1" : "body3"}
                    component="p"
                >
                    Click here
                </Typography>
            </Button>{" "}
            to install {APP_NAME} as an app on your device.
        </>
    );

    return (
        <>
            {!alreadyInPWA &&
                !installTriggered &&
                (deferredPrompt || supportedIos) && (
                    <>
                        <Divider
                            sx={{
                                ...styledDividerSX,
                                width: "100%",
                                margin: "16px 0px",
                            }}
                        />
                        <Typography
                            sx={{ color: "text.primary", padding: "16px 0" }}
                            variant={isDesktopWidth ? "body1" : "body3"}
                            component="p"
                            align="center"
                        >
                            {content}
                            <MoreInfoButton
                                ariaLabel={"open more info modal"}
                                dataTrackId={"open pwa info modal"}
                                openMoreInfoModal={() =>
                                    setMoreInfoModalOpen(true)
                                }
                            />
                        </Typography>
                    </>
                )}
            <MoreInfoModal
                open={moreInfoModalOpen}
                setOpen={setMoreInfoModalOpen}
            />
        </>
    );
};

export default PWAInstallSection;
