import Typography from "@mui/material/Typography";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    useTheme,
} from "@mui/material";
import Link from "@mui/material/Link";
import {
    EMPLOYEE_PRIVACY_NOTICE,
    SSE_EDISON,
} from "../../constants/externalUrls";
import Box from "@mui/material/Box";

const ChatBotSecurityDisclaimer = () => {
    const theme = useTheme();

    return (
        <Box mb={"30px"}>
            <Typography variant="body5" sx={{ wordBreak: "break-word" }}>
                Please do not enter any personal data (e.g. names, addresses, or
                any other information which could directly or indirectly
                identify anyone) or any confidential information of SSE or any
                third party (e.g. suppliers or customers).
            </Typography>
            <Link
                mt="15px"
                ml="22px"
                display={"block"}
                underline="always"
                color="text.emphasis"
                variant="body5"
                data-track-id={`How to use Edison`}
                href={SSE_EDISON}
                target="_blank"
            >
                How to use Edison
            </Link>
            <Accordion
                disableGutters
                elevation={0}
                sx={{
                    flexDirection: "row-reverse",
                }}
            >
                <AccordionSummary
                    sx={{
                        minHeight: "0px",
                        px: 0,
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                            {
                                transform: "rotate(90deg)",
                            },
                        "& .MuiAccordionSummary-content": {
                            margin: "0px",
                            p: "0px",
                        },
                    }}
                    expandIcon={
                        <Box
                            sx={{
                                width: 0,
                                height: 0,
                                borderTop: "8px solid transparent",
                                borderBottom: "8px solid transparent",
                                borderLeft: `12px solid ${theme.palette.text.emphasis}`,
                            }}
                        ></Box>
                    }
                    aria-label="What is Edison"
                    aria-controls="What is Edison content"
                    id="search-assistant-security-disclaimer"
                >
                    <Typography
                        ml={"10px"}
                        variant="body5"
                        color={"text.emphasis"}
                        sx={{ textDecoration: "underline" }}
                    >
                        What is Edison
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: "0px 0px 0px 4px" }}>
                    <Typography
                        component="p"
                        variant="body5"
                        mt={"25px"}
                        sx={{
                            borderLeftWidth: "5px",
                            borderLeftStyle: "solid",
                            borderLeftColor: "text.emphasis",
                            paddingLeft: "15px",
                        }}
                    >
                        I am a corporate assistant powered by Microsoft OpenAI.
                        I am a type of artificial intelligence (AI) that uses a
                        Large Language Model created by Microsoft to recognise
                        and generate text in natural sounding language. As such,
                        any information you provide is processed by Microsoft.
                        <br />
                        <br />I am programmed to provide information and
                        assistance based on the data and knowledge available to
                        me. While I strive to provide accurate and helpful
                        responses, my responses should not be considered as
                        professional advice nor as a substitute for human
                        expertise.
                        <br />
                        <br />
                        You must check the source of the information to verify
                        my response before relying on or publishing any
                        response. If in doubt as to the relevance of Edison
                        sources, especially if no source is provided, please
                        check with the relevant department before taking action.
                        If you are using the response from me, please make clear
                        that the information was generated by AI. SSE may use
                        information you provide, and responses, to assist with
                        improving the data and knowledge available to me.
                        <br />
                        <br />
                        <Link
                            data-track-id={`View Privacy Notice`}
                            href={EMPLOYEE_PRIVACY_NOTICE}
                            target="_blank"
                            underline="always"
                            color="text.emphasis"
                            variant="body5"
                            sx={{ display: "block" }}
                        >
                            View Employee Privacy Notice
                        </Link>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default ChatBotSecurityDisclaimer;
