import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import DashboardTile from "../templates/dashboardTile";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const RecognitionTile = ({ id, transform, addRectGetter }) => {
    const theme = useTheme();

    return (
        <DashboardTile
            id={id}
            tileColor={theme.palette.background.paper}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <Typography
                sx={{ margin: "0 0 20px 0" }}
                color="text.primary"
                variant="h2"
                component="p"
                textAlign="left"
                alignItems="center"
            >
                Recognition
            </Typography>
            <Divider
                sx={{ borderBottomWidth: 1 }}
                variant="middle"
                ml={"20px"}
                mr={"20px"}
            />
            <Box
                sx={{
                    margin: "35px 15px",
                    border: "1px solid",
                    paddingBottom: "10px",
                    borderImageSlice: 1,
                    borderImageSource: `linear-gradient(to right, ${theme.palette.common.white}, ${theme.palette.text.emphasis})`,
                    borderWidth: "0px 0px 3px 0px",
                }}
            >
                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={2}
                >
                    <Grid item lg={8}>
                        <Typography
                            mb="5px"
                            variant="body1"
                            component="p"
                            fontWeight={"bold"}
                            color="text.primary"
                        >
                            Congratulations!
                        </Typography>
                        <Typography
                            variant={"body1"}
                            component="p"
                            color="text.primary"
                        >
                            You have been recognised as a Core Value Ambassador!
                        </Typography>
                    </Grid>
                    <Grid item lg={4}>
                        <WorkspacePremiumIcon
                            sx={{ color: "text.emphasis", fontSize: "65px" }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </DashboardTile>
    );
};

RecognitionTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default RecognitionTile;
