import { getMsalInstance } from "../auth/authProvider";

const getLocalAPIToken = (tokenRequest) => {
    return (
        GLOBAL_TOKENS.find((tokenConfig) =>
            tokenConfig.scope.startsWith(tokenRequest.scopes.join(" "))
        )?.access_token ?? ""
    );
};

export const tokenManager = {
    getTokenForAPI: async (apiConfig) => {
        if (process.env.REACT_APP_LOCAL_DEV !== "true") {
            const msalInstance = await getMsalInstance();
            try {
                return (
                    await msalInstance.acquireTokenSilent(
                        apiConfig.tokenRequest
                    )
                ).accessToken;
            } catch (error) {
                await msalInstance.acquireTokenRedirect(apiConfig.tokenRequest);
            }
        } else {
            return getLocalAPIToken(apiConfig.tokenRequest);
        }
    },
    getLocalAPIToken,
};
