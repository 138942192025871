import {
    HTTPS_PLACEHOLDER,
    HTTP_PLACEHOLDER,
} from "constants/generalConstants";

const notificationsMapper = {
    mapNotificationStatus: (notificationsData) =>
        notificationsData.map((notificationsPage) => {
            return {
                ...notificationsPage,
                unread_count: 0,
                data: notificationsPage.data.map((notification) => {
                    return {
                        ...notification,
                        userViewed: true,
                    };
                }),
            };
        }),
    mapNotificationsWithMaintenanceMode: (
        newNotifications,
        servicesInMaintenanceMode
    ) => {
        const dataWithMaintenanceMode = newNotifications.data.map(
            (notification) => ({
                ...notification,
                data: {
                    ...notification.data,
                    displayComment:
                        notification.data.displayComment ??
                        notification.data.notification,
                    maintenanceMode:
                        !!servicesInMaintenanceMode &&
                        !!servicesInMaintenanceMode.find(
                            (service) => service === notification.source
                        ),
                },
            })
        );
        return { ...newNotifications, data: dataWithMaintenanceMode };
    },
    mapNotificationCounter: (notificationCounterData) => {
        if (notificationCounterData) {
            return {
                globalCounter: notificationCounterData.global_counter,
                userCounter: notificationCounterData.user_counter,
            };
        }
        return {};
    },
    mapAdminNotification: (adminNotification) => {
        return {
            notification: adminNotification.message,
            resourceUri: adminNotification.url
                ?.replace("https://", HTTPS_PLACEHOLDER)
                .replace("http://", HTTP_PLACEHOLDER),
        };
    },
};

export default notificationsMapper;
