import { arrayMove } from "@dnd-kit/sortable";
import { REMOVED_TILE_PRIORITY } from "constants/generalConstants";
import { useState, useEffect, useCallback, useMemo } from "react";
import usePreferences from "services/apiUtils/hooks/usePreferences";
import { useTheme } from "@mui/material";
import { GRID_SPACING } from "../../constants/tileGridConstants";

const useTileCollection = (name, tilesMap) => {
    const { tilePreferences } = usePreferences();
    const [tiles, setTiles] = useState({});
    const [hiddenTileIds, setHiddenTileIds] = useState([]);
    const [tileOrder, setTileOrder] = useState([]);
    const [tileTransforms, setTileTransforms] = useState([]);
    const [rectGetters, setRectGetters] = useState({});
    const [initialRects, setInitialRects] = useState();
    const [tileBeingAdded, setTileBeingAdded] = useState();
    const theme = useTheme();

    const tileHandles = useMemo(() => {
        const sortedHiddenTiles = hiddenTileIds.sort((a, b) => {
            const first = a.toUpperCase();
            const second = b.toUpperCase();
            if (first < second) {
                return -1;
            }
            if (first > second) {
                return 1;
            }
            return 0;
        });
        return tileOrder.concat([
            "visibleTilesPlaceholder",
            ...sortedHiddenTiles,
            "hiddenTilesPlaceholder",
        ]);
    }, [tileOrder, hiddenTileIds]);

    const addRectGetter = (id, rectGetter) =>
        setRectGetters((oldGetters) => ({
            ...oldGetters,
            [id]: rectGetter,
        }));

    const updateTileStateFromPreferences = useCallback(
        (tilesWithComponents) => {
            const hiddenTileKeys = Object.keys(tilesWithComponents).filter(
                (tileKey) => !tilesWithComponents[tileKey].display
            );
            setHiddenTileIds([...hiddenTileKeys]);

            const orderedVisibleTiles = Object.keys(tilesWithComponents)
                .filter((tileKey) => tilesWithComponents[tileKey].display)
                .sort(
                    (keyA, keyB) =>
                        tilesWithComponents[keyA].priority -
                        tilesWithComponents[keyB].priority
                );
            setTileOrder([...orderedVisibleTiles]);
        },
        [setHiddenTileIds, setTileOrder]
    );

    useEffect(() => {
        if (tilePreferences) {
            const tilesWithComponents = {};
            Object.keys(tilePreferences).forEach((tileKey) => {
                if (tilesMap[tileKey]?.component) {
                    tilesWithComponents[tileKey] = {
                        ...tilePreferences[tileKey],
                        component: tilesMap[tileKey].component,
                    };
                }
            });
            setTiles(tilesWithComponents);

            updateTileStateFromPreferences(tilesWithComponents);
        }
    }, [tilePreferences, tilesMap, updateTileStateFromPreferences]);

    const getTilePreferencesToSave = () => {
        const newTilePreferences = {};
        tileOrder.forEach((tileId, index) => {
            newTilePreferences[tileId] = {
                ...tiles[tileId],
                priority: index,
                display: true,
            };
        });
        hiddenTileIds.forEach((tileId) => {
            newTilePreferences[tileId] = {
                ...tiles[tileId],
                priority: REMOVED_TILE_PRIORITY,
                display: false,
            };
        });
        return newTilePreferences;
    };

    const updateTilePreferences = useCallback(
        (newTilePreferences) => {
            setTiles(newTilePreferences);
            updateTileStateFromPreferences(newTilePreferences);
        },
        [setTiles, updateTileStateFromPreferences]
    );

    const reorderTiles = (draggedTileId, targetTileId) => {
        setTileOrder((tileIds) => {
            const oldIndex = tileIds.indexOf(draggedTileId);
            const newIndex = tileIds.indexOf(targetTileId);
            return oldIndex > -1 && newIndex > -1
                ? arrayMove(tileIds, oldIndex, newIndex)
                : tileIds;
        });
    };

    const removeTile = (removedTileId) => {
        setTileOrder((prevTileOrder) =>
            prevTileOrder.filter((tileId) => tileId !== removedTileId)
        );
        setHiddenTileIds((prevHiddenTiles) =>
            prevHiddenTiles.concat(removedTileId)
        );
        rectGetters[removedTileId] = undefined;
    };

    const addTile = (addedTileId) => {
        setTileOrder((prevTileOrder) => prevTileOrder.concat(addedTileId));
        setHiddenTileIds((prevHiddenTileOrder) =>
            prevHiddenTileOrder.filter((tileId) => tileId !== addedTileId)
        );
    };

    const calculateNewRects = (rects, oldIndex, newIndex) => {
        if (rects.length === 0) {
            return rects;
        }
        const reorderedRects = arrayMove(rects, oldIndex, newIndex).map(
            (rect) => {
                return {
                    top: rect?.top,
                    left: rect?.left,
                    height: rect?.height,
                    width: rect?.width,
                };
            }
        );

        let firstRectInRow = {
            top: rects[0]?.top,
            left: rects[0]?.left,
            width: reorderedRects[0]?.width,
            height: reorderedRects[0]?.height,
        };
        let maxRowHeight = firstRectInRow?.height;

        const newRects = [];
        reorderedRects.forEach((rect, index) => {
            if (index === 0) {
                newRects.push(firstRectInRow);
            } else {
                const prevRect = newRects[index - 1];
                let top = prevRect?.top;
                let left =
                    prevRect?.left +
                    prevRect?.width +
                    GRID_SPACING * parseInt(theme.spacing());
                if (left + rect?.width > window.innerWidth) {
                    left = firstRectInRow?.left;
                    top =
                        firstRectInRow?.top +
                        maxRowHeight +
                        GRID_SPACING * parseInt(theme.spacing());
                    firstRectInRow = {
                        top,
                        left,
                        width: rect?.width,
                        height: rect?.height,
                    };
                    maxRowHeight = firstRectInRow?.height;
                } else if (rect?.height > maxRowHeight) {
                    maxRowHeight = rect?.height;
                }
                newRects.push({
                    top,
                    left,
                    width: rect?.width,
                    height: rect?.height,
                });
            }
        });

        return newRects;
    };

    const updateRects = (activeId) => {
        const rects =
            initialRects ??
            tileOrder.map((tileId) =>
                rectGetters[tileId] ? rectGetters[tileId]() : null
            );
        if (
            tileOrder.indexOf(activeId) === -1 &&
            rectGetters[activeId] &&
            (!initialRects || initialRects.length === tileOrder.length)
        ) {
            rects.push(rectGetters[activeId]());
        }
        setInitialRects(rects);
        return rects;
    };

    const updateTransforms = (activeId, overId) => {
        const overIndex = tileOrder.indexOf(overId);
        let activeIndex = tileOrder.indexOf(activeId);

        if (activeIndex === -1) {
            if (!tileBeingAdded) {
                setTileBeingAdded(activeId);
            }
            if (rectGetters[activeId]) {
                activeIndex = tileOrder.length;
            }
        }

        const rects = updateRects(activeId);

        const newRects = calculateNewRects(rects, activeIndex, overIndex);

        // Get the inverse of the re-ordering transformation so we can look up the new rects
        const indices = new Array(newRects.length)
            .fill({})
            .map((_, newIndex) => newIndex);
        const newIndices = arrayMove(indices, overIndex, activeIndex);
        setTileTransforms(
            rects.map((oldRect, index) => {
                const newRect = newRects[newIndices[index]];

                return {
                    transition: "transform 200ms ease 0s",
                    transform: `translate3d(${
                        newRect?.left - oldRect?.left
                    }px, ${
                        newRect?.top - oldRect?.top
                    }px, 0px) scaleX(1) scaleY(1)`,
                };
            })
        );
    };

    const resetTransforms = () => {
        setTileTransforms([]);
        setInitialRects(undefined);
        if (tileBeingAdded) {
            if (tileOrder.indexOf(tileBeingAdded) === -1) {
                rectGetters[tileBeingAdded] = undefined;
            }
            setTileBeingAdded(undefined);
        }
    };

    return {
        currentTileOrder: tileOrder,
        currentHiddenTileIds: hiddenTileIds,
        addTile,
        reorderTiles,
        getTilePreferencesToSave,
        removeTile,
        updateTilePreferences,
        tilesMap,
        name,
        updateTransforms,
        resetTransforms,
        tileTransforms,
        addRectGetter,
        tileHandles,
        tileBeingAdded,
    };
};

export default useTileCollection;
