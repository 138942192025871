import {
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from "@mui/material";
import {
    formatDateTime,
    useHighContrastBorderSX,
} from "@sse-digital-services/design-system";
import { EDISON_QA_PER_PAGE } from "constants/generalConstants";
import React, { useState } from "react";
import useEdisonReport from "../../../services/apiUtils/hooks/useEdisonReport";
import StyledPagination from "../../tables/styledPagination";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const EdisonQACard = () => {
    const { borderSX } = useHighContrastBorderSX();
    const [page, setPage] = useState(0);
    const { report, lastPageIndex, setPageIndex, edisonReportIsLoadingMore } =
        useEdisonReport();
    const tableBodyHeight = `${Math.min(230, 46 * report.count)}px`;

    const itemsToDisplay = report.data.slice(
        page * EDISON_QA_PER_PAGE,
        (page + 1) * EDISON_QA_PER_PAGE
    );

    const rowsPerPage = Math.min(report.count, EDISON_QA_PER_PAGE);
    const emptyRowCount = rowsPerPage - itemsToDisplay.length;
    const emptyRows = new Array(emptyRowCount).fill({});

    const handlePageChange = (event, newPage) => {
        if (
            newPage + 1 >= lastPageIndex &&
            report.count > (newPage + 1) * EDISON_QA_PER_PAGE
        ) {
            setPageIndex(newPage + 2);
        }
        setPage(newPage);
    };
    return (
        <Grid
            id="edison-report-results-card"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            item
        >
            <Card sx={{ padding: "16px", ...borderSX }}>
                <TableContainer>
                    <Table
                        data-track-id="edison-qa-table"
                        aria-label="edison qa table"
                        size="small"
                        sx={{
                            "& .MuiTableCell-sizeSmall": {
                                padding: "2px",
                                borderColor: "text.primary",
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "5%",
                                        minWidth: "60px",
                                    }}
                                >
                                    Length
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "10%",
                                        minWidth: "60px",
                                    }}
                                >
                                    Conversation ID
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "5%",
                                        minWidth: "150px",
                                    }}
                                >
                                    Timestamp
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "10%",
                                        minWidth: "100px",
                                    }}
                                >
                                    Query
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "35%",
                                        minWidth: "200px",
                                        maxWidth: "700px",
                                    }}
                                >
                                    Response
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        width: "25%",
                                        minWidth: "200px",
                                    }}
                                >
                                    Search Results
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                height: tableBodyHeight,
                                verticalAlign: "top",
                            }}
                        >
                            {itemsToDisplay.map((responseRowData) => (
                                <TableRow
                                    key={`response-${responseRowData.timestamp}-${responseRowData.id}`}
                                    sx={{
                                        width: "100%",
                                        height: "46px",
                                    }}
                                >
                                    <TableCell>
                                        {responseRowData.conversationLength}
                                    </TableCell>
                                    <TableCell>{responseRowData.id}</TableCell>
                                    <TableCell>
                                        {formatDateTime(
                                            responseRowData.timestamp
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {responseRowData.query}
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                maxHeight: "300px",
                                                overflowY: "auto",
                                                maxWidth: "700px",
                                            }}
                                        >
                                            {responseRowData.response}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {responseRowData.searchResults?.map(
                                            (result, index) => (
                                                <Box
                                                    key={`${responseRowData.id}-${responseRowData.timestamp}-${result.title}`}
                                                >
                                                    <Link
                                                        href={result.url}
                                                        target="_blank"
                                                        color="text.emphasis"
                                                    >
                                                        {index + 1}.{" "}
                                                        {result.title}
                                                    </Link>
                                                </Box>
                                            )
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows.map((_, index) => (
                                <TableRow
                                    key={`emptyRow-${index}`}
                                    sx={{
                                        width: "100%",
                                        height: "46px",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            borderBottom: "none",
                                        }}
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <StyledPagination
                                    totalItems={report.count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handlePageChange={handlePageChange}
                                    disabled={edisonReportIsLoadingMore}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    );
};

export default EdisonQACard;
