import { serviceNames } from "../../../constants/serviceNames";
import usePreferences from "../../../services/apiUtils/hooks/usePreferences";
import { useEffect, useState } from "react";
import { arrayIntersection } from "../../../utils/arrayUtils";

const useTileMaintenanceMode = (id) => {
    const { tilePreferences, servicesBasedOnPermissions } = usePreferences();
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    const [serviceDisplayNames, setServiceDisplayNames] = useState([]);

    useEffect(() => {
        const preferencesForTile = tilePreferences
            ? tilePreferences[id]
            : undefined;
        const serviceIds = arrayIntersection(
            preferencesForTile?.services,
            servicesBasedOnPermissions
        );
        setMaintenanceMode(!!preferencesForTile?.maintenanceMode);
        setServiceDisplayNames(serviceIds.map((id) => serviceNames[id]) ?? []);
    }, [tilePreferences]);

    return { maintenanceMode, serviceDisplayNames };
};

export default useTileMaintenanceMode;
