import { combineReducers } from "redux";

// Reducers defined in reducer directory
import alertsReducer from "./alertsReducer";
import authReducer from "./authReducer";
import generalReducer from "./generalReducer";
import errorReducer from "./errorReducer";
import pwaReducer from "./pwaReducer";
import taskCountReducer from "./taskCountReducer";

const rootReducer = combineReducers({
    alerts: alertsReducer,
    auth: authReducer,
    general: generalReducer,
    error: errorReducer,
    pwa: pwaReducer,
    taskCount: taskCountReducer,
});

export default rootReducer;
