import { Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import CustomiseTilesPanel from "./customiseTilesPanel";
import PropTypes from "prop-types";
import { setSelectedCustomisationTab } from "../../redux/actions/generalActions";
import { useDispatch, useSelector } from "react-redux";
import { SwipeableDrawerWithHeader } from "@sse-digital-services/design-system";
import Typography from "@mui/material/Typography";

const CustomiseTilesDrawer = ({ open, onOpen, tileCollections, onClose }) => {
    const dispatch = useDispatch();
    const selectedTileCollection = useSelector(
        (state) => state.general.selectedCustomisationTab
    );
    const theme = useTheme();

    const tabIndicatorSX = {
        maxWidth: 70,
        height: "3px",
        width: "100%",
        backgroundColor: theme.palette.icon.secondary,
    };

    const onTabChange = (_, newValue) => {
        dispatch(setSelectedCustomisationTab(newValue));
    };
    return (
        <SwipeableDrawerWithHeader
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            title={"Edit layout"}
            modalProps={{
                keepMounted: false, // Needed for Drawer content to update according to state changes
            }}
            displayCloseButton={true}
        >
            <Tabs
                value={selectedTileCollection}
                selectionFollowsFocus
                onChange={onTabChange}
                centered
                TabIndicatorProps={{
                    sx: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        height: "10px",
                    },
                    children: <span style={tabIndicatorSX} />,
                }}
                sx={{
                    "& .MuiTab-root": {
                        color: "secondary.contrastText",
                        textTransform: "none",
                        marginX: "8px",
                        paddingY: "6px",
                    },
                    "&& .Mui-selected": {
                        color: "secondary.contrastText",
                    },
                }}
            >
                {tileCollections.map((tileCollection) => (
                    <Tab
                        key={`tab-${tileCollection.name}`}
                        label={
                            <Typography
                                variant="h6"
                                component="p"
                                color="icon.secondary"
                            >
                                {tileCollection.name}
                            </Typography>
                        }
                        disableRipple
                        disableFocusRipple
                    />
                ))}
            </Tabs>
            <CustomiseTilesPanel
                tileCollection={tileCollections[selectedTileCollection]}
            />
        </SwipeableDrawerWithHeader>
    );
};

CustomiseTilesDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    tileCollections: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CustomiseTilesDrawer;
