import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import usePreferences from "./usePreferences";
import useSWR from "swr";
import integrationsClient from "../clients/integrationsClient";
import { setProcureToPayRequestItemTaskCount } from "../../../redux/actions/taskCountActions";
import { useDispatch } from "react-redux";
import serviceNowMapper from "../mappers/serviceNowMapper";
import useServiceNowUser from "./useServiceNowUser";
import { SERVICENOW } from "../../../constants/serviceNames";
import { useCallback, useMemo } from "react";

const useProcureToPayRequestItemTasks = () => {
    const dispatch = useDispatch();
    const {
        servicesInMaintenanceMode,
        servicesBasedOnPermissions,
        preferencesAreLoading,
    } = usePreferences();
    const { serviceNowUser, serviceNowUserIsLoading } = useServiceNowUser();
    const { throwAsyncError } = useErrorHandling();

    const {
        data: procureToPayRequestItemTasks,
        isLoading: procureToPayRequestItemTasksDataIsLoading,
    } = useSWR(
        () =>
            preferencesAreLoading || serviceNowUserIsLoading
                ? null
                : "ProcureToPayRequestItemTasks",
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(SERVICENOW) &&
                    servicesInMaintenanceMode &&
                    serviceNowUser
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === SERVICENOW
                        )
                    ) {
                        const ptpRequestItemData =
                            await integrationsClient.getProcureToPayRequestItemTasks(
                                { "sn-user-data-token": serviceNowUser }
                            );
                        const approvalsMapped =
                            serviceNowMapper.mapAPIPTPRequestItemTasksToTasks(
                                ptpRequestItemData
                            );
                        dispatch(
                            setProcureToPayRequestItemTaskCount(
                                approvalsMapped.length
                            )
                        );
                        return approvalsMapped;
                    }
                }
            } catch (error) {
                // Display message from API in case of 404 (user not found in ITSC)
                let message =
                    error?.response?.data?.errorCode === 404 ||
                    error?.response?.data?.errorCode === "SF-ITG-SN-400"
                        ? error?.response?.data?.detail
                        : "Unable to fetch Procure To Pay Request Item Tasks";
                throwAsyncError(
                    ErrorTypes.ServiceNowProcureToRequestItemsError,
                    message,
                    error
                );
            }
        },
        { fallbackData: [] }
    );

    const procureToPayRequestItemTasksAreLoading = useMemo(
        () =>
            procureToPayRequestItemTasksDataIsLoading ||
            serviceNowUserIsLoading ||
            preferencesAreLoading,
        [
            procureToPayRequestItemTasksDataIsLoading,
            serviceNowUserIsLoading,
            preferencesAreLoading,
        ]
    );

    const ptpRequestItemTaskById = useCallback(
        (id) => {
            if (procureToPayRequestItemTasks) {
                const task = procureToPayRequestItemTasks.find((task) => {
                    return task.id === id;
                });
                return task ?? {};
            }
            return {};
        },
        [procureToPayRequestItemTasks]
    );

    return {
        procureToPayRequestItemTasks,
        procureToPayRequestItemTasksAreLoading,
        ptpRequestItemTaskById,
    };
};

export default useProcureToPayRequestItemTasks;
