import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import * as PropTypes from "prop-types";
import { styledCarouselCardSX } from "../../styled/styledSXProperties/styledDashboardTileCardSX";
import CardHeader from "@mui/material/CardHeader";
import BaseTaskButton from "./baseTaskButton";
import { CardActionArea, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import DashboardTaskCard from "./dashboardTaskCard";

export const CardTitle = ({ title }) => (
    <Typography variant={"h2"} component="p">
        {title}
    </Typography>
);

export const ActionWrapper = ({ action, isDashboard, children }) => {
    const wrapperSX = isDashboard ? { padding: "12px 0 24px 0" } : {};
    return (
        <>
            {action ? (
                <CardActionArea onClick={action}>
                    <Grid container sx={{ ...wrapperSX }}>
                        {children}
                    </Grid>
                </CardActionArea>
            ) : (
                children
            )}
        </>
    );
};

const BaseTaskCard = ({ title, fields, action, children, isDashboard }) => {
    const theme = useTheme();

    return (
        <>
            {isDashboard ? (
                <DashboardTaskCard
                    title={title}
                    fields={fields}
                    action={action}
                >
                    {children}
                </DashboardTaskCard>
            ) : (
                <Card
                    sx={{
                        ...styledCarouselCardSX,
                        minHeight: "100px",
                        ":hover": {
                            background: theme.palette.secondary.dark,
                            color: "common.white",
                            "& .MuiTypography-root": {
                                color: "common.white",
                            },
                            "& .MuiSvgIcon-root": {
                                color: "common.white",
                            },
                        },
                    }}
                    data-track-id="task card"
                >
                    <CardHeader title={<CardTitle title={title} />} />
                    <CardContent
                        sx={{ py: "0px", "&:last-child": { pb: "0px" } }}
                    >
                        <Grid container>
                            <Grid
                                container
                                item
                                lg={6}
                                sm={8}
                                sx={{
                                    color: "text.primary",
                                }}
                            >
                                <ActionWrapper
                                    action={action}
                                    isDashboard={isDashboard}
                                >
                                    {fields.map((row) => (
                                        <Grid
                                            item
                                            pr={1}
                                            key={`${row.field}-row`}
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            {row.action ? (
                                                <BaseTaskButton row={row} />
                                            ) : (
                                                <Typography
                                                    variant="body3"
                                                    component="p"
                                                    sx={{ paddingY: "2px" }}
                                                >
                                                    {`${row.field}: ${row.value}`}
                                                </Typography>
                                            )}
                                        </Grid>
                                    ))}
                                </ActionWrapper>
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                sm={4}
                                align={"right"}
                                sx={{
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    display: "flex",
                                    p: 0,
                                }}
                            >
                                {children}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

CardTitle.propTypes = {
    title: PropTypes.string.isRequired,
};
ActionWrapper.propTypes = {
    children: PropTypes.node,
    action: PropTypes.func,
    isDashboard: PropTypes.bool,
};
BaseTaskCard.propTypes = {
    title: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    action: PropTypes.func,
    children: PropTypes.node,
    isDashboard: PropTypes.bool,
};

export default BaseTaskCard;
