import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setDeferredInstallPrompt,
    setShowInstallDialog,
} from "../../redux/actions/pwaActions";
import { getDeviceInfo } from "@sse-digital-services/design-system";

const usePWAInstall = () => {
    const dispatch = useDispatch();
    const deferredPrompt = useSelector((state) => state.pwa.deferredPrompt);
    const { desktop, android, chrome, edge } = getDeviceInfo();
    const supportedNonIos =
        (desktop && (chrome || edge)) || (android && chrome);
    const alreadyInPWA =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator?.standalone;

    // Hook into relevant event
    useEffect(() => {
        if (!alreadyInPWA && supportedNonIos && !deferredPrompt) {
            window.addEventListener("beforeinstallprompt", (e) => {
                e.preventDefault();
                dispatch(setDeferredInstallPrompt(e));
                dispatch(setShowInstallDialog(true));
            });
        }
    }, [deferredPrompt]);

    return { alreadyInPWA };
};

export default usePWAInstall;
