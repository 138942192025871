import { NavigationBarContent } from "@sse-digital-services/design-system";
import useNavigationItems from "./useNavigationItems";
import React from "react";

const SfNavigationBar = () => {
    const { navItems } = useNavigationItems();

    return (
        <NavigationBarContent
            navItems={navItems.filter((item) => item.showOnBurgerMenu)}
        />
    );
};

export default SfNavigationBar;
