export const VERSION_BEARS = [
    "Grizzly",
    "Yogi",
    "Paddington",
    "Polar",
    "Gummy",
    "Koala",
    "Teddy",
    "Care",
    "Spectacled",
    "BooBoo",
    "Fozzie",
    "Smokey",
    "Grylls",
    "Pom",
    "Baloo",
    "Rupert",
    "Banjo",
];
