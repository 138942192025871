import { useMemo } from "react";
import {
    HIGH_CONTRAST_THEME,
    themeMap,
    useHighContrastTheme,
} from "@sse-digital-services/design-system";
import useAccessibility from "../services/apiUtils/hooks/useAccessibility";
import contrast from "get-contrast";
import { MIN_ALLOWED_CONTRAST } from "../constants/generalConstants";
import { triggerSnackbar } from "./alertsUtils";
import { useDispatch } from "react-redux";

const useSfThemes = () => {
    const dispatch = useDispatch();
    const { accessibilitySettings, updateAccessibilitySettings } =
        useAccessibility();

    const { highContrastTheme } = useHighContrastTheme(
        accessibilitySettings?.highContrastBackground,
        accessibilitySettings?.highContrastForeground
    );

    const sfThemeMap = useMemo(() => {
        return {
            ...themeMap,
            [HIGH_CONTRAST_THEME]: highContrastTheme,
        };
    }, [highContrastTheme]);

    const getTheme = (themeName) => sfThemeMap[themeName];

    const isColourCombinationAllowed = (backgroundColour, foregroundColour) => {
        return (
            contrast.ratio(backgroundColour, foregroundColour) >
            MIN_ALLOWED_CONTRAST
        );
    };

    const setHCColours = async (backgroundColour, foregroundColour) => {
        if (isColourCombinationAllowed(backgroundColour, foregroundColour)) {
            await updateAccessibilitySettings({
                highContrastBackground: backgroundColour,
                highContrastForeground: foregroundColour,
            });
        } else {
            triggerSnackbar(
                "info",
                "Colour contrast is too low",
                null,
                dispatch
            );
        }
    };

    const setHCBackgroundColour = (colour) => {
        setHCColours(colour, accessibilitySettings?.highContrastForeground);
    };

    const setHCForegroundColour = (colour) => {
        setHCColours(accessibilitySettings?.highContrastBackground, colour);
    };

    return {
        getTheme,
        setHCForegroundColour,
        setHCBackgroundColour,
        setHCColours,
    };
};

export default useSfThemes;
