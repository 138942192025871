import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import * as routes from "../../constants/routes";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setIsEditing,
    setSelectedCustomisationTab,
    setShowChatField,
    setShowFeedbackForm,
    setShowNotifications,
    setShowUserInfo,
} from "../../redux/actions/generalActions";
import {
    Close,
    CountPill,
    DESKTOP_MEDIA_QUERY,
    useNavIconSX,
} from "@sse-digital-services/design-system";
import { useLocation } from "react-router-dom";
import useNotifications from "../../services/apiUtils/hooks/useNotifications";
import { useMediaQuery, useTheme } from "@mui/material";
import usePreferences from "../../services/apiUtils/hooks/usePreferences";
import { SEARCHASSISTANT } from "../../constants/serviceNames";
import {
    AssignmentOutlined,
    ChatOutlined,
    DashboardCustomizeOutlined,
    HomeOutlined,
    ManageAccountsOutlined,
    NotificationsOutlined,
} from "@mui/icons-material";

const useNavigationItems = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const location = useLocation();
    const isDesktopWidth = useMediaQuery(DESKTOP_MEDIA_QUERY);
    const showNotifications = useSelector(
        (state) => state.general.showNotifications
    );
    const showFeedbackForm = useSelector(
        (state) => state.general.showFeedbackForm
    );
    const showUserInfo = useSelector((state) => state.general.showUserInfo);
    const taskCount = useSelector((state) => state.taskCount);
    const { getIconSX } = useNavIconSX();
    const {
        servicesBasedOnPermissions,
        servicesInMaintenanceMode,
        isAdmin,
        isManager,
        isEdisonAdmin,
    } = usePreferences();
    const { notifications } = useNotifications();
    const unreadNotificationsCount = notifications
        ? notifications.unread_count
        : undefined;
    const isEditing = useSelector((state) => state.general.isEditing);
    const showChatField = useSelector((state) => state.general.showChatField);

    const hideDrawers = (keepOpen = "") => {
        keepOpen !== "Ask Edison" && dispatch(setShowChatField(false));
        keepOpen !== "Notifications" && dispatch(setShowNotifications(false));
        keepOpen !== "Settings" && dispatch(setShowUserInfo(false));
        dispatch(setIsEditing(false));
        dispatch(setShowFeedbackForm(false));
    };

    const routeIsSelected = (route) =>
        (isDesktopWidth ||
            (!showChatField && !showNotifications && !showUserInfo)) &&
        route === location.pathname;
    const chatInMaintenance =
        servicesInMaintenanceMode?.includes(SEARCHASSISTANT);
    const chatBotSX = chatInMaintenance
        ? { ...getIconSX(), color: theme.palette.common.slateGrey70 }
        : getIconSX();

    const navItems = [
        {
            key: "Dashboard",
            tooltip: "Dashboard",
            icon: (
                <HomeOutlined
                    style={getIconSX(routeIsSelected(routes.HOMEPAGE))}
                />
            ),
            route: routes.HOMEPAGE,
            action: hideDrawers,
            selected: routeIsSelected(routes.HOMEPAGE),
            order: 0,
            isPage: true,
            showOnMobileBottomBar: true,
            showOnBurgerMenu: true,
        },
        {
            key: "My Tasks",
            tooltip: "My Tasks",
            icon: (
                <CountPill
                    count={Object.values(taskCount).reduce((a, b) => a + b, 0)}
                >
                    <AssignmentOutlined
                        style={getIconSX(routeIsSelected(routes.TASKS))}
                    />
                </CountPill>
            ),
            route: routes.TASKS,
            action: hideDrawers,
            selected: routeIsSelected(routes.TASKS),
            order: 1,
            count: Object.values(taskCount).reduce((a, b) => a + b, 0),
            isPage: true,
            showOnMobileBottomBar: true,
            showOnBurgerMenu: true,
        },
        {
            key: "Notifications",
            tooltip: "Notifications",
            icon: (
                <CountPill count={unreadNotificationsCount}>
                    <NotificationsOutlined sx={getIconSX()} />
                </CountPill>
            ),
            action: () => {
                dispatch(setShowNotifications(true));
                hideDrawers("Notifications");
            },
            selected: showNotifications,
            order: 3,
            showOnMobileBottomBar: true,
            count: unreadNotificationsCount,
        },
        {
            key: "Ask Edison",
            tooltip: chatInMaintenance
                ? "Edison is currently under maintenance!"
                : "Ask Edison",
            icon: <LightbulbOutlinedIcon sx={chatBotSX} />,
            selectedIcon: <Close sx={getIconSX()} />,
            action: () => {
                if (!chatInMaintenance) {
                    dispatch(setShowChatField(!showChatField));
                    hideDrawers("Ask Edison");
                }
            },
            order: 2,
            selected: showChatField,
            hidden: !servicesBasedOnPermissions?.includes(SEARCHASSISTANT),
            showOnMobileBottomBar: true,
            showOnBurgerMenu: true,
            fab: true,
        },
        {
            key: "Edit layout",
            tooltip: "Edit layout",
            icon: <DashboardCustomizeOutlined sx={getIconSX()} />,
            action: () => {
                if (!isEditing) {
                    dispatch(setSelectedCustomisationTab(0));
                    dispatch(setIsEditing(true));
                }
            },
            showOnBurgerMenu: true,
        },
        {
            key: "Give feedback",
            tooltip: "Give feedback",
            icon: <ChatOutlined sx={getIconSX()} />,
            action: () =>
                !showFeedbackForm && dispatch(setShowFeedbackForm(true)),
        },
        {
            key: "Admin",
            tooltip: "Admin",
            route: routes.ADMIN,
            selected: routeIsSelected(routes.ADMIN),
            hidden: !(isAdmin || isManager),
            showOnBurgerMenu: true,
            isPage: true,
        },
        {
            key: "Edison Admin",
            tooltip: "Edison Admin",
            route: routes.EDISON_ADMIN,
            selected: routeIsSelected(routes.EDISON_ADMIN),
            hidden: !isEdisonAdmin,
            showOnBurgerMenu: true,
            isPage: true,
        },
        {
            key: "Settings",
            tooltip: "Settings",
            icon: <ManageAccountsOutlined sx={getIconSX()} />,
            action: () => {
                dispatch(setShowUserInfo(true));
                hideDrawers("Settings");
            },
            selected: showUserInfo,
            showOnMobileBottomBar: true,
            order: 4,
        },
    ];

    return { navItems };
};

export default useNavigationItems;
