import React from "react";
import { LightbulbOutlined } from "@mui/icons-material";
import { ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import DashboardTile from "./templates/dashboardTile";
import { useDispatch } from "react-redux";
import { setShowChatField } from "../../redux/actions/generalActions";
import PropTypes from "prop-types";

const EdisonTile = ({ id, transform, addRectGetter }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    return (
        <DashboardTile
            tileColor={theme.palette.common.darkTeal}
            contentSX={{
                height: "100%",
                padding: "0px",
                "&:last-child": {
                    paddingBottom: "0px",
                },
                color: "common.white",
            }}
            id={id}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <ButtonBase
                onClick={() => dispatch(setShowChatField(true))}
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "20px",
                }}
            >
                <Typography variant="h2" component="p" textAlign="left">
                    Ask Edison
                </Typography>
                <Grid
                    container
                    columns={4}
                    sx={{
                        height: "100%",
                        alignItems: "center",
                    }}
                >
                    <Grid item xs={1}>
                        <LightbulbOutlined
                            sx={{
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                        >
                            Need some assistance?
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                        >
                            Use your SSE digital corporate assistant to get
                            answers, help and information
                        </Typography>
                    </Grid>
                </Grid>
            </ButtonBase>
        </DashboardTile>
    );
};

EdisonTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default EdisonTile;
