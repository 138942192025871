import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { ChevronRight } from "@sse-digital-services/design-system";

const TaskActionButton = ({
    mode,
    action,
    url,
    onClick,
    disabled = false,
    typographyVariant = "h6",
    buttonSx,
}) => {
    const buttonMap = {
        approve: { color: "text.emphasis", icon: <CheckCircleOutlineIcon /> },
        reject: { color: "text.primary", icon: <CancelIcon /> },
        neutral: {
            color: "text.emphasis",
            icon: (
                <ChevronRight
                    fontSize={"large"}
                    sx={{
                        borderRadius: "50%",
                        border: "2px solid",
                    }}
                />
            ),
        },
    };

    const onButtonClick = (event) => {
        if (onClick) {
            onClick(event);
        }
        event.stopPropagation();
    };

    return (
        <Button
            data-track-id={`${action}-button`}
            aria-label={`${action}-button`}
            fullWidth
            variant="standard"
            endIcon={buttonMap[mode].icon}
            sx={{
                p: "0px",
                minWidth: "60px",
                color: buttonMap[mode].color,
                textTransform: "none",
                alignItems: "center",
                justifyContent: "flex-start",
                outline: "revert",
                ...buttonSx,
            }}
            href={url}
            target="_blank"
            onClick={onButtonClick}
            disabled={disabled}
            disableRipple={true}
            disableTouchRipple={true}
        >
            <Typography variant={typographyVariant} component="p">
                {action}
            </Typography>
        </Button>
    );
};

TaskActionButton.propTypes = {
    mode: PropTypes.oneOf(["approve", "reject", "neutral"]).isRequired,
    action: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    typographyVariant: PropTypes.string,
    buttonSx: PropTypes.object,
};

export default TaskActionButton;
